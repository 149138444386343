import React, { useState } from 'react'
import { isIOS, isMacOs, isMobile } from 'react-device-detect'
import { IosShareIcon } from './CustomIcons'
import ShareIcon from '@material-ui/icons/Share'
import { IconButton } from '@material-ui/core'
import SocialModal from './SocialModal'

const MyShareIcon = () => {

  if (isMacOs || isIOS) {
    return (
      <IosShareIcon />
    )
  } else {
    return (
      <ShareIcon />
    )
  }
}


const MobileShare = props => {
  const {
    title,
    text,
    url,
    modalHeader,
    personName,
  } = props
  const [openModal, setOpenModal] = useState(false)

  const getShareData = props => ({
    ...props.title && { title: title },
    ...props.text && { text: text },
    ...props.url && { url: url },
  })

  const shareData = getShareData(props)

  const handleShare = () => {
    if (isMobile && navigator.share) {
      // share api
      navigator.share(shareData)
        .then(() => console.log('successfully shared'))
        .catch(() => console.log('uh oh - no share'))
    } else {
      // not mobile so do regular website share methods
      setOpenModal(true)
    }
  }

  return (
    <>
      <IconButton aria-label={`Share ${title}`} onClick={() => handleShare()}>
        <MyShareIcon />
      </IconButton>
      <SocialModal
        modalHeader={modalHeader || `Share Vagabongo`}
        handleCancelClick={() => setOpenModal(false)}
        openModal={openModal}
        shareTitle={title}
        shareSummary={text}
        hashtags={['vagabongo']}
        sharePersonName={personName}
      />
    </>
  )
}

export default MobileShare
