import Grid from '@material-ui/core/Grid'
import ExploreOutlinedIcon from '@material-ui/icons/ExploreOutlined'
import { TrophyIcon } from '../components/CustomIcons'
import MapIcon from '@material-ui/icons/Map'
import PaperContainer from './PaperContainer'
import React from 'react'
import IconTextButton from '../components/IconTextButton'


const PassportExploreContainer = props => {
  const {
    children,
    history,
    imgSrc,
    name,
    id,
  } = props

  return (
    <PaperContainer imgSrc={imgSrc} history={history} name={name}>
      <Grid container justify="space-around" direction="row" alignItems="center" style={{ marginTop: 5 }}>
        <Grid item sm={4}>
          <IconTextButton
            onClick={() => history.push(`/passport-explore/${id}`)}
            label='Destinations'
            lineHeight={2.0}
          >
            <ExploreOutlinedIcon fontSize="large" />
          </IconTextButton>
        </Grid>
        <Grid item sm={4}>
          <Grid container alignItems="flex-start" justify="center">
            <IconTextButton
              onClick={() => history.push(`/passport-explore-leaderboard/${id}`)}
              label='Leaderboard'
              lineHeight={0}
            >
              <TrophyIcon
                width="50px"
                height="50px"
                style={{ textAlign: 'center', vAlign: 'text' }}
              />
            </IconTextButton>
          </Grid>
        </Grid>
        <Grid item sm={4}>
          <IconTextButton
            onClick={() => history.push(`/passport-explore-map/${id}`)}
            label='Map'
            lineHeight={2.0}
          >
            <MapIcon fontSize="large" />
          </IconTextButton>
        </Grid>
      </Grid>
      {children}
    </PaperContainer>
  )
}

export default PassportExploreContainer
