import React, { useState } from 'react'
import ListItemText from '@material-ui/core/ListItemText'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import IconButton from '@material-ui/core/IconButton'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import DeleteIcon from '@material-ui/icons/Delete'
import BlockIcon from '@material-ui/icons/Block'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import VisibilityIcon from '@material-ui/icons/Visibility'
import MobileShare from './MobileShare'
import MotherMayIModal from './MotherMayIModal'


const OneActionListItem = props => {
  const {
    id,
    primaryText,
    secondaryText,
    handleAction,
    action,
    enableMobileShare,
    mobileShareTitle,
    mobileShareText,
    mobileShareUrl,
    mobileSharePersonName,
    mobileModalHeader,
    handleDelete,
  } = props

  const [openModal, setOpenModal] = useState(false)

  const handleOpenModal = () => setOpenModal(true)

  const handleCloseModal = () => setOpenModal(false)

  const handleConfirmClick = (idx) => {
    handleDelete(idx)
    handleCloseModal()
  }

  const handleIconClick = action === 'delete' ? handleOpenModal : handleAction

  const firstLetter = primaryText.charAt(0)

  return (
    <>
      <ListItem key={`list-item-${id}`}>
        <ListItemAvatar key={`list-item-avatar-${id}`}>
          <Avatar key={`avatar-${id}`}>{firstLetter}</Avatar>
        </ListItemAvatar>
        <ListItemText primary={primaryText} secondary={secondaryText} key={`list-item-text-${id}`} />
        <ListItemSecondaryAction style={{ marginRight: 50 }} key={`list-item-2-action-${id}`}>
          <IconButton edge="end" aria-label={action} key={`icon-button-${id}`} onClick={() => handleIconClick(id)}>
            {action === 'delete' && <DeleteIcon key={`delete-icon-${id}`} />}
            {action === 'disable' && <BlockIcon key={`block-icon-${id}`} />}
            {action === 'enable' && <AddCircleOutlineIcon key={`add-circle-icon-${id}`} />}
            {action === 'visible' && <VisibilityIcon key={`visible-circle-icon-${id}`} />}
          </IconButton>
        </ListItemSecondaryAction>
        {enableMobileShare && (
          <ListItemSecondaryAction>
            <MobileShare
              title={mobileShareTitle}
              text={mobileShareText}
              url={mobileShareUrl}
              personName={mobileSharePersonName}
              modalHeader={mobileModalHeader}
            />
        </ListItemSecondaryAction>
        )}
      </ListItem>
      {action === 'delete' &&
      <MotherMayIModal
        id={id}
        title="Really Delete Invite?"
        description={`This will permanently delete ${mobileSharePersonName}'s invite.`}
        confirmButtonText="Delete"
        handleConfirmClick={() => handleConfirmClick(id)}
        cancelButtonText="No"
        openModal={openModal}
        handleCancelClick={handleCloseModal}
      />}
    </>
  )
}

export default OneActionListItem
