import React, { useState } from 'react'
import { Button, ButtonGroup } from '@material-ui/core'
import ManageSentInvitationPage from '../pages/ManageSentInvitationPage'
import ManageReceivedInvitationPage from '../pages/ManageReceivedInvitationPage'


const InvitationContainer = () => {
  const [activeContainer, setActiveContainer] = useState(0)

  const getButtonVariant = active => active ? 'contained' : 'outlined'

  return (
    <>
      <ButtonGroup
        color="primary"
        aria-label="contained primary button group"
        style={{ marginTop: 75 }}
      >
        <Button
          onClick={() => setActiveContainer(0)}
          variant={getButtonVariant(activeContainer === 0)}>
          Received Invites
        </Button>
        <Button
          onClick={() => setActiveContainer(1)}
          variant={getButtonVariant(activeContainer === 1)}>
          Sent Invites
        </Button>
      </ButtonGroup>
      {activeContainer === 0 && <ManageReceivedInvitationPage />}
      {activeContainer === 1 && <ManageSentInvitationPage />}
    </>
  )
}

export default InvitationContainer
