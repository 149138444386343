import axios from 'axios'
import { useContext } from 'react'
import { sessionStore } from '../store/SessionStore'


const get_csrf_token = () => {
  axios.get('/api/csrf_token/').then().catch(err => {
    console.log(err)
  })
}

export const useCsrfToken = () => {
  const { state: { csrf }, dispatch } = useContext(sessionStore)

  if (!csrf) {
    get_csrf_token()
    dispatch({ type: 'csrf' })
  }
}
