import { useHistory, useParams } from 'react-router-dom'
import { useFetchItem, useFetchItemQueryParams } from '../helpers/apiFetchHooks'
import React from 'react'
import axios from 'axios'
import { formatDate } from '../helpers/utils'
import MapContainer from '../containters/MapContainer'
import DestinationDetails from '../components/DestinationDetails'
import DestinationExploreContainer from '../containters/DestinationExploreContainer'
import { imgUrl } from '../helpers/constants'

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'


const DestinationExplorePage = props => {
  const {
    history,
    destination,
    passportId,
    stamp,
    rating,
  } = props

  const stamped = stamp.status === 'stamped'
  const imgSrc = destination.image ? `${imgUrl}destinations/${destination.image}` : false
  const mapDestination = [{ ...destination, isStamped: stamped, stampDate: formatDate(stamp.created) }]


  return (
    <DestinationExploreContainer
      imgSrc={imgSrc}
      history={history}
      name={destination.name}
      rating={rating}
      id={destination.id}
      stamped={stamp.status === 'stamped'}
      stampDate={stamp.created}
      passportId={passportId}
    >
      <DestinationDetails destination={destination} />
      <MapContainer destinations={mapDestination} height={'35vh'} />
    </DestinationExploreContainer>
  )
}


const ManageDestinationExplore = () => {
  const { passportId, destinationId } = useParams()
  const history = useHistory()
  const { itemData: destination, fetched } = useFetchItem('destination_view', destinationId)
  const {
    itemData: stamp,
    fetched: stampFetched
  } = useFetchItemQueryParams('stamp', passportId, `destination=${destinationId}`)

  const { itemData: rating, fetched: ratingFetched } = useFetchItem('rating_review', destinationId)

  if (!fetched || !stampFetched || !ratingFetched) {
    return (
      <>
        <h2>Loading</h2>
      </>
    )
  } else if (fetched) {
    return (
      <DestinationExplorePage
        destination={destination}
        passportId={passportId}
        history={history}
        stamp={stamp}
        rating={rating}
      />
    )
  } else {
    return null
  }
}

export default ManageDestinationExplore
