import React, { useState } from 'react'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import { Visibility, VisibilityOff } from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

const LoginForm = props => {
  const { handleSubmit, values, handleChange, setFieldTouched, errors, touched, isValid, dirty } = props
  const [showPassword, setShowPassword] = useState(false)
  const change = (name, e) => {
    e.persist()
    handleChange(e)
    setFieldTouched(name, true, false)
  }
  const classes = useStyles()
  const disableSubmit = !(isValid && dirty)
  return (
    <>
      <Avatar className={classes.avatar}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Reset Your Password
      </Typography>
      <form className={classes.form}>
        <TextField
          variant="outlined"
          margin="normal"
          disabled
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
          value={values.email}
          onChange={change.bind(null, 'email')}
          helperText={touched.email ? errors.email : ''}
          error={touched.email && Boolean(errors.email)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          id="password"
          value={values.password}
          onChange={change.bind(null, 'password')}
          helperText={touched.password ? errors.password : ''}
          error={touched.password && Boolean(errors.password)}
          InputProps={{
             endAdornment:
               <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
           }}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="passwordConfirmation"
          label="Confirm Password"
          type={showPassword ? 'text' : 'password'}
          id="passwordConfirmation"
          value={values.passwordConfirmation}
          onChange={change.bind(null, 'passwordConfirmation')}
          helperText={touched.passwordConfirmation ? errors.passwordConfirmation : ''}
          error={touched.passwordConfirmation && Boolean(errors.passwordConfirmation)}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={handleSubmit}
          disabled={disableSubmit}
        >
          Set New Password
        </Button>
      </form>
    </>
  )
}

export default LoginForm
