import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Avatar } from '@material-ui/core'


const useStyles = makeStyles(theme => ({
  avatar: {
    textAlign: 'center',
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
    height: props => props.height || 50,
    width: props => props.width || 50,
    fontSize: props => props.fontSize || '2.25rem',
  },
}))

const ProfileAvatar = props => {
  const { firstName, src, fontSize, height, width } = props
  const classes = useStyles({fontSize, height, width})
  const avatarChild = firstName ? firstName.charAt(0) : null

  return (
    <Avatar src={src} className={classes.avatar}>
      {avatarChild}
    </Avatar>
  )
}

export default ProfileAvatar
