import React, { useState } from 'react'
import { ClickAwayListener, withStyles } from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'

const StyledTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
    maxWidth: 500,
  },
}))(Tooltip)

const HtmlTooltip = props => {
  const { children, tooltipIcon } = props
  const [open, setOpen] = useState(false)

  const handleTooltipClose = () => {
    setOpen(false)
  }

  const handleTooltipOpen = () => {
    setOpen(true)
  }

  return (
    <>
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <StyledTooltip
          onClick={handleTooltipOpen}
          arrow
          interactive
          placement="top-start"
          open={open}
          title={
            <>
              {children}
            </>
          }
        >
          {tooltipIcon}
        </StyledTooltip>
      </ClickAwayListener>
    </>
  )
}

export default HtmlTooltip
