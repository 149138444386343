import React, { useState } from 'react'
import PassportWalletPage from './PassportWalletPage'
import ManagePassportGalleryPage from './ManagePassportGalleryPage'
import { useFetchList } from '../helpers/apiFetchHooks'
import FeaturedPassportContainer from '../containters/FeaturedPassportContainer'
import { imgUrl } from '../helpers/constants'


const ManagePassportHomePage = () => {
  const { listData: passports, fetched, setListData: setPassports } = useFetchList('passport_gallery')
  const { listData: featuredPassports, fetched: featuredPassportsFetched } = useFetchList('passport_featured')
  const [activeStep, setActiveStep] = useState(0)

  if (!fetched || !featuredPassportsFetched) {
    return (
      <>
        <h2>Loading</h2>
      </>
    )
  } else {

    const passportItems = passports
      .filter(pass => pass.is_active === true)
      .map(pass => ({
        id: pass.id,
        label: pass.name,
        description: pass.description,
        imgPath: `${imgUrl}passports/${pass.image}`,
      }))

    return (
      <>
        {passportItems.length > 0 && (<PassportWalletPage passportItems={passportItems} activeStep={activeStep} setActiveStep={setActiveStep} />)}
        <FeaturedPassportContainer featuredPassports={featuredPassports} />
        <ManagePassportGalleryPage passports={passports} setPassports={setPassports} setActiveStep={setActiveStep} />
      </>
    )
  }
}

export default ManagePassportHomePage
