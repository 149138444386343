import { useHistory, useParams } from 'react-router-dom'
import React, { useState } from 'react'
import { useFetchItem, useFetchList } from '../helpers/apiFetchHooks'
import PassportExploreContainer from '../containters/PassportExploreContainer'
import { CircularProgress, Container, FormControlLabel } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import SnackbarAlert from '../components/SnackbarAlert'
import TextField from '@material-ui/core/TextField'
import { Autocomplete } from '@material-ui/lab'
import { Formik } from 'formik'
import * as yup from 'yup'
import Checkbox from '@material-ui/core/Checkbox'
import Button from '@material-ui/core/Button'
import axios from 'axios'
import useLogin from '../store/useLogin'
import { imgUrl } from '../helpers/constants'

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'

const PrizeItem = props => {
  const { prize } = props
  const imgSrc = prize.photos.length > 0 ? `${imgUrl}prizes/${prize.photos[0].name}` : null

  return (
    <Grid container justify="flex-start" alignContent="flex-end" style={{ marginBottom: 30 }}>
      {imgSrc && (
        <Grid item xs={8} sm={8} style={{ width: '100%' }}>
          <img src={imgSrc} style={{ maxWidth: '100%', maxHeight: '90vh' }} alt={prize.name} />
        </Grid>)}
      <Grid item xs={4} sm={4}>
        <Typography variant="h6" style={{ textAlign: 'center' }}>{prize.name}</Typography>
        <Typography variant="subtitle1" style={{ textAlign: 'center' }}>{prize.description}</Typography>
      </Grid>
    </Grid>
  )
}

const PrizeForm = props => {
  const {
    handleSubmit,
    values,
    handleChange,
    setFieldTouched,
    setFieldValue,
    errors,
    isValid,
    dirty,
    multiplePrizes,
    prizeOptions,
    noAddressInfo,
  } = props

  const change = (name, e) => {
    e.persist()
    handleChange(e)
    setFieldTouched(name, true, false)
  }

  const disableSubmit = !(isValid && dirty && values.verify)

  const handleGetOptionSelected = (option, value) => {
    if (value === '') {
      return true
    } else {
      return option.id === value.id
    }
  }

  return (
    <Container maxWidth="sm" component="form">
      {multiplePrizes && (
        <Autocomplete
          id="prize"
          options={prizeOptions}
          onChange={(_, value) => {
            setFieldValue('prize', value)
          }}
          fullWidth
          getOptionLabel={option => option.name || ''}
          value={values.prize}
          getOptionSelected={handleGetOptionSelected}
          disableClearable
          renderInput={(params) =>
            <TextField
              {...params}
              label="Prize"
              variant="outlined"
              required
              margin="normal"
              helperText={errors.prize || ''}
              error={Boolean(errors.prize)}
            />
          }
        />)}
      {!multiplePrizes && (
        <Autocomplete
          id="prize"
          options={prizeOptions}
          onChange={(_, value) => {
            setFieldValue('prize', value)
          }}
          fullWidth
          disabled
          getOptionLabel={option => option.name || ''}
          value={prizeOptions[0]}
          disableClearable
          renderInput={(params) =>
            <TextField
              {...params}
              label="Prize"
              variant="outlined"
              required
              margin="normal"
              helperText={errors.prize || ''}
              error={Boolean(errors.prize)}
            />
          }
        />)}
      {noAddressInfo && <Typography variant="body1" color="secondary">Please add address info to your profile.</Typography>}
        <FormControlLabel
        label="I verify that my profile address is accurate."
        control={
          <Checkbox
            onChange={change.bind(null, 'verify')}
            id="verify"
            name="verify"
            color="primary"
            inputProps={{ 'aria-label': 'verify' }}
            checked={values.featured}
            disabled={noAddressInfo}
          />}
      />
      <Button
        onClick={handleSubmit}
        variant="contained"
        color="primary"
        disabled={disableSubmit}
        style={{ marginBottom: 20 }}
      >
        Submit
      </Button>
    </Container>
  )
}


const PrizeLevelItem = props => {
  const {
    prizeLevel,
    progressValue,
    idx,
    viewOnly,
    prizeProfile,
    updatePrizeProfiles,
    noAddressInfo,
  } = props
  const pendingPrize = prizeProfile && prizeProfile.status === 'pending confirmation'
  const processingOrLater = prizeProfile && prizeProfile.status !== 'pending confirmation'
  const selectedPrizeIdx = processingOrLater ? prizeLevel.prizes.map(prize => prize.id).indexOf(prizeProfile.prize_selected) : null

  const prizeItems = prizeLevel.prizes.map((prize, idx) => (
    <PrizeItem prize={prize} key={idx} />
  ))
  const multiplePrizes = prizeItems.length > 1

  const schema = multiplePrizes ? yup.object({
    verify: yup.boolean().required(),
    prize: yup.string().required(),
  }) : yup.object({
    verify: yup.boolean().required(),
  })

  const prizeOptions = prizeLevel.prizes.map(prize => (
      { id: prize.id, name: prize.name }
    ),
  )

  const onSubmit = values => {
    const prizeId = multiplePrizes ? values.prize.id : prizeLevel.prizes[0].id
    const passportId = prizeLevel.passport
    const data = {
      stamps: prizeLevel.stamps,
      prize_selected: prizeId,
      status: 'processing',
    }
    axios.put(`/api/prize_profile/${passportId}/`, data)
      .then(res => {
        console.log(res.data)
        updatePrizeProfiles(res.data)
      })
      .catch(err => console.log(err))
  }

  return (
    <>
      <hr />
      {!viewOnly && pendingPrize && (
        <Grid container justify="center" alignItems="center" direction="row">
          <Typography variant="h5" style={{ marginTop: 20, marginBottom: 20 }}>Confirm your prize options!</Typography>
          <Formik
            validationSchema={schema}
            onSubmit={onSubmit}
            enableReinitialize={true}
            initialValues={{
              verify: false,
              prize: '',
            }}
            children={props => <PrizeForm
              {...props}
              multiplePrizes={multiplePrizes}
              prizeOptions={prizeOptions}
              noAddressInfo={noAddressInfo}
            />}
          />
        </Grid>)}
      <Grid container justify="space-between" alignItems="baseline" direction="row">
        <Grid item xs={5} sm={5}>
          <div style={{ display: 'flex' }}>
            <Typography variant="h6" style={{ textAlign: 'left' }}>Level:</Typography>
            <Typography variant="subtitle1" style={{
              textAlign: 'left',
              lineHeight: 2.1,
              marginLeft: 10,
            }}>{(idx + 1).toString()}</Typography>
          </div>
        </Grid>
        <Grid item xs={5} sm={5}>
          <div style={{ display: 'flex' }}>
            <Typography variant="h6" style={{ textAlign: 'left' }}>Stamps:</Typography>
            <Typography variant="subtitle1"
                        style={{ textAlign: 'left', lineHeight: 2.1, marginLeft: 10 }}>{prizeLevel.stamps}</Typography>
          </div>
        </Grid>
        <Grid item xs={2} sm={2}>
          {progressValue >= prizeLevel.stamps && (
            <CheckCircleOutlineIcon fontSize="large" style={{ color: 'green' }} />)}
        </Grid>
      </Grid>
      {!viewOnly && processingOrLater && (
        <Grid container justify="flex-start" alignItems="baseline" direction="row">
          <Grid item xs={12} sm={12}>
            <div style={{ display: 'flex' }}>
              <Typography variant="h6" style={{ textAlign: 'left' }}>Shipping status:</Typography>
              <Typography variant="subtitle1" style={{
                textAlign: 'left',
                lineHeight: 2,
                marginLeft: 10,
              }}>{prizeProfile.status}</Typography>
            </div>
          </Grid>
        </Grid>)}
      {!processingOrLater && (<Grid container justify="flex-start" alignItems="center" direction="row">
        {prizeLevel.prizes.length > 1 && (
          <Grid item xs={12} sm={12}>
            <Typography variant="h6" style={{ textAlign: 'left' }}>Prize Options:</Typography>
          </Grid>)}
        {prizeItems}
      </Grid>)}
      {!viewOnly && processingOrLater && (
        <Grid container justify="flex-start" alignItems="center" direction="row">
          <Grid item xs={12} sm={12}>
            <Typography variant="h6" style={{ textAlign: 'left' }}>Prize Selected:</Typography>
          </Grid>
          <PrizeItem prize={prizeLevel.prizes[selectedPrizeIdx]} />
        </Grid>
      )}
    </>
  )
}

export const PassportExplorePrizeContainer = props => {
  const {
    prize_levels,
    stamps,
    viewOnly,
    prizeProfiles,
    updatePrizeProfiles,
    profile: {
      address,
      city,
      state,
      zip,
    },
  } = props
  const progressValue = viewOnly ? -1 : stamps.filter(stamp => stamp.status === 'stamped').length
  const hasPrizeProfile = prizeProfiles && prizeProfiles.length > 0
  const hasPendingPrize = () => hasPrizeProfile ? prizeProfiles.filter(pProfile => pProfile.status === 'pending confirmation').length > 0 : false
  const [showSnackbar, setShowSnackbar] = useState(hasPendingPrize())
  const incompleteProfileAddress = (!address || !city || !state || !zip)
  const noAddressInfo = (!address && !city && !state && !zip)
  const [showAddressSnackbar, setShowAddressSnackbar] = useState(hasPendingPrize() && incompleteProfileAddress)
  const comma = (city && state) ? ',' : ''

  const prizeLevelItems = prize_levels.map((prizeLevel, idx) => {
    const prizeProfile = hasPrizeProfile ? prizeProfiles[idx] : null
    return (
      <PrizeLevelItem
        prizeLevel={prizeLevel}
        key={idx}
        idx={idx}
        noAddressInfo={noAddressInfo}
        progressValue={progressValue}
        viewOnly={viewOnly}
        prizeProfile={prizeProfile}
        updatePrizeProfiles={updatePrizeProfiles}
      />
    )
  })

  return (

    <Grid item xs={12} sm={12} style={{ marginTop: 20 }}>
      {!viewOnly && (
        <Grid container justify="space-between" direction="row" alignItems="baseline" style={{ marginTop: 5 }}>
          <Grid item sm={4} xs={4}>
            <Typography variant="h5">{progressValue}</Typography>
            <Typography variant="subtitle1">{progressValue === 1 ? `Stamp` : `Stamps`} Earned</Typography>
          </Grid>
          <Grid item sm={8} xs={8}>
            <Typography variant="subtitle1">Address we will use:</Typography>
            {(address || city || state || zip) && (
              <div style={{ marginTop: 5 }}>
                {address && <Typography variant="h6">{address}</Typography>}
                {(city || state || zip) && <Typography variant="h6">{city}{comma} {state} {zip}</Typography>}
              </div>)}
            <Button variant="outlined" color="primary" href='/profile-home'>Update Address</Button>
          </Grid>
        </Grid>)}
      {prizeLevelItems}
      <SnackbarAlert
        showSnackbar={showSnackbar}
        setShowSnackbar={setShowSnackbar}
        severity="info"
        message="You have a pending prize. Complete the form below to receive your prize."
        disableAutoHide
      />
      <SnackbarAlert
        showSnackbar={showAddressSnackbar}
        setShowSnackbar={setShowAddressSnackbar}
        severity="error"
        message="Please add a complete address to your profile so we can send your prize."
        disableAutoHide
      />
    </Grid>
  )
}


const PassportExplorePrizePage = () => {
  const { id } = useParams()
  const { profileId } = useLogin()
  const history = useHistory()
  const { itemData: passport, fetched: passportFetched } = useFetchItem('passport_wallet', id)
  const { listData: stamps, fetched: stampsFetched } = useFetchList('stamp', { id: id })
  const {
    listData: prizeProfiles,
    fetched: prizeProfilesFetched,
    setListData: setPrizeProfiles,
  } = useFetchList('prize_profile', { id: id })
  const { itemData: profile, fetched: fetchedProfile } = useFetchItem('profile', profileId)

  const updatePrizeProfiles = newPrizeProfile => {
    const prizeProfileIdx = prizeProfiles.map(pProfile => pProfile.stamps).indexOf(newPrizeProfile.stamps)
    let newPrizeProfiles = [...prizeProfiles]
    newPrizeProfiles[prizeProfileIdx] = newPrizeProfile
    setPrizeProfiles(newPrizeProfiles)
  }

  if (!passportFetched || !stampsFetched || !prizeProfilesFetched || !fetchedProfile) {
    return (
      <CircularProgress />
    )
  } else {
    const imgSrc = passport.image ? `${imgUrl}passports/${passport.image}` : null
    return (
      <PassportExploreContainer history={history} imgSrc={imgSrc} name={passport.name} id={id}>
        <PassportExplorePrizeContainer
          prize_levels={passport.prize_levels}
          stamps={stamps}
          prizeProfiles={prizeProfiles}
          updatePrizeProfiles={updatePrizeProfiles}
          profile={profile}
        />
      </PassportExploreContainer>
    )
  }
}

export default PassportExplorePrizePage
