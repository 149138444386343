import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Grid from '@material-ui/core/Grid'
import { Button, CircularProgress, Typography } from '@material-ui/core'
import { useHistory, useParams } from 'react-router-dom'
import { useFetchItem, useFetchItemQueryParams } from '../helpers/apiFetchHooks'
import DestinationExploreContainer from '../containters/DestinationExploreContainer'
import Container from '@material-ui/core/Container'
import confetti from '../confetti.gif'
import MapContainer from '../containters/MapContainer'
import { formatDate } from '../helpers/utils'
import Link from '@material-ui/core/Link'
import QrCodeReader from '../components/QrCodeReader'
import SnackbarAlert from '../components/SnackbarAlert'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQrcode } from '@fortawesome/free-solid-svg-icons'
import { imgUrl } from '../helpers/constants'


const DestinationStampContainer = props => {
  const { handleSubmitStamp } = props
  return (
    <>
      <Grid item sm={12} style={{ marginTop: 10, marginBottom: 10 }}>
          <Button variant="contained" color="primary" onClick={() => handleSubmitStamp()}>
            Stamp Me
          </Button>
      </Grid>
    </>
  )
}


const useGeoLocation = () => {
  const [latitude, setLatitude] = useState()
  const [longitude, setLongitude] = useState()
  const [fetched, setFetched] = useState(false)

  useEffect(() => {
    getLocation()
  }, [])

  function success(position) {
    setLatitude(position.coords.latitude)
    setLongitude(position.coords.longitude)
    setFetched(true)
  }

  function error(err) {
    console.log('Error Code = ' + err.code + ' - ' + err.message)
    setFetched(true)
  }

  function getLocation() {
    setFetched(false)
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(success, error)
    } else {
      console.log('Geolocation services not available in this browser')
      setFetched(true)
    }
  }

  return { latitude, longitude, getLocation, fetched }
}

//https://cloud.google.com/blog/products/maps-platform/how-calculate-distances-map-maps-javascript-api
function calculateDistance(lat1, long1, lat2, long2) {
  const R = 3958.8 // Radius of the Earth in miles
  const rlat1 = lat1 * (Math.PI / 180) // Convert degrees to radians
  const rlat2 = lat2 * (Math.PI / 180) // Convert degrees to radians
  const difflat = rlat2 - rlat1 // Radian difference (latitudes)
  const difflon = (long2 - long1) * (Math.PI / 180) // Radian difference (longitudes)

  const distance = 2 * R * Math.asin(Math.sqrt(Math.sin(difflat / 2) * Math.sin(difflat / 2) + Math.cos(rlat1) * Math.cos(rlat2) * Math.sin(difflon / 2) * Math.sin(difflon / 2)))
  return distance
}

const LatLongContainer = props => {
  const {
    latitude,
    longitude,
    destination,
    handleSubmitStamp,
    getLocation,
  } = props

  const distance = calculateDistance(
    latitude, longitude, destination.latitude, destination.longitude).toFixed(2)

  const proximity = 0.5
  const disableStamp = distance > proximity
  return (
    <>
      {disableStamp && (
        <Grid item sm={12}>
          <Typography variant="h6">You need to get closer to get your stamp</Typography>
          <Typography variant="subtitle1">Proximity required: {proximity} miles</Typography>
          <Typography variant="subtitle1">Current distance from destination: {distance} miles</Typography>
          <Typography variant="subtitle1">Get closer and click the "Update Location" button.</Typography>
        </Grid>
      )}
      {!disableStamp && (
        <Grid item sm={12}>
          <Typography variant="h6">You're here!</Typography>
          <Typography variant="subtitle1">Click the "Stamp Me" button below.</Typography>
          <Typography variant="subtitle1">Current distance from destination: {distance} miles</Typography>
        </Grid>
      )}
      <Grid item sm={12} style={{ marginTop: 10, marginBottom: 10 }}>
        {!disableStamp && (
          <Button variant="contained" color="primary" onClick={() => handleSubmitStamp()} disabled={disableStamp}>
            Stamp Me
          </Button>
        )}
        {disableStamp && (
          <Button variant="outlined" color="primary" style={{ marginLeft: 10 }} onClick={() => getLocation()}>
            Update Location
          </Button>
        )}
      </Grid>
      <Grid item sm={12}>
        <MapContainer
          destinations={[{ ...destination, isStamped: false }, {
            name: 'Your location',
            latitude,
            longitude,
            userLocation: true,
          }]}
          height={'35vh'}
        />
      </Grid>
    </>
  )
}

const DestinationStampGpsContainer = props => {
  const { handleSubmitStamp, destination } = props
  const { latitude, longitude, getLocation, fetched } = useGeoLocation()
  const showLatLongContainer = fetched && latitude && longitude

  return (
    <>
      {fetched && !showLatLongContainer && (
        <Grid item sm={12}>
          <Typography variant="subtitle1">
            There was an error getting your coordinates.
            Ensure that you have enabled location services for this site.
            Info on how to update those {' '}
            <Link
              href="https://docs.buddypunch.com/en/articles/919258-how-to-enable-location-services-for-chrome-safari-edge-and-android-ios-devices-gps-setting"
              target="_blank"
              rel="noopener">
              settings is available here.
            </Link>
          </Typography>
          <Typography variant="subtitle1" style={{ marginTop: 20, marginBottom: 20 }}>
            After updating your settings, click below.
          </Typography>
          <Button variant="outlined" color="primary" style={{ marginLeft: 10 }} onClick={() => getLocation()}>
            Update Location
          </Button>
        </Grid>
      )}
      {!fetched && (
        <Grid item sm={12}>
          <Typography variant="subtitle1">Loading your location</Typography>
          <CircularProgress color="inherit" />
        </Grid>
      )}
      {showLatLongContainer && (
        <LatLongContainer
          latitude={latitude}
          longitude={longitude}
          destination={destination}
          handleSubmitStamp={handleSubmitStamp}
          getLocation={getLocation}
        />
      )}
    </>
  )
}

const DestinationStampQrContainer = props => {
  const {
    error,
    setError,
    scan,
    setScan,
  } = props

  return (
    <Grid item sm={12}>
        <>
          {!scan && <Typography variant="h6">Scan QR code to get stamp</Typography>}
          <Button
            variant="contained"
            onClick={() => setScan(!scan)}
            color="primary"
            style={{ marginTop: 20, marginBottom: 20 }}
            endIcon={<FontAwesomeIcon icon={faQrcode} />}
          >
            Start Scanning
          </Button>
        </>
      <SnackbarAlert
        showSnackbar={!!(error)}
        setShowSnackbar={setError}
        severity="error"
        message={error}
        disableAutoHide
      />
    </Grid>
  )
}


const DestinationExploreStampPage = () => {
  const { passportId, destinationId } = useParams()
  const history = useHistory()
  const { itemData: destination, fetched } = useFetchItem('destination_view', destinationId)
  const {
    itemData: stamp,
    fetched: stampFetched,
    setItemData: setStamp,
  } = useFetchItemQueryParams('stamp', passportId, `destination=${destinationId}`)
  const { itemData: ratingReview, fetched: ratingReviewFetched } = useFetchItem('rating_review', destinationId)
  const [showConfetti, setShowConfetti] = useState(false)
  const [error, setError] = useState('')
  const [scan, setScan] = useState(false)
  const disableUndoStamp = stamp.disable_undo_stamp
  const stamped = stamp.status === 'stamped'

  const imgSrc = destination.image ? `${imgUrl}destinations/${destination.image}` : false

  const handleShowConfetti = prizeReached => {
    setTimeout(() => {
      setShowConfetti(false)
      if (prizeReached) history.push(`/passport-explore-prize/${passportId}`)
    }, 3000)
    setShowConfetti(true)
    setTimeout(3000)
  }

  const handleSubmitStamp = (qr_code_data = null) => {
    const data = {
      passport: passportId,
      destination: destinationId,
      status: 'stamped',
      qr_code_data: qr_code_data,
    }
    axios.post('/api/stamp/', data)
      .then(res => {
        setStamp(res.data)
        handleShowConfetti(res.data.prize_level_reached)
      })
      .catch(err => {
        setError(err.response.data.msg)
      })
  }

  const handleDeleteStamp = () => {
    axios.delete(`/api/stamp/${stamp.id}`)
      .then(res => {
        setStamp({})
      })
      .catch(err => {
        console.log(err)
      })
  }


  if (!fetched || !stampFetched || !ratingReviewFetched) {
    return (
      <CircularProgress />
    )
  } else {
    return (
      <>
        {showConfetti ? (
          <Container component="main" maxWidth="sm" style={{ marginTop: 20, marginBottom: 20 }}>
            <>
              <img src={confetti} alt="confetti" style={{ maxWidth: '100wh' }} />
              <Typography variant="h4">Congratulations!!!</Typography>
            </>
          </Container>
        ) : (
          <DestinationExploreContainer
            imgSrc={imgSrc}
            history={history}
            name={destination.name}
            rating={ratingReview}
            id={destinationId}
            stamped={stamp.status === 'stamped'}
            stampDate={stamp.created}
            passportId={passportId}
            hide={scan}
          >
            <Grid item sm={12}>
              {stamp.status !== 'stamped' && (<Typography variant="h5">Get Your Stamp</Typography>)}
              {stamp.status === 'stamped' && (
                <Typography variant="h5">You were stamped on {formatDate(stamp.created)}</Typography>)}
            </Grid>
            {stamped && !disableUndoStamp && (
              <Grid item sm={12}>
                <Button variant="contained" color="primary" onClick={() => handleDeleteStamp()}>
                  Undo stamp
                </Button>
              </Grid>
            )}
            {destination.stamp_type === 'Honor' && !stamped && (
              <DestinationStampContainer
                handleSubmitStamp={handleSubmitStamp}
              />)}
            {destination.stamp_type === 'GPS' && !stamped && (
              <DestinationStampGpsContainer
                handleSubmitStamp={handleSubmitStamp}
                destination={destination}
              />)}
            {destination.stamp_type === 'QR' && !stamped && (
              <DestinationStampQrContainer
                error={error}
                setError={setError}
                scan={scan}
                setScan={setScan}
              />)}
          </DestinationExploreContainer>
        )}
        {scan && (
          <QrCodeReader
            setError={setError}
            setScan={setScan}
            handleSubmitStamp={handleSubmitStamp}
          />)}
      </>
    )
  }
}

export default DestinationExploreStampPage
