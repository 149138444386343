import React from 'react'
import QrReader from 'react-qr-reader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQrcode } from '@fortawesome/free-solid-svg-icons'
import { Button } from '@material-ui/core'

const QrCodeReader = props => {
  const { setError, setScan, handleSubmitStamp } = props

  const handleScan = data => {
    if (data) {
      setScan(false)
      handleSubmitStamp(data)
    }
  }

  const handleError = err => {
    const errString = err.toString()
    if (errString.includes('Permission denied')) {
      setError('Permission denied - Enable site to use camera')
    } else {
      setError('Unknown error - check privacy permissions')
    }
    setScan(false)
  }

  return (
    <>
      <div>
        <QrReader
          delay={300}
          onError={handleError}
          onScan={handleScan}
          style={{ width: '100%' }}
        />
      </div>
      <Button
        variant="contained"
        onClick={() => setScan(false)}
        color="primary"
        style={{ marginTop: 20, marginBottom: 20 }}
        endIcon={<FontAwesomeIcon icon={faQrcode} />}
      >
        Stop Scanning
      </Button>
    </>
  )
}

export default QrCodeReader
