import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import 'bootstrap/dist/css/bootstrap.min.css'
import { StateProvider } from './store/Store'
import { BrowserRouter as Router } from 'react-router-dom'
import { SessionStateProvider } from './store/SessionStore'

ReactDOM.render(
  <StateProvider>
    <SessionStateProvider>
      <Router>
        <App />
      </Router>
    </SessionStateProvider>
  </StateProvider>,
  document.getElementById('root'),
)
