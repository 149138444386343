import React, { useEffect } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { useHistory } from 'react-router-dom'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import DemoAppContainer from '../containters/DemoAppContainer'
import { Link as ScrollLink } from 'react-scroll'
import ContactUs from '../containters/ContactUs'
import useLogin from '../store/useLogin'
import useActivePassport from '../store/useActivePassport'

const useStyles = makeStyles((theme) => ({
  heroImage: {
    backgroundColor: theme.palette.primary.main,
    minWidth: '100wh',
    width: '100%',
    height: '94vh',
    // backgroundPosition: 'center',
    // backgroundRepeat: 'no-repeat',
    // backgroundSize: 'contain',
    // position: 'relative',
  },
  heroButtonOutline: {
    marginTop: theme.spacing(4),
    color: 'white',
    borderColor: 'white',
    margin: 10,
  },
  heroButtonContain: {
    marginTop: theme.spacing(4),
    color: theme.palette.primary.main,
    backgroundColor: 'white',
    margin: 10,
  },
  heroText: {
    color: 'white',
    position: 'absolute',
    width: '95%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}))


const LandingPage = () => {
  const classes = useStyles()
  const history = useHistory()
  const isTinyMobile = useMediaQuery('(max-width:345px)')
  const typeVariant = isTinyMobile ? 'h3' : 'h2'
  const { drfCookie } = useLogin()
  const activePassport = useActivePassport('last')

  useEffect(() => {
    if (drfCookie && activePassport) {
      history.push(`/passport-explore/${activePassport.id}`)
    } else if (drfCookie) {
      history.push('/passport-home')
    }
  }, [activePassport, drfCookie, history])

  return (
    <>
      <div className={classes.heroImage} data-react={16} id="home">
        <div className={classes.heroText}>
          <Typography variant={typeVariant} paragraph align="center">
            Together, we're going places!
          </Typography>
          <Typography variant="h6" paragraph align="center">Vagabongo is your partner for building truly engaging
            customer experiences</Typography>
          <Grid container spacing={2} justify="center">
            <Grid item>
              <ScrollLink
                activeClass="buttonActive"
                to="demo"
                spy={true}
                offset={-75}
                duration={1000}
                smooth={true}
              >
                <Button variant="outlined" className={classes.heroButtonOutline} size="large">
                  Learn More
                </Button>
              </ScrollLink>
              <Button variant="contained" className={classes.heroButtonContain} size="large"
                      onClick={() => history.push('/profile')}>
                Get your passport
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
      <DemoAppContainer history={history} />
      <ContactUs />
    </>
  )
}

export default LandingPage
