

const iframePrint = data =>
  new Promise(resolve => {
    const iframe = document.createElement('iframe')

    iframe.onload = () => {
      iframe.contentWindow.onbeforeunload = () => {
        window.document.body.removeChild(iframe)
      }
      iframe.contentWindow.onafterprint = () => {
        window.document.body.removeChild(iframe)
      }
      iframe.focus()
      iframe.contentWindow.print()

      resolve('Successful iframe print')
    }
    iframe.style.position = 'fixed'
    iframe.style.width = '0'
    iframe.style.height = '0'
    iframe.srcdoc = data
    document.body.appendChild(iframe)
  })

export const print = (content, forceIframeOnBlocked = false) => {
  let newTab = window.open()
  return new Promise(res => {
    if (Promise.resolve(content) === content) {
      content.then(response => {
        res(handlePrint(newTab, response, forceIframeOnBlocked))
      })
    } else {
      res(handlePrint(newTab, content, forceIframeOnBlocked))
    }
  })
}

const handlePrint = (newTab, htmlData, forceIframeOnBlocked = false) =>
  new Promise((resolve, reject) => {
    if (!newTab) {
      if (forceIframeOnBlocked) {
        // iframe failures are allowed since pop-ups are preferred
        try {
          return iframePrint(htmlData)
        } catch (err) {
          reject('Printing requires pop-up blocker to be disabled')
        }
      } else {
        reject('Printing requires pop-up blocker to be disabled')
      }
    } else {
      try {
        newTab.document.open()
        newTab.document.write(htmlData)
        newTab.document.close()
      } catch {
        newTab.close() // Hopefully this is allowed
        if (forceIframeOnBlocked) {
          return iframePrint(htmlData)
        } else {
          reject('Access Denied')
        }
      }

      newTab.focus() // Required for IE
      if (newTab.onafterprint || newTab.onafterprint === null) {
        newTab.onafterprint = () => {
          // Timeout prevents Safari from crashing
          setTimeout(() => {
            newTab.close()
            resolve('Successful print')
          }, 200)
        }
        // Some Android browsers can't print
        if (newTab.print) {
          // Timeout required for Mobile Chrome
          setTimeout(() => {
            newTab.print()
          }, 200)
        }
      } else {
        setTimeout(() => {
          newTab.print()
          resolve('Legacy printing successful')
          // iOS/Safari doesnt wait for print dialog to finish
          // no way of identifying when it's safe to close without onafterprint
          // These users will now have to close out the tab themselves
        }, 200)
      }
    }
  })
