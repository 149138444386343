import Container from '@material-ui/core/Container'
import React from 'react'
import PassportListPage from './PassportListPage'
import DestinationListPage from './DestinationListPage'
import PrizeListPage from './PrizeListPage'
import { useFetchList } from '../helpers/apiFetchHooks'
import { CircularProgress } from '@material-ui/core'
import ManagePrizeFulfillmentPage from './ManagePrizeFulfillmentPage'


const ManageAdminHomePage = () => {
  const { listData: permission, fetched: fetchedPermission } = useFetchList('permissions')
  if (!fetchedPermission) {
    return (
      <CircularProgress />
    )
  } else if (fetchedPermission && permission.is_superuser) {
    return (
      <Container component="main" maxWidth="md">
        <>
          <PassportListPage />
          <DestinationListPage />
          <PrizeListPage />
          <ManagePrizeFulfillmentPage />
        </>
      </Container>
    )
  } else return null
}

export default ManageAdminHomePage
