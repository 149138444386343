export const formatDate = (dateString) => {
  const options = { year: 'numeric', month: 'short', day: 'numeric' }
  return new Date(dateString).toLocaleDateString(undefined, options)
}

export const truncate = (longStr, max) => (longStr.length > max ? `${longStr.substring(0, max)}...` : longStr)

export const normalizePhoneNumber = value => {
  // From https://stackoverflow.com/a/55989037/10044829
  // return nothing if no value
  if (!value) return value

  // only allows 0-9 inputs
  const currentValue = value.replace(/[^\d]/g, '')
  const cvLength = currentValue.length

  // returns: "x", "xx", "xxx"
  if (cvLength < 4) return currentValue

  // returns: "(xxx)", "(xxx) x", "(xxx) xx", "(xxx) xxx",
  if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`

  // returns: "(xxx) xxx-", (xxx) xxx-x", "(xxx) xxx-xx", "(xxx) xxx-xxx", "(xxx) xxx-xxxx"
  return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`
}

export const phoneRegEx = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/

export const formatPhone = phoneStr => {
  if (!phoneStr) return phoneStr
  const firstPhoneChar = phoneStr.charAt(0)
  if (firstPhoneChar === '+') {
    return phoneStr.substring(2)
  } else {
    return phoneStr
  }
}

export const formatPhoneNumber = phoneNumber => {
    const noPlusOnePhone = formatPhone(phoneNumber)
    return normalizePhoneNumber(noPlusOnePhone)
  }
