import { useHistory, useParams } from 'react-router-dom'
import { useFetchItem } from '../helpers/apiFetchHooks'
import { CircularProgress, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import * as yup from 'yup'
import { Formik } from 'formik'
import TextField from '@material-ui/core/TextField'
import { DropzoneArea } from 'material-ui-dropzone'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import axios from 'axios'
import Grid from '@material-ui/core/Grid'
import { imgUrl } from '../helpers/constants'

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'


const PrizePhoto = props => {
  const { photo, prizeId, setPrizeData } = props

  const handleDelete = () => {
    const data = {
      photo_id: photo.id,
    }
    axios.patch(`/api/prize/${prizeId}/`, data)
      .then(res => {
        setPrizeData(res.data)
      }).catch()
  }

  return (
    <Grid item style={{ maxWidth: '90wh', maxHeight: '90vh' }}>
      <img src={`${imgUrl}prizes/${photo.name}`} alt={photo.name} style={{ maxWidth: '100%', maxHeight: '90vh' }} />
      <Button
        variant="contained"
        color="secondary"
        onClick={handleDelete}
        style={{ marginTop: 10, marginBottom: 10 }}
      >
        Delete Image
      </Button>
    </Grid>
  )
}


const PrizeForm = props => {
  const {
    handleSubmit,
    values,
    handleChange,
    setFieldTouched,
    errors,
    touched,
    isValid,
    dirty,
    setImageFiles,
    isEdit,
    prizePhotos,
    setPrizeData,
    id,
  } = props

  const disableSubmit = isEdit ? !(isValid) : !(isValid && dirty)
  const imageLimit = (3 - prizePhotos.length || 0)
  const showDropZone = !prizePhotos || prizePhotos.length < 3

  const change = (name, e) => {
    e.persist()
    handleChange(e)
    setFieldTouched(name, true, false)
  }

  const onKeyDown = keyEvent => {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault()
    }
  }

  const handleFileChange = files => {
    setImageFiles(files)
  }

  const getPrizePhotos = photos => {
    return photos.map((photo, idx) => (
      <PrizePhoto
        photo={photo}
        key={idx}
        setPrizeData={setPrizeData}
        prizeId={id}
      />
    ))
  }

  const PrizePhotos = isEdit && prizePhotos.length > 0 ? getPrizePhotos(prizePhotos) : null

  return (
    <form style={{ width: '100%' }} onKeyDown={onKeyDown}>
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        name="name"
        label="Prize Name"
        placeholder="Prize Name"
        id="name"
        margin="normal"
        value={values.name}
        onChange={change.bind(null, 'name')}
        helperText={touched.name ? errors.name : ''}
        error={touched.name && Boolean(errors.name)}
      />
      <TextField
        variant="outlined"
        margin="normal"
        required
        multiline
        rows={6}
        fullWidth
        margin="normal"
        name="description"
        label="Description"
        placeholder="Description"
        id="description"
        value={values.description}
        onChange={change.bind(null, 'description')}
        helperText={touched.description ? errors.description : ''}
        error={touched.description && Boolean(errors.description)}
      />
      {PrizePhotos}
      {showDropZone && (
        <DropzoneArea
          acceptedFiles={['image/*']}
          dropzoneText={`Add up to ${imageLimit} photos`}
          filesLimit={imageLimit}
          style={{ outline: 'none' }}
          maxFileSize={75000000}
          onChange={files => {
            handleFileChange(files)
          }}
        />
      )}
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        disabled={disableSubmit}
        style={{ marginTop: 15 }}
      >
        Submit
      </Button>
    </form>
  )
}


const CreateEditPrizePage = props => {
  const {
    initialState,
    id,
    isEdit,
    setImageFiles,
    handleSubmit,
    setPrizeData,
  } = props

  const headerText = isEdit ? 'Edit Prize' : 'Add Prize'

  const schema = yup.object({
    name: yup.string().required(),
    description: yup.string().required(),
  })


  return (
    <>
      <Typography variant="h5">{headerText}</Typography>
      <Formik
        validationSchema={schema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
        initialValues={{
          name: initialState.name,
          description: initialState.description,
        }}
        children={props => (
          <PrizeForm
            {...props}
            setImageFiles={setImageFiles}
            isEdit={isEdit}
            id={id}
            prizePhotos={initialState.photos}
            setPrizeData={setPrizeData}
          />)}
      />
    </>
  )

}


const getInitialState = data => {
  return {
    id: data.id || '',
    name: data.name || '',
    description: data.description || '',
    photos: data.photos || [],
  }
}

const ManagePrizePage = () => {
  const { id: idFromUrl } = useParams()
  const history = useHistory()
  const { itemData: prizeData, fetched: fetchedPrizeData, setItemData: setPrizeData } = useFetchItem('prize', idFromUrl)
  const [imageFiles, setImageFiles] = useState([])

  const handleSubmit = values => {
    let form_data = new FormData()
    form_data.append('name', values.name)
    form_data.append('description', values.description)
    imageFiles.forEach((imgFile, idx) => {
      form_data.append(`image_file_${idx}`, imgFile, imgFile.name)
    })

    if (idFromUrl) {
      axios.put(`/api/prize/${idFromUrl}/`, form_data, {
        headers: {
          'content-type': 'multipart/form-data',
        },
      }).then(() => {
        history.push('/prize-list')
      }).catch(err => console.log(err))

    } else {
      axios.post('/api/prize/', form_data, {
        headers: {
          'content-type': 'multipart/form-data',
        },
      }).then(() => {
        history.push('/prize-list')
      }).catch(err => console.log(err))
    }
  }

  if (!fetchedPrizeData) {
    return (
      <CircularProgress />
    )
  } else {
    const initialState = getInitialState(prizeData)

    return (
      <Container component="main" maxWidth="sm" style={{ marginTop: 20, marginBottom: 20 }}>
        <CreateEditPrizePage
          initialState={initialState}
          isEdit={!!(idFromUrl)}
          id={idFromUrl}
          setImageFiles={setImageFiles}
          handleSubmit={handleSubmit}
          setPrizeData={setPrizeData}
        />
      </Container>
    )
  }

}

export default ManagePrizePage
