import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { Link } from 'react-router-dom'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import { normalizePhoneNumber } from '../helpers/utils'
import ProfileAvatar from '../components/ProfileAvatar'
import { DropzoneArea } from 'material-ui-dropzone'

const useStyles = makeStyles(theme => ({
  dropZone: {
    width: '100%',
    minHeight: 175,
  },
}))

const ProfileForm = props => {
  const {
    handleSubmit,
    values,
    handleChange,
    setFieldTouched,
    errors,
    touched,
    isValid,
    phoneValue,
    setPhoneValue,
    dirty,
    saving,
    imageFile,
    setImageFile,
  } = props
  const [showPassword, setShowPassword] = useState(false)
  const change = (name, e) => {
    e.persist()
    if (name === 'phone') {
      const prettyPhone = normalizePhoneNumber(e.target.value || '')
      setPhoneValue(prettyPhone)
    } else {
      handleChange(e)
    }
    setFieldTouched(name, true, false)
  }
  const classes = useStyles()
  const disableSubmit = !(isValid && phoneValue.length === 14 && dirty)
  const imagePath = imageFile ? URL.createObjectURL(imageFile) : null

  return (
    <form style={{ width: '100%' }}>
      <Grid container justify="center" direction="column" alignItems="center">
        <ProfileAvatar
          src={imagePath}
          fontSize='3.0rem'
          height={75}
          width={75}
        />
        <DropzoneArea
          acceptedFiles={['image/*']}
          dropzoneClass={classes.dropZone}
          dropzoneText="Add profile photo"
          filesLimit={1}
          useChipsForPreview
          style={{ outline: 'none' }}
          maxFileSize={75000000}
          onChange={files => {
            setImageFile(files[0])
          }}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="firstName"
          label="First Name"
          name="firstName"
          autoComplete="firstName"
          autoFocus
          value={values.firstName}
          onChange={change.bind(null, 'firstName')}
          helperText={touched.firstName ? errors.firstName : ''}
          error={touched.firstName && Boolean(errors.firstName)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="lastName"
          label="Last Name"
          type="lastName"
          id="lastName"
          autoComplete="lastName"
          value={values.lastName}
          onChange={change.bind(null, 'lastName')}
          helperText={touched.lastName ? errors.lastName : ''}
          error={touched.lastName && Boolean(errors.lastName)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          value={values.email}
          onChange={change.bind(null, 'email')}
          helperText={touched.email ? errors.email : ''}
          error={touched.email && Boolean(errors.email)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="phone"
          label="Phone Number"
          name="phone"
          autoComplete="phone"
          value={phoneValue}
          onChange={change.bind(null, 'phone')}
          helperText={touched.phone ? errors.phone : ''}
          error={touched.phone && Boolean(errors.phone)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          id="password"
          autoComplete=""
          value={values.password}
          onChange={change.bind(null, 'password')}
          helperText={touched.password ? errors.password : ''}
          error={touched.password && Boolean(errors.password)}
          InputProps={{
            endAdornment:
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>,
          }}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="passwordConfirmation"
          label="Confirm Password"
          type={showPassword ? 'text' : 'password'}
          id="passwordConfirmation"
          value={values.passwordConfirmation}
          onChange={change.bind(null, 'passwordConfirmation')}
          helperText={touched.passwordConfirmation ? errors.passwordConfirmation : ''}
          error={touched.passwordConfirmation && Boolean(errors.passwordConfirmation)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          name="address"
          label="Address"
          id="address"
          autoComplete="address"
          value={values.address}
          onChange={change.bind(null, 'address')}
          helperText={touched.address ? errors.address : ''}
          error={touched.address && Boolean(errors.address)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          name="city"
          label="City"
          id="city"
          autoComplete="city"
          value={values.city}
          onChange={change.bind(null, 'city')}
          helperText={touched.city ? errors.city : ''}
          error={touched.city && Boolean(errors.city)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          name="state"
          label="State"
          id="state"
          autoComplete="state"
          value={values.state}
          onChange={change.bind(null, 'state')}
          helperText={touched.state ? errors.state : ''}
          error={touched.state && Boolean(errors.state)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          name="zip"
          label="Zip"
          id="zip"
          autoComplete="zip code"
          value={values.zip}
          onChange={change.bind(null, 'zip')}
          helperText={touched.zip ? errors.zip : ''}
          error={touched.zip && Boolean(errors.zip)}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          disabled={disableSubmit}
          color="primary"
          className={classes.submit}
          onClick={handleSubmit}
        >
          Create Profile
        </Button>
        <Grid container>
          <Grid item xs>
            <Link to="/password-reset">
              <Typography variant="body2" align="left">
                Forgot password?
              </Typography>
            </Link>
          </Grid>
          <Grid item>
            <Link to="/login-page">
              <Typography variant="body2" align="right">Have an account? Sign in</Typography>
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </form>
  )
}

export default ProfileForm