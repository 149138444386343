import React from 'react'
import { Route, Switch } from 'react-router-dom'

import ProfilePage from '../pages/ProfilePage'
import LoginPage from '../pages/LoginPage'
import PasswordResetLinkPage from '../pages/PasswordResetLinkPage'
import PasswordResetPage from '../pages/PasswordResetPage'
import PublicQrCodePage from '../pages/PublicQrCodePage'
import LandingPage from '../pages/LandingPage'
import PrivateRoute from '../helpers/PrivateRoute'
import ManageDestinationPage from '../pages/ManageDestinationPage'
import ManageDestinationExplore from '../pages/ManageDestinationExplore'
import DestinationExploreReviewPage from '../pages/DestinationExploreReviewPage'
import DestinationExploreStampPage from '../pages/DestinationExploreStampPage'
import DestinationListPage from '../pages/DestinationListPage'
import ViewDestinationPage from '../pages/ViewDestinationPage'
import ViewDestinationReviewPage from '../pages/ViewDestinationReviewPage'
import ManagePassportExplore from '../pages/ManagePassportExplore'
import PassportExploreLeaderboardPage from '../pages/PassportExploreLeaderboardPage'
import PassportExploreLeaderboardDetailPage from '../pages/PassportExploreLeaderboardDetailPage'
import PassportExploreMapPage from '../pages/PassportExploreMapPage'
import ManagePassportHomePage from '../pages/ManagePassportHomePage'
import ManagePassportPage from '../pages/ManagePassportPage'
import ManagePassportGalleryPage from '../pages/ManagePassportGalleryPage'
import PassportListPage from '../pages/PassportListPage'
import ViewPassportPage from '../pages/ViewPassportPage'
import PassportFeaturedPreviewPage from '../pages/PassportFeaturedPreviewPage'
import ManageProfileHomePage from '../pages/ManageProfileHomePage'
import ProfileEditPage from '../pages/ProfileEditPage'
import ManageSocialHomePage from '../pages/ManageSocialHomePage'
import MissingPage from '../pages/404Page'
import { useFetchLoggedIn } from '../helpers/apiFetchHooks'
import ManagePrizePage from '../pages/ManagePrizePage'
import PrizeListPage from '../pages/PrizeListPage'
import ManagePassportPrizePage from '../pages/ManagePassportPrizePage'
import PassportExplorePrizePage from '../pages/PassportExplorePrizePage'
import ManageAdminHomePage from '../pages/ManageAdminHomePage'


const Routes = () => {
  const { loggedInData: permission, fetched: fetchedPermission } = useFetchLoggedIn('permissions')
  PrivateRoute({})

  return (
    <Switch>
      <Route path="/profile">
        <ProfilePage />
      </Route>
      <Route path="/login-page">
        <LoginPage />
      </Route>
      <Route path="/password-reset/:id">
        <PasswordResetLinkPage />
      </Route>
      <Route path="/password-reset">
        <PasswordResetPage />
      </Route>
      <Route path="/qr/:passphrase">
        <PublicQrCodePage />
      </Route>
      <Route path="/qr">
        <PublicQrCodePage />
      </Route>
      <Route exact path="/">
        <LandingPage />
      </Route>
      {/* END PUBLIC ROUTES - START ADMIN ROUTES */}
      {fetchedPermission && permission.is_superuser && (
        <PrivateRoute path="/admin-home">
          <ManageAdminHomePage />
        </PrivateRoute>)}
      {fetchedPermission && permission.is_superuser && (
        <PrivateRoute path="/destination/:id">
          <ManageDestinationPage />
        </PrivateRoute>)}
      {fetchedPermission && permission.is_superuser && (
        <PrivateRoute path="/destination">
          <ManageDestinationPage />
        </PrivateRoute>)}
      {fetchedPermission && permission.is_superuser && (
        <PrivateRoute path="/destination-list">
          <DestinationListPage />
        </PrivateRoute>)}
      {fetchedPermission && permission.is_superuser && (
        <PrivateRoute path="/passport-create">
          <ManagePassportPage />
        </PrivateRoute>)}
      {fetchedPermission && permission.is_superuser && (
        <PrivateRoute path="/passport/:id">
          <ManagePassportPage />
        </PrivateRoute>)}
      {fetchedPermission && permission.is_superuser && (
        <PrivateRoute path="/passport-list">
          <PassportListPage />
        </PrivateRoute>)}
      {fetchedPermission && permission.is_superuser && (
        <PrivateRoute path="/prize/:id">
          <ManagePrizePage />
        </PrivateRoute>)}
      {fetchedPermission && permission.is_superuser && (
        <PrivateRoute path="/passport-prize/:id">
          <ManagePassportPrizePage />
        </PrivateRoute>)}
      {fetchedPermission && permission.is_superuser && (
        <PrivateRoute path="/prize">
          <ManagePrizePage />
        </PrivateRoute>)}
      {fetchedPermission && permission.is_superuser && (
        <PrivateRoute path="/prize-list">
          <PrizeListPage />
        </PrivateRoute>)}
      {/* END ADMIN ROUTES - START USER LOGGED IN ROUTES */}
      <PrivateRoute path="/destination-explore/:passportId/:destinationId">
        <ManageDestinationExplore />
      </PrivateRoute>
      <PrivateRoute path="/destination-explore-review/:passportId/:destinationId">
        <DestinationExploreReviewPage />
      </PrivateRoute>
      <PrivateRoute path="/destination-explore-stamp/:passportId/:destinationId">
        <DestinationExploreStampPage />
      </PrivateRoute>
      <PrivateRoute path="/destination-view/:id">
        <ViewDestinationPage />
      </PrivateRoute>
      <PrivateRoute path="/destination-review-list/:id">
        <ViewDestinationReviewPage />
      </PrivateRoute>
      <PrivateRoute path="/passport-explore/:id">
        <ManagePassportExplore />
      </PrivateRoute>
      <PrivateRoute path="/passport-explore-leaderboard/:id">
        <PassportExploreLeaderboardPage />
      </PrivateRoute>
      <PrivateRoute path="/passport-explore-leaderboard-detail/:id/:leaderId">
        <PassportExploreLeaderboardDetailPage />
      </PrivateRoute>
      <PrivateRoute path="/passport-explore-map/:id">
        <PassportExploreMapPage />
      </PrivateRoute>
      <PrivateRoute path="/passport-explore-prize/:id">
        <PassportExplorePrizePage />
      </PrivateRoute>
      <PrivateRoute path="/passport-home">
        <ManagePassportHomePage />
      </PrivateRoute>
      <PrivateRoute path="/passport-gallery">
        <ManagePassportGalleryPage />
      </PrivateRoute>
      <PrivateRoute path="/passport-view/:id">
        <ViewPassportPage />
      </PrivateRoute>
      <PrivateRoute path="/passport-featured/:id">
        <PassportFeaturedPreviewPage />
      </PrivateRoute>
      <PrivateRoute path="/passport-wallet">
        <ManagePassportHomePage />
      </PrivateRoute>
      <PrivateRoute path="/profile-home">
        <ManageProfileHomePage />
      </PrivateRoute>
      <PrivateRoute path="/profile-edit">
        <ProfileEditPage />
      </PrivateRoute>
      <PrivateRoute path="/social-home">
        <ManageSocialHomePage />
      </PrivateRoute>
      <PrivateRoute exact path="/">
        <ManagePassportHomePage />
      </PrivateRoute>
      <Route>
        <MissingPage />
      </Route>
    </Switch>
  )
}

export default Routes
