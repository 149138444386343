import CrudListItem from '../components/CrudListItem'
import Grid from '@material-ui/core/Grid'
import { Button, Typography } from '@material-ui/core'
import List from '@material-ui/core/List'
import React, { useState } from 'react'
import PaperContainer from './PaperContainer'
import Switch from '@material-ui/core/Switch'
import axios from 'axios'
import RedeemIcon from '@material-ui/icons/Redeem'
import IconTextButton from '../components/IconTextButton'
import { PassportExplorePrizeContainer } from '../pages/PassportExplorePrizePage'
import { imgUrl } from '../helpers/constants'

const ViewPassportContainer = props => {
  const {
    collapsed,
    history,
    passport: {
      id,
      name,
      image,
      description,
      destinations,
      is_active,
      prize_levels,
    },
  } = props
  const imgSrc = image ? `${imgUrl}passports/${image}` : false
  const [isInWallet, setIsInWallet] = useState(is_active)
  const [showDestinations, setShowDestinations] = useState(!collapsed)
  const [showPrize, setShowPrize] = useState(false)

  const handleAddPassport = () => {
    const data = {
      passport: id,
    }
    axios.put('/api/passport_gallery/', data)
      .then(() => {
        setIsInWallet(true)
      }).catch()
  }

  const handleRemove = () => {
    axios.delete(`/api/passport_gallery/${id}/`)
      .then(() => {
        setIsInWallet(false)
      })
      .catch()
  }

  const handleClick = () => {
    if (isInWallet) {
      handleRemove()
    } else {
      handleAddPassport()
    }
  }
  const imgDir = `${imgUrl}destinations`
  const destinationItems = destinations.map(dest => (
    <CrudListItem
      avatarVariant="circle"
      itemType="destination"
      imgDirectory={imgDir}
      key={dest.id}
      {...dest}
      readOnly={true}
      isEditList={false}
      viewLink={true}
    />))

  const buttonText = isInWallet ? 'Remove from wallet' : 'Add to wallet'


  return (
    <PaperContainer imgSrc={imgSrc} history={history} name={name}>
      {!collapsed && (
        <Grid container justify="center">
          <Grid item sm={12} xs={12} style={{ padding: 10 }}>
            <Button
              variant="outlined"
              color="primary"
              style={{ width: 250, marginRight: 10 }}
              onClick={handleClick}
            >
              {buttonText}
              <Switch
                checked={isInWallet}
                name="checkedB"
                color="primary"
              />
            </Button>
            {prize_levels.length > 0 && (
              <IconTextButton
                onClick={() => setShowPrize(!showPrize)}
                label={showPrize ? 'Hide Prizes' : 'Show Prizes'}
                lineHeight={2}
              >
                <RedeemIcon
                  fontSize="large"
                  style={{ textAlign: 'center', vAlign: 'text' }}
                />
              </IconTextButton>)}
          </Grid>
          {isInWallet && (
            <Grid item sm={10} xs={10} style={{ padding: 10 }}>
              <Button variant="contained" color="primary" onClick={() => history.push(`/passport-explore/${id}/`)}>
                Go to Wallet
              </Button>
            </Grid>
          )}
        </Grid>)}
      <Grid item sm={12}>
        <Typography variant="subtitle1" style={{ marginBottom: 10, marginTop: 10 }}>{description}</Typography>
      </Grid>
      {collapsed && (
        <>
          <Grid item sm={12}>
            <Typography variant="h6" style={{ marginBottom: 10, marginTop: 10 }}>Set Prizes</Typography>
          </Grid>
          <Grid item sm={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setShowDestinations(!showDestinations)}
            >
              {showDestinations ? 'Hide' : 'Show'} {destinations.length.toString()} Destinations
            </Button>
          </Grid>
        </>
      )}
      {showDestinations && !showPrize && (
        <List>
          {destinationItems}
        </List>)}
      {showPrize && (
        <PassportExplorePrizeContainer
          prize_levels={prize_levels}
          stamps={[]}
          viewOnly={true}
          profile={{}}
        />)}
    </PaperContainer>
  )
}

export default ViewPassportContainer
