import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import ReactGA from 'react-ga'
import useLogin from '../store/useLogin'


const usePageTracking = () => {
  let location = useLocation()
  const [initialized, setInitialized] = useState(false)
  const { profileId } = useLogin()

  useEffect(() => {
    if (!window.location.href.includes('localhost')) {
      ReactGA.initialize('UA-185451591-1')
      ReactGA.set({
        userId: profileId || 'not-logged-in',
      })
      setInitialized(true)
    }
  }, [profileId])

  useEffect(() => {
    if (initialized) {
      ReactGA.pageview(location.pathname + location.search)
    }
  }, [initialized, location])
}

export default usePageTracking
