import { useContext } from 'react'
import { store } from './Store'

const useLogin = () => {
  const {
    state: {
      login: { drfCookie, profileId, username },
    },
  } = useContext(store)
  return { drfCookie, profileId, username }
}

export default useLogin
