import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Chip, Typography } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import AccessibleForwardIcon from '@material-ui/icons/AccessibleForward'
import RestaurantOutlinedIcon from '@material-ui/icons/RestaurantOutlined'
import LocalBarIcon from '@material-ui/icons/LocalBar'
import ChildFriendlyIcon from '@material-ui/icons/ChildFriendly'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSkiingNordic, faHiking, faWater, faHorse } from '@fortawesome/free-solid-svg-icons'


const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: theme.typography.pxToRem(18),
    margin: theme.spacing(0.5),
    height: 40,
  },
}))

const DestinationDetails = props => {
  const { destination } = props
  const {
    description,
    address,
    city,
    state,
    zip,
    difficulty,
    destination_types,
    accessibility_features,
    time_to_complete_hours,
    time_to_complete_minutes,
    distance,
    distance_unit,
    elevation,
    elevation_unit,
  } = destination
  const classes = useStyles()

  const getIcon = keyword => {
    switch (keyword) {
      case 'restaurant': {
        return <RestaurantOutlinedIcon />
      }
      case 'bar': {
        return <LocalBarIcon />
      }
      case 'cross country skiing': {
        return <FontAwesomeIcon icon={faSkiingNordic} />
      }
      case 'hiking': {
        return <FontAwesomeIcon icon={faHiking} />
      }
      case 'paddling': {
        return <FontAwesomeIcon icon={faWater} />
      }
      case 'horseback': {
        return <FontAwesomeIcon icon={faHorse} />
      }
      case 'wheelchair accessible': {
        return <AccessibleForwardIcon />
      }
      case 'stroller friendly': {
        return <ChildFriendlyIcon />
      }
      default:
        return null
    }
  }

  const getDestinationChip = chipData => {
    return chipData.map(chip => {
      const icon = getIcon(chip.name)
      return (
        <li key={chip.id} style={{ listStyle: 'none', padding: 5 }}>
          <Chip
            className={classes.root}
            icon={icon}
            label={chip.name}
          />
        </li>
      )
    })
  }

  return (
    <>
      <Grid item sm={12}>
        <Typography variant="h5">{address}</Typography>
      </Grid>
      <Grid item sm={12}>
        <Typography variant="h5">{city}, {state} {zip}</Typography>
        <i className="fas fa-skiing-nordic" />
      </Grid>
      <Grid item sm={12}>
        {destination_types.length > 0 && (
          <Grid container justify="center">
            {getDestinationChip(destination_types)}
          </Grid>
        )}
        {difficulty && (
          <Grid container justify="flex-start">
            <Typography variant="h6">Difficulty: {difficulty.name}</Typography>
          </Grid>)}
        {(time_to_complete_hours || time_to_complete_minutes) && (
          <Grid container justify="flex-start">
            <Typography variant="h6">Time to
              complete: {time_to_complete_hours || 0} hours {time_to_complete_minutes || 0} minutes</Typography>
          </Grid>
        )}
        {distance && (
          <Grid container justify="flex-start">
            <Typography variant="h6">Distance: {distance} {distance_unit.name}</Typography>
          </Grid>
        )}
        {elevation && (
          <Grid container justify="flex-start">
            <Typography variant="h6">Elevation: {elevation} {elevation_unit.name}</Typography>
          </Grid>
        )}
        {accessibility_features.length > 0 && (
          <Grid container justify="center">
            {getDestinationChip(accessibility_features)}
          </Grid>
        )}
      </Grid>
      <Grid item sm={12}>
        <Typography
          variant="subtitle1"
          style={{ marginBottom: 10, marginTop: 10 }}>
          {description}
        </Typography>
      </Grid>
    </>
  )
}

export default DestinationDetails
