import React, { useContext, useState } from 'react'
import { Container, Typography } from '@material-ui/core'
import SwitchItem from '../components/SwitchItem'
import List from '@material-ui/core/List'
import axios from 'axios'
import { store } from '../store/Store'
import { truncate } from '../helpers/utils'

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'


const PassportGalleryPage = props => {
  const { passports, handleSave, handleRemove } = props
  const { dispatch } = useContext(store)
  const [activePassports, setActivePassports] = useState(
    passports
      .filter(passport => passport.is_active === true)
      .map(passport => passport.id),
  )

  const isChecked = (id) => activePassports.indexOf(id) > -1

  const handleChange = (id, event) => {
    const passportId = event.target.name
    if (event.target.checked) {
      const newActivePassports = [...activePassports, passportId]
      setActivePassports(newActivePassports)
      handleSave(passportId)
    } else {
      const idx = activePassports.indexOf(passportId)
      const newActivePassports = [...activePassports]
      newActivePassports.splice(idx, 1)
      setActivePassports(newActivePassports)
      handleRemove(passportId)
      dispatch({ type: 'resetActivePassport' })
    }
  }

  const passportItems = passports.map(passport => (
    <SwitchItem
      key={passport.id}
      id={passport.id}
      itemType="passport"
      name={truncate(passport.name, 35)}
      description={truncate(passport.description, 40)}
      ariaLabel={`select passport ${passport.name}`}
      isChecked={isChecked}
      handleChange={handleChange}
    />
  ))

  return (
    <Container maxWidth={'sm'} style={{ marginTop: 50, marginBottom: 20 }}>
      <Typography variant="h4">
        Select Your Passports
      </Typography>
      <List>
        {passportItems}
      </List>
    </Container>
  )
}


const ManagePassportGalleryPage = props => {
  const { passports, setPassports, setActiveStep } = props

  const handleSave = passportId => {
    const data = {
      passport: passportId,
    }
    axios.put('/api/passport_gallery/', data)
      .then(res => {
        setActiveStep(0)
        setPassports(res.data)
      })
      .catch()
  }

  const handleRemove = passportId => {
    axios.delete(`/api/passport_gallery/${passportId}/`)
      .then(res => {
        setActiveStep(0)
        setPassports(res.data)
      })
      .catch()
  }

  return (
    <PassportGalleryPage
      passports={passports}
      handleSave={handleSave}
      handleRemove={handleRemove}
    />
  )
}

export default ManagePassportGalleryPage
