import { isEmpty } from 'lodash'
import CrudListItem from '../components/CrudListItem'
import List from '@material-ui/core/List'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import Grid from '@material-ui/core/Grid'
import { imgUrl } from '../helpers/constants'

const VerifyPassportContainer = props => {
  const { alldestinations, destinations, name, description, imageFile } = props
  const imageUrl = isEmpty(imageFile) ? null : URL.createObjectURL(imageFile)

  const destinationItems = alldestinations
    .filter(dest => destinations.indexOf(dest.id) > -1)
    .map(dest => <CrudListItem avatarVariant="circle" itemType="destination" imgDirectory={`${imgUrl}destinations`}
                               key={dest.id} {...dest} readOnly={true}/>)

  return (
    <>
      {imageUrl && <img src={imageUrl} alt="Passport Cover" width="75%"/>}
      <Grid container direction="row" alignItems="center" justify="center">
        <Grid item sm={6} xs={12} style={{ textAlign: 'center'}}>
            <Typography variant="h6">Name:</Typography>
            <Typography variant="subtitle1">{name}</Typography>
        </Grid>
        <Grid item sm={6} xs={12} style={{ textAlign: 'center'}}>
            <Typography variant="h6">Description:</Typography>
            <Typography variant="subtitle1">{description}</Typography>
        </Grid>
      </Grid>
      <Typography variant="h6">Destinations</Typography>
      <List>
        {destinationItems}
      </List>
    </>
  )
}

export default VerifyPassportContainer