import React, { createContext, useEffect, useReducer } from 'react'

const initialState = {
  login: {
    drfCookie: false,
    profileId: null,
    username: null,
  },
  leaderboard: [],
  activePassport: {},
}

const localState = JSON.parse(localStorage.getItem('myState'))

const store = createContext(localState || initialState)
const { Provider } = store


const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case 'login':
        return { ...state, login: { ...action.payload } }
      case 'logout':
        return { ...state, login: { drfCookie: false, profileId: null } }
      case 'leaderboard':
        return { ...state, leaderboard: [...action.payload] }
      case 'activePassport':
        return { ...state, activePassport: { ...action.payload } }
      case 'resetActivePassport':
        return { ...state, activePassport: {} }
      default:
        throw new Error()
    }
  }, localState || initialState)

  useEffect(() => {
    localStorage.setItem('myState', JSON.stringify(state))
  }, [state])

  return <Provider value={{ state, dispatch }}>{children}</Provider>
}

export { store, StateProvider }
