import React, { useState } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Button } from '@material-ui/core'
import Modal from '@material-ui/core/Modal'


function getModalStyle() {
  const top = 60
  const left = 60

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    textAlign: 'center',
    width: 300,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}))


const MotherMayIModal = props => {
  const {
    id,
    title,
    description,
    confirmButtonText,
    handleConfirmClick,
    cancelButtonText,
    handleCancelClick,
    openModal,
  } = props
  const classes = useStyles()
  const [modalStyle] = useState(getModalStyle)

  const handleClick = () => {
    handleConfirmClick(id)
  }

  return (
    <Modal
      open={openModal}
      onClose={handleCancelClick}
      aria-labelledby="delete-modal-title"
      aria-describedby="delete-modal-description"
    >
      <div style={modalStyle} className={classes.paper}>
        <h2 id="simple-modal-title">{title}</h2>
        <p id="simple-modal-description">
          {description}
        </p>
        <Button variant="contained" color="secondary" onClick={() => handleClick()}>
          {confirmButtonText}
        </Button>
        <Button variant="contained" color="primary" onClick={handleCancelClick} style={{ marginLeft: 10 }}>
          {cancelButtonText}
        </Button>
      </div>
    </Modal>
  )
}

export default MotherMayIModal