import React from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import { normalizePhoneNumber } from '../helpers/utils'


const EmailForm = props => {
  const {
    handleSubmit,
    values,
    handleChange,
    setFieldTouched,
    errors,
    touched,
    isValid,
    dirty,
  } = props

  const change = (name, e) => {
    e.persist()
    handleChange(e)
    setFieldTouched(name, true, false)
  }

  const disableSubmit = !(isValid && dirty)
  return (
    <form style={{ width: '100%' }}>
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="name"
        label="Name"
        name="name"
        value={values.name}
        onChange={change.bind(null, 'name')}
        helperText={touched.name ? errors.name : ''}
        error={touched.name && Boolean(errors.name)}
      />
      <TextField
        variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          value={values.email}
          onChange={change.bind(null, 'email')}
          helperText={touched.email ? errors.email : ''}
          error={touched.email && Boolean(errors.email)}
        InputProps={{
          endAdornment: <InputAdornment position="end">
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              type="submit"
              disabled={disableSubmit}
            >
              Add
            </Button>
          </InputAdornment>,
        }}
      />
    </form>
  )
}

export default EmailForm
