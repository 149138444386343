import { Link } from 'react-router-dom'
import { Typography } from '@material-ui/core'
import React from 'react'

const ReviewsLink = props => {
  const { rating, destinationId } = props

  const reviewPlural = rating.number_of_reviews > 1 ? 'reviews' : 'review'

  return (
    <Link to={`/destination-review-list/${destinationId}`}>
      <Typography variant="h6" style={{ marginLeft: 10 }}>
        {`${rating.number_of_reviews} ${reviewPlural}`}
      </Typography>
    </Link>
  )
}

export default ReviewsLink
