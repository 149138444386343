import { Link, useHistory, useParams } from 'react-router-dom'
import React from 'react'
import useLeaderboard from '../store/useLeaderboard'
import { Typography } from '@material-ui/core'
import PaperContainer from '../containters/PaperContainer'
import Grid from '@material-ui/core/Grid'
import ProgressBar from '../components/ProgressBar'
import { formatDate } from '../helpers/utils'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Avatar from '@material-ui/core/Avatar'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemText from '@material-ui/core/ListItemText'
import Badge from '@material-ui/core/Badge'
import { CheckmarkAvatar } from '../components/CheckmarkAvatar'
import useActivePassport from '../store/useActivePassport'
import { imgUrl } from '../helpers/constants'

const leaderStampItem = (leaderStamp, passport) => {
  const imgSrc = leaderStamp.destination_image ? `${imgUrl}destinations/${leaderStamp.destination_image}` : null
  return (
    <ListItem key={leaderStamp.stamp_id}>
      <ListItemAvatar>
          <Badge
            overlap="circle"
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            badgeContent={<CheckmarkAvatar alt={leaderStamp.destination_name} />}
          >
            <Avatar src={imgSrc} alt={leaderStamp.destination_name} />
          </Badge>
        </ListItemAvatar>
      <Link to={`/destination-explore/${leaderStamp.passport_id}/${leaderStamp.destination_id}`}>
        <ListItemText primary={leaderStamp.destination_name} secondary={formatDate(leaderStamp.stamp_created)} />
      </Link>
    </ListItem>
  )
}

const PassportExploreLeaderboardDetailPage = () => {
  const { id, leaderId } = useParams()
  const history = useHistory()
  const leaderboard = useLeaderboard(id, leaderId)
  const activePassport = useActivePassport(id)

  if (!leaderboard) {
    return (
      <>
        <h2>Loading</h2>
      </>
    )
  } else {
    const firstStamp = leaderboard[0]
    const imgSrc = firstStamp.passport_image ? `${imgUrl}passports/${firstStamp.passport_image}` : null
    const leaderName = `${firstStamp.first_name} ${firstStamp.last_name}`
    const stampCount = leaderboard.length
    const totalStampCount = activePassport.destinations.length
    const progressBar = ProgressBar(stampCount, totalStampCount, 0)
    const leaderStampItems = leaderboard.map(leader => (leaderStampItem(leader, activePassport)))

    return (
      <PaperContainer
        history={history}
        imgSrc={imgSrc}
        name={firstStamp.passport_name}
      >
        <Typography variant="h5" style={{ marginTop: 10 }}>{leaderName}'s Progress</Typography>
        <Grid container justify="space-around" direction="row" alignItems="center" style={{ marginTop: 5 }}>
          <Grid item sm={6} xs={6}>
            <Typography variant="h5">{stampCount}</Typography>
            <Typography variant="subtitle1">{stampCount === 1 ? `Stamp` : `Stamps`} Earned</Typography>
          </Grid>
          <Grid item sm={6} xs={6}>
            <Typography variant="h5">{totalStampCount}</Typography>
            <Typography variant="subtitle1">Total Stamps</Typography>
          </Grid>
        </Grid>
        <Grid item sm={12}>
          {progressBar}
        </Grid>
        <List>
          {leaderStampItems}
        </List>
      </PaperContainer>
    )
  }
}

export default PassportExploreLeaderboardDetailPage
