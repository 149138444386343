import React, { useState } from 'react'
import Container from '@material-ui/core/Container'
import { Typography } from '@material-ui/core'
import OneActionListItem from '../components/OneActionListItem'
import axios from 'axios'
import List from '@material-ui/core/List'
import SnackbarAlert from '../components/SnackbarAlert'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import { get } from 'lodash'
import FriendEmailInviteContainer from '../containters/FriendEmailInviteContainer'
import FriendPhoneInviteContainer from '../containters/FriendPhoneInviteContainer'


const ManageAddFriendPage = () => {
  const [phoneInvites, setPhoneInvites] = useState([])
  const [emailInvites, setEmailInvites] = useState([])
  const [openModal, setOpenModal] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [showEmail, setShowEmail] = useState(false)

  const buttonText = showEmail ? 'Use Phone' : 'Use Email'
  const hasInvites = phoneInvites.length > 0 || emailInvites.length > 0

  const deleteInvite = id => {
    return axios.delete(`/api/friend_invite/${id}/`)
  }

  const handleDelete = (idx, keyName) => {
    const isPhone = keyName === 'phone'
    const invites = isPhone ? phoneInvites : emailInvites
    const setInvites = isPhone ? setPhoneInvites : setEmailInvites
    const inviteId = invites[idx].id

    deleteInvite(inviteId)
      .then(() => {
        const newInvites = [...invites]
        newInvites.splice(idx, 1)
        setInvites([...newInvites])
      }).catch(err => {
      console.log(err)
    })
  }

  const getInviteItems = invites => {
    if (!invites) {
      return null
    }
    let keyName
    if (get(invites[0], 'phone')) {
      keyName = 'phone'
    } else if (get(invites[0], 'email')) {
      keyName = 'email'
    }
    return invites.map((invite, idx) => (
      <OneActionListItem
        key={idx}
        id={idx}
        primaryText={invite.name}
        secondaryText={invite[keyName]}
        handleAction={() => handleDelete(idx, keyName)}
        action="delete"
        enableMobileShare
        mobileShareTitle="Join me on Vagabongo!"
        mobileShareText={`Come explore with me!`}
        mobileShareUrl="https://vagabongo.com/profile"
        mobileSharePersonName={invite.name}
        openModal={openModal}
        setOpenModal={setOpenModal}
        handleDelete={() => handleDelete(idx, keyName)}
        mobileModalHeader={`Share Vagabongo with ${invite.name}`}
      />
    ))
  }

  return (
    <Container component="main" maxWidth="sm">
      <Typography variant="h5" style={{ marginTop: 75 }}>Add Friends</Typography>
      {showEmail ? (
        <FriendEmailInviteContainer
          emailInvites={emailInvites}
          setEmailInvites={setEmailInvites}
          setErrorMessage={setErrorMessage}
        />
      ) : (
        <FriendPhoneInviteContainer
          phoneInvites={phoneInvites}
          setPhoneInvites={setPhoneInvites}
          setErrorMessage={setErrorMessage}
        />)}
      <Grid container direction="row" justify="flex-start">
        <Button
          variant="outlined"
          onClick={() => setShowEmail(!showEmail)}
          color="primary"
        >
          {buttonText}
        </Button>
      </Grid>
      {hasInvites && <Typography variant="h6">Invites</Typography>}
      {phoneInvites.length > 0 && <List>{getInviteItems(phoneInvites)}</List>}
      {emailInvites.length > 0 && <List>{getInviteItems(emailInvites)}</List>}
      <SnackbarAlert
        showSnackbar={!!(errorMessage)}
        setShowSnackbar={() => setErrorMessage('')}
        severity="error"
        message={errorMessage}
        disableAutoHide
      />
    </Container>
  )
}

export default ManageAddFriendPage
