import Container from '@material-ui/core/Container'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import axios from 'axios'
import Button from '@material-ui/core/Button'
import { useHistory } from 'react-router-dom'
import { useFetchList } from '../helpers/apiFetchHooks'
import CrudListItem from '../components/CrudListItem'
import { imgUrl } from '../helpers/constants'

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'

const PassportListPage = () => {
  const { listData, fetched } = useFetchList('passport')
  const passports = listData
  let history = useHistory()
  const handleClick = () => {
    history.push('/passport-create')
  }

  if (!fetched || !passports) {
    return (
      <>
        <h2>Loading</h2>
      </>
    )
  } else {
    const passportItems = passports.map(passport => (
      <CrudListItem
        avatarVariant="rounded"
        itemType="passport"
        imgDirectory={`${imgUrl}passports`}
        key={passport.id}
        {...passport}
        isEditList
        viewLink
        isPrize
      />
    ))
    return (
      <Container component="main" maxWidth="md" style={{ marginTop: 50, marginBottom: 20 }}>
        {passports.length === 0 && fetched && (
          <>
            <Typography variant="h6">Gotta make some passports!</Typography>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleClick}
              style={{ maxWidth: 200, marginTop: 20 }}
            >
              Make Passports
            </Button>
          </>
        )}
        {passports.length > 0 && passportItems && (
          <div>
            <Typography variant="h6">Your Passports</Typography>
            <List>{passportItems}</List>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleClick}
              style={{ maxWidth: 200, marginTop: 20 }}
            >
              Make Passports
            </Button>
          </div>)}
      </Container>
    )
  }
}

export default PassportListPage
