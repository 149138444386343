import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { useHistory } from 'react-router-dom'
import { Button } from '@material-ui/core'
import useLogin from '../store/useLogin'
import { Link as ScrollLink } from 'react-scroll'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
}))

const NavigationBar = () => {
  const classes = useStyles()
  const history = useHistory()
  const { drfCookie } = useLogin()
  const currentUrl = window.location.href
  const urlParts = currentUrl.split('/')
  const isHomePage = urlParts[3] === ''

  return (
    <div className={classes.root}>
      <AppBar className={classes.appBar} elevation={0}>
        <Toolbar>
          <Typography variant="h6" className={classes.title} onClick={() => history.push('/')}>
            Vagabongo
          </Typography>
          {!drfCookie && (
            <>
              {isHomePage && (<ScrollLink
                activeClass="buttonActive"
                to="demo"
                spy={true}
                offset={-75}
                duration={1000}
                smooth={true}
              >
                <Button variant="text" style={{ color: 'white' }}>How It Works</Button>
              </ScrollLink>)}
              {isHomePage && (<ScrollLink
                activeClass="buttonActive"
                to="contact"
                spy={true}
                offset={-25}
                duration={1000}
                smooth={true}
              >
                <Button variant="text" style={{ color: 'white' }}>Contact Us</Button>
              </ScrollLink>)}
              <Button href="login-page" variant="text" style={{ color: 'white' }}>Login</Button>
            </>
          )}
        </Toolbar>
      </AppBar>
    </div>
  )
}


export default NavigationBar
