import Container from '@material-ui/core/Container'
import { Typography } from '@material-ui/core'
import React from 'react'

const MissingPage = () => {

  return (
    <Container component="main" maxWidth="sm">
      <Typography variant="h4" style={{ marginTop: '10vh', marginBottom: '5vh' }}>
        Hmmm, that page does not appear to exist.
      </Typography>
    </Container>
  )
}

export default MissingPage
