import * as yup from 'yup'
import { Formik } from 'formik'
import PassportSettingsForm from '../forms/PassportSettingsForm'
import React from 'react'

const PassportSettingsFormContainer = props => {
  const { name, description, imageFile, dispatch, image, featured, disable_undo_stamp } = props
  const schema = yup.object({
    name: yup.string().required(),
    description: yup.string().required(),
    featured: yup.bool(),
    disable_undo_stamp: yup.bool(),
    image: yup.string(),
  })
  const initialFiles = [imageFile]
  return (
    <Formik
      validationSchema={schema}
      enableReinitialize={true}
      initialValues={{
        name: name,
        description: description,
        featured: featured,
        disable_undo_stamp: disable_undo_stamp,
        image: image,
      }}
      children={props => (
        <PassportSettingsForm
          {...props}
          dispatch={dispatch}
          initialFiles={initialFiles}
        />
      )}
    />
  )
}

export default PassportSettingsFormContainer