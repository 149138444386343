import React from 'react'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import { Link } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

const LoginForm = props => {
  const {
    handleSubmit,
    values,
    handleChange,
    setFieldTouched,
    errors,
    touched,
    isValid,
    dirty,
  } = props
  const change = (name, e) => {
    e.persist()
    handleChange(e)
    setFieldTouched(name, true, false)
  }
  const classes = useStyles()
  const disableSubmit = !(isValid && dirty)
  return (
    <>
      <Avatar className={classes.avatar}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Log in
      </Typography>
      <form className={classes.form}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="username"
          autoFocus
          value={values.email}
          onChange={change.bind(null, 'email')}
          helperText={touched.email ? errors.email : ''}
          error={touched.email && Boolean(errors.email)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="password"
          value={values.password}
          onChange={change.bind(null, 'password')}
          helperText={touched.password ? errors.password : ''}
          error={touched.password && Boolean(errors.password)}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={handleSubmit}
          disabled={disableSubmit}
        >
          Sign In
        </Button>
        <Grid container>
          <Grid item xs>
            <Link to="/password-reset">
            <Typography variant="body2" align="left">
              Forgot password?
            </Typography>
            </Link>
          </Grid>
          <Grid item>
            <Link to="/profile">
              <Typography variant="body2" align="right">Need an account? Sign Up</Typography>
            </Link>
          </Grid>
        </Grid>
      </form>
    </>
  )
}

export default LoginForm
