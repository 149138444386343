import Grid from '@material-ui/core/Grid'
import { Button, Typography } from '@material-ui/core'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import Paper from '@material-ui/core/Paper'
import Container from '@material-ui/core/Container'
import React from 'react'


const PaperContainer = (props) => {
  const {
    children,
    history,
    imgSrc,
    name,
    subHeader
  } = props

  return (
    <Container component="main" maxWidth="sm" style={{ marginTop: 20, marginBottom: 20 }}>
      <Grid container justify="center" style={{ padding: 10, maxWidth: '90wh' }}>
        <Grid container justify="flex-start">
          <Button
            onClick={() => history.goBack()}
            startIcon={<ChevronLeftIcon />}
          >
            Back
          </Button>
        </Grid>
        <Paper elevation={4} style={{ padding: 10, width: '100%' }}>
          <Typography variant="h4">{name}</Typography>
          {subHeader}
          {imgSrc && (
            <Grid item style={{ maxWidth: '90wh', maxHeight: '90vh' }}>
              <img src={imgSrc} style={{ maxWidth: '100%', maxHeight: '90vh' }} alt={name} />
            </Grid>
          )}
          {children}
        </Paper>
      </Grid>
    </Container>
  )
}
export default PaperContainer
