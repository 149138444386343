import ListItem from '@material-ui/core/ListItem'
import { Link, useHistory } from 'react-router-dom'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Switch from '@material-ui/core/Switch'
import React from 'react'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import GroupAddIcon from '@material-ui/icons/GroupAdd'


const SwitchItem = props => {
  const {
    id,
    itemType,
    name,
    description,
    ariaLabel,
    isChecked,
    handleChange,
    enableHidden,
    handleHideClick,
    isEdit,
    editItemType,
    iconType,
  } = props
  const history = useHistory()
  const editEndpoint = editItemType ? editItemType : itemType
  const ActionIcon = () => {
    if (iconType && iconType === 'share') {
      return (
        <GroupAddIcon />
      )
    } else {
      return (
        <EditIcon />
      )
    }
  }

  const change = (id, e) => {
    handleChange(id, e)
  }

  return (
    <ListItem key={id}>
      <Link to={`/${itemType}-view/${id}`}>
        <ListItemText primary={name} secondary={description}/>
      </Link>
      <ListItemSecondaryAction>
        <Switch
          checked={isChecked(id)}
          onChange={change.bind(null, id)}
          color="primary"
          name={id}
          inputProps={{ 'aria-label': `${ariaLabel}` }}
        />
      </ListItemSecondaryAction>
      {enableHidden && !isChecked(id) && (<ListItemSecondaryAction style={{ marginRight: 70 }}>
        <IconButton edge="end" aria-label="hide item" onClick={() => handleHideClick(id)}>
          <VisibilityOffIcon/>
        </IconButton>
      </ListItemSecondaryAction>)}
      {isEdit && isChecked(id) && (<ListItemSecondaryAction style={{ marginRight: 70 }}>
        <IconButton edge="end" aria-label="hide item" onClick={() => history.push(`/${editEndpoint}/${id}`)}>
          <ActionIcon />
        </IconButton>
      </ListItemSecondaryAction>)}
    </ListItem>
  )
}

export default SwitchItem
