import React, { useState } from 'react'
import { Formik } from 'formik'
import axios from 'axios'
import * as yup from 'yup'
import ProfileEditForm from '../forms/ProfileEditForm'
import SnackbarAlert from '../components/SnackbarAlert'
import Typography from '@material-ui/core/Typography'
import { formatPhoneNumber } from '../helpers/utils'
import ProfileAvatar from '../components/ProfileAvatar'
import Grid from '@material-ui/core/Grid'

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'

const schema = yup.object({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup.string().required().email(),
})


const ProfileEditPage = props => {
  const { profile: profileData, setShowEdit, setProfile, setShowSuccess } = props
  const [phoneValue, setPhoneValue] = useState(formatPhoneNumber(profileData.phone) || '')
  const [imageFile, setImageFile] = useState()
  const [saving, setSaving] = useState(false)

  const submit = values => {
    setSaving(true)
    let form_data = new FormData()
    form_data.append('first_name', values.firstName)
    form_data.append('last_name', values.lastName)
    form_data.append('email', values.email)
    form_data.append('phone', phoneValue)
    form_data.append('address', values.address)
    form_data.append('city', values.city)
    form_data.append('state', values.state)
    form_data.append('zip', values.zip)
    if (imageFile) {
      form_data.append('image_file', imageFile, imageFile.name)
    }

    axios.put('/api/profile/1/', form_data, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    }).then(res => {
      setSaving(false)
      setProfile(res.data)
      setShowEdit(false)
      setShowSuccess(true)
    }).catch(err => {
      if (err.response) {
        console.log(err.response)
        setSaving(false)
      }
    })
  }

  return (
    <>
      <Typography variant="h5" style={{ marginBottom: 20, marginTop: 75 }}>
        Edit Profile
      </Typography>
      <Formik
        validationSchema={schema}
        enableReinitialize={true}
        onSubmit={submit}
        initialValues={{
          firstName: profileData.first_name,
          lastName: profileData.last_name,
          email: profileData.email,
          address: profileData.address || '',
          city: profileData.city || '',
          state: profileData.state || '',
          zip: profileData.zip || '',
        }}
        children={props => (
          <ProfileEditForm
            {...props}
            setPhoneValue={setPhoneValue}
            phoneValue={phoneValue}
            setImageFile={setImageFile}
            imageFile={imageFile}
            image={profileData.image}
            setProfile={setProfile}
            saving={saving}
            setSaving={setSaving}
          />)}
      />
    </>
  )
}

export default ProfileEditPage
