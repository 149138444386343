import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Formik } from 'formik'
import axios from 'axios'
import * as yup from 'yup'
import ProfileForm from '../forms/ProfileForm'
import { store } from '../store/Store'
import { useCsrfToken } from '../helpers/GetCsrfToken'
import { get } from 'lodash'
import SnackbarAlert from '../components/SnackbarAlert'
import Typography from '@material-ui/core/Typography'
import { Container } from '@material-ui/core'

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'

const schema = yup.object({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup.string().required().email(),
  password: yup.string().required().min(8),
  passwordConfirmation: yup.string().required()
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
})

const submit = (values, actions, history, dispatch, setShowError, setErrorMessage, phoneValue, imageFile, setSaving) => {
  setSaving(true)
  let form_data = new FormData()
  const user = {
    username: values.email,
    password: values.password,
    password_confirmation: values.passwordConfirmation,
  }
  form_data.append('user', JSON.stringify(user))
  form_data.append('first_name', values.firstName)
  form_data.append('last_name', values.lastName)
  form_data.append('email', values.email)
  form_data.append('phone', phoneValue)
  form_data.append('address', values.address)
  form_data.append('city', values.city)
  form_data.append('state', values.state)
  form_data.append('zip', values.zip)
  if (imageFile) {
    form_data.append('image_file', imageFile, imageFile.name)
  }

  axios.post('/api/profile/', form_data, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  }).then(res => {
    if (res.status === 201) {
      axios.post('/api/login/', { email: user.username, password: user.password }).then(res => {
        const payload = { drfCookie: res.data.login, profileId: res.data.profile_id, username: user.username }
        dispatch({ type: 'login', payload })
        history.push('/passport-home')
      })
    }
  }).catch(err => {
    if (err.response) {
      if (get(err.response, 'data.user.username')) {
        setErrorMessage(`A user with email address ${values.email} already exists`)
        setShowError(true)
      } else if (get(err.response, 'data.phone_error')) {
        setErrorMessage(err.response.data.phone_error)
        setShowError(true)
      } else if (get(err.response, 'data')) {
        setErrorMessage(err.response.data[0])
        setShowError(true)
      } else {
        setErrorMessage('An error occurred. Please try again')
        setShowError(true)
      }
    } else {
      setErrorMessage('An error occurred. Please try again')
      setShowError(true)
    }
    setSaving(false)
  })
}

const ProfilePage = () => {
  const { dispatch } = useContext(store)
  const [showError, setShowError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [phoneValue, setPhoneValue] = useState('')
  const [imageFile, setImageFile] = useState()
  const [saving, setSaving] = useState(false)
  let history = useHistory()

  useCsrfToken()

  return (
    <Container maxWidth="xs" style={{ paddingBottom: 70 }}>
      <Typography variant="h5" style={{ marginBottom: 20, marginTop: 75 }}>
        Create Profile
      </Typography>
      <Formik
        validationSchema={schema}
        onSubmit={(values, actions) => submit(values, actions, history, dispatch, setShowError, setErrorMessage, phoneValue, imageFile, setSaving)}
        initialValues={{
          firstName: '',
          lastName: '',
          email: '',
          password: '',
          passwordConfirmation: '',
          address: '',
          city: '',
          state: '',
          zip: '',
        }}
        children={props => (
          <ProfileForm
            {...props}
            phoneValue={phoneValue}
            setPhoneValue={setPhoneValue}
            saving={saving}
            setImageFile={setImageFile}
            imageFile={imageFile}
          />)}
      />
      <SnackbarAlert
        showSnackbar={showError}
        setShowSnackbar={setShowError}
        severity="error"
        message={errorMessage}
      />
    </Container>
  )
}

export default ProfilePage
