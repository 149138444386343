import { useFetchList } from '../helpers/apiFetchHooks'
import { Button, CircularProgress, List, ListItem, Typography } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import { Autocomplete } from '@material-ui/lab'
import axios from 'axios'

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'

const PrizeFulfillmentItem = props => {
  const { prizeProfile, handleSubmit } = props
  const profile = prizeProfile.profile
  const prize = prizeProfile.prize_selected
  const passport = prizeProfile.passport
  const [status, setStatus] = useState()

  return (
    <ListItem>
      <Grid container justify="space-between" alignItems="flex-start">
        <Grid item md={4}>
          <Typography variant="h6">User Info:</Typography>
          <Typography variant="body1">{profile.first_name} {profile.last_name}</Typography>
          <Typography variant="body1">{profile.address}</Typography>
          <Typography variant="body1">{profile.city}, {profile.state} {profile.zip}</Typography>
          <Typography variant="body1">{profile.email}</Typography>
          <Typography variant="body1">{profile.phone}</Typography>
        </Grid>
        <Grid item md={4}>
          <Typography variant="h6">Prize Info:</Typography>
          {!prize ? (<Typography variant="body1">Prize not selected</Typography>) : (
            <>
              <Typography variant="body1">{prize.name}</Typography>
              <Typography variant="body1">{prize.description}</Typography>
              <Typography variant="body1">Passport: {passport.name}</Typography>
              <Typography variant="body1">Stamps: {prizeProfile.stamps}</Typography>
            </>)}
        </Grid>
        <Grid item md={4} style={{ textAlign: 'right' }}>
          <Typography variant="h6">Status: {prizeProfile.status}</Typography>
          <Autocomplete
            id="status"
            options={['pending confirmation', 'processing', 'shipped', 'delivered']}
            onChange={(_, value) => {
              setStatus(value)
            }}
            fullWidth
            getOptionLabel={option => option}
            value={prizeProfile.status}
            renderInput={(params) =>
              <TextField
                {...params}
                label="Updated Status"
                variant="outlined"
                margin="normal"
              />
            }
          />
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleSubmit(prizeProfile.id, status)}
            style={{ marginTop: 10 }}
          >
            Update Status
          </Button>
        </Grid>
      </Grid>
    </ListItem>
  )
}


const ManagePrizeFulfillmentPage = () => {
  const { listData: permission, fetched: fetchedPermission } = useFetchList('permissions')
  const {
    listData: prizeProfiles,
    fetched: fetchedPrizeProfiles,
    setListData: setPrizeProfiles,
  } = useFetchList('prize_profile')

  const handleSubmit = (prizeProfileId, status) => {
    axios.patch(`/api/prize_profile/${prizeProfileId}/`, { status: status })
      .then(res => {
        setPrizeProfiles(res.data)
      })
      .catch(err => console.log(err))
  }


  if (!fetchedPermission || !fetchedPrizeProfiles) {
    return (
      <CircularProgress />
    )
  } else if (fetchedPermission && permission.is_superuser) {
    const prizeItems = prizeProfiles.map((prizeProfile, idx) => (
      <PrizeFulfillmentItem key={idx} prizeProfile={prizeProfile} handleSubmit={handleSubmit} />
    ))

    return (
      <Container component="main" maxWidth="md">
        <List>
          {prizeItems}
        </List>
      </Container>
    )
  } else return null
}

export default ManagePrizeFulfillmentPage
