import Container from '@material-ui/core/Container'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import axios from 'axios'
import Button from '@material-ui/core/Button'
import { useHistory } from 'react-router-dom'
import { useFetchList } from '../helpers/apiFetchHooks'
import CrudListItem from '../components/CrudListItem'
import { CircularProgress } from '@material-ui/core'

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'

const PrizeListPage = () => {
  const { listData: prizes, fetched: fetchedPrizes } = useFetchList('prize')
  const history = useHistory()

  const handleClick = () => {
    history.push('/prize')
  }

  if (!fetchedPrizes) {
    return (
      <CircularProgress />
    )
  } else {
    const prizeItems = prizes.map(prize => {
      const prize_image = prize.photos.length > 0 ? prize.photos[0].name : null
      return (
        <CrudListItem
          avatarVariant="circle"
          itemType="prize"
          imgDirectory="img/prizes"
          key={prize.id}
          id={prize.id}
          name={prize.name}
          image={prize_image}
          description={prize.description}
          isEditList={true}
          viewLink={false}
        />
      )
    })
    return (
      <Container component="main" maxWidth="md" style={{ marginTop: 50, marginBottom: 20 }}>
        {prizes.length === 0 && fetchedPrizes && (
          <>
            <Typography variant="h6">Make some prizes!</Typography>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleClick}
              style={{ maxWidth: 200, marginTop: 20 }}
            >
              Make a Prize
            </Button>
          </>
        )}
        {prizes.length > 0 && prizeItems && (
          <div>
            <Typography variant="h6">Your Prizes</Typography>
            <List>{prizeItems}</List>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleClick}
              style={{ maxWidth: 200, marginTop: 20, marginBottom: 20 }}
            >
              Make New Prize
            </Button>
          </div>)}
      </Container>
    )
  }
}

export default PrizeListPage
