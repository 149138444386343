import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { Typography } from '@material-ui/core'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import List from '@material-ui/core/List'
import ListItemText from '@material-ui/core/ListItemText'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'
import Carousel from '../components/Carousel'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import Button from '@material-ui/core/Button'


import passportSettingsImg from '../imgs/passportSettings.png'
import passportStampMeImg from '../imgs/stampMe.png'
import takePhotoImg from '../imgs/takePhoto.png'

import postcardImg from '../imgs/postcard.jpg'
import leaderboardImg from '../imgs/leaderBoard.png'
import selfieImg from '../imgs/selfie.jpg'
import { Link as ScrollLink } from 'react-scroll'


const useStyles = makeStyles((theme) => ({
  containerFluid: {
    minHeight: '100vh',
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    width: '100%',
    [theme.breakpoints.down('md')]: {
      minHeight: '90vh',
    },
    [theme.breakpoints.down('sm')]: {
      minHeight: '60vh',
    },
  },
  gridHeader: {
    textAlign: 'center',
    width: '100%',
    height: '30%',
  },
  gridBody: {
    width: '100%',
  },
  heroButtonContain: {
    marginTop: theme.spacing(4),
    color: theme.palette.primary,
    backgroundColor: 'white',
    margin: 10,
  },
  gridItemBeans: {
    //backgroundImage: `url(${beans})`,
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.primary.main,
    backgroundPosition: 'right',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    position: 'relative',
  },
}))

const passportSlides = [
  { src: passportSettingsImg, alt: 'passport settings' },
  { src: passportStampMeImg, alt: 'passport stamp me' },
  { src: takePhotoImg, alt: 'passport take photo' },
]

const squadSlided = [
  { src: postcardImg, alt: 'holding postcard at site' },
  { src: leaderboardImg, alt: 'leaderboard' },
  { src: selfieImg, alt: 'people taking a group selfie' },
]

const DemoAppContainer = props => {
  const { history } = props
  const classes = useStyles()

  return (
    <>
      <div id="demo" className={classes.containerFluid}>
        <Grid
          container
          direction="row"
          spacing={0}
          justify="center"
          alignItems="center"
        >
          <Grid item xs={12} sm={6} md={6} style={{ textAlign: 'center' }}>
            <Typography variant="h4">You have an amazing business</Typography>
            <Typography variant="h5" style={{ marginTop: 10 }}>We'll help you keep bringing them back for
              more</Typography>
            <List>
              <ListItem>
                <ListItemIcon>
                  <FiberManualRecordIcon style={{ color: 'white' }} />
                </ListItemIcon>
                <ListItemText primary="Work with us to create a digital passport for your customers" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <FiberManualRecordIcon style={{ color: 'white' }} />
                </ListItemIcon>
                <ListItemText primary="Customers visit your locations and collect digital stamps" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <FiberManualRecordIcon style={{ color: 'white' }} />
                </ListItemIcon>
                <ListItemText
                  primary="Upload customer email lists to jump start your campaign. Automated emails keeps engagement high." />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <FiberManualRecordIcon style={{ color: 'white' }} />
                </ListItemIcon>
                <ListItemText primary="Social features turn your best customers into your best marketers" />
              </ListItem>
            </List>
            <ScrollLink
              activeClass="buttonActive"
              to="contact"
              spy={true}
              offset={-25}
              duration={1000}
              smooth={true}
            >
              <Button variant="contained" className={classes.heroButtonContain} size="large">
                Get in touch
              </Button>
            </ScrollLink>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            className={classes.gridItemBeans}
            style={{ marginBottom: '7vh' }}
          >
            <Box css={{ width: '95%', margin: 10 }}>
              <Paper elevation={8}>
                <Carousel slides={passportSlides} width={900} height={600} interval={3250} />
              </Paper>
            </Box>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row-reverse"
          spacing={0}
          justify="center"
          alignItems="center"
        >
          <Grid item xs={12} sm={6} md={6} style={{ textAlign: 'center' }}>
            <Typography variant="h4">Reward your customers (and yourself)!</Typography>
            <List>
              <ListItem>
                <ListItemIcon>
                  <FiberManualRecordIcon style={{ color: 'white' }} />
                </ListItemIcon>
                <ListItemText
                  primary="Work with us to create high-quality branded prizes, digital rewards, and contests" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <FiberManualRecordIcon style={{ color: 'white' }} />
                </ListItemIcon>
                <ListItemText
                  primary="Focus on delighting your customers and leave the logistics of prize fulfillment to us" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <FiberManualRecordIcon style={{ color: 'white' }} />
                </ListItemIcon>
                <ListItemText
                  primary="Flexible models to fit your needs - bulk order prizes to lock in low prices or on-demand to scale as your campaign grows" />
              </ListItem>
            </List>
            <ScrollLink
              activeClass="buttonActive"
              to="contact"
              spy={true}
              offset={-25}
              duration={1000}
              smooth={true}
            >
              <Button variant="contained" className={classes.heroButtonContain} size="large">
                Get Started
              </Button>
            </ScrollLink>
          </Grid>
          <Grid item xs={12} sm={6} md={6} className={classes.gridItemBeans}>
            <Box css={{ width: '95%', margin: 10 }}>
              <Paper elevation={8}>
                <Carousel slides={squadSlided} width={900} height={600} interval={2500} />
              </Paper>
            </Box>
          </Grid>
        </Grid>
      </div>
    </>
  )
}

export default DemoAppContainer
