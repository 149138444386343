import { useFetchList } from '../helpers/apiFetchHooks'
import { CircularProgress, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import useLogin from '../store/useLogin'
import ProfileAvatar from '../components/ProfileAvatar'
import DeleteIcon from '@material-ui/icons/Delete'
import IconTextButton from '../components/IconTextButton'
import axios from 'axios'
import MotherMayIModal from '../components/MotherMayIModal'
import { imgUrl } from '../helpers/constants'


const FriendshipItem = props => {
  const { friendship, friendships, setFriendships, index } = props
  const [openModal, setOpenModal] = useState(false)
  const { username } = useLogin()

  const handleOpenModal = () => setOpenModal(true)
  const handleCloseModal = () => setOpenModal(false)
  const myFriend = friendship.creator.email === username ? friendship.friend : friendship.creator

  const handleDeleteFriendship = friendId => {
    axios
      .delete(`/api/friendship/${friendId}/`)
      .then(() => {
        const newFriends = [...friendships]
        newFriends.splice(index, 1)
        setFriendships([...newFriends])
        handleCloseModal()
      })
      .catch(err => console.log(err))
  }

  return (
    <Grid container direction="row" justify="space-between" alignItems="center" style={{ width: '100%' }}>
      <Grid item sm={1} xs={1}>
        <ProfileAvatar
          firstName={myFriend.first_name}
          src={myFriend.image ? `${imgUrl}profiles/${myFriend.image}` : null}
          fontSize='1.75rem'
          height={45}
          width={45}
        />
      </Grid>
      <Grid item sm={8} xs={8}>
        <Typography variant="h6">{myFriend.first_name} {myFriend.last_name}</Typography>
      </Grid>
      <Grid item sm={1} xs={1} style={{ marginRight: 20 }}>
        <IconTextButton
          onClick={handleOpenModal}
          label="Delete"
          lineHeight={1.5}
        >
          <DeleteIcon fontSize="large" />
        </IconTextButton>
      </Grid>
      <MotherMayIModal
        id={friendship.id}
        title="Really Delete?"
        description={`This will permanently delete your friendship with ${myFriend.first_name} ${myFriend.last_name}.`}
        confirmButtonText="Delete"
        handleConfirmClick={handleDeleteFriendship}
        cancelButtonText="Cancel"
        openModal={openModal}
        handleCancelClick={handleCloseModal}
      />
    </Grid>
  )
}


const FriendshipContainer = props => {
  const { friendships, setFriendships } = props


  const getFriendshipItems = friends => {
    return friends.map((friendship, idx) => (
      <FriendshipItem
        key={idx}
        index={idx}
        friendship={friendship}
        friendships={friendships}
        setFriendships={setFriendships}
      />
    ))
  }


  return (
    <Container component="main" maxWidth="sm">
      {friendships.length > 0 && (
        <>
          <Typography variant="h5" style={{ marginTop: 20 }}>Friends</Typography>
          <Grid container direction="row" justify="space-between" alignItems="center" style={{ width: '100%' }}>
            {getFriendshipItems(friendships)}
          </Grid>
        </>
      )}
    </Container>
  )

}

const ManageFriendshipPage = () => {
  const {
    listData: friendships,
    fetched: fetchedFriendships,
    setListData: setFriendships,
  } = useFetchList('friendship')

  return (
    <>
      {!fetchedFriendships ? (
        <CircularProgress />
      ) : (
        <FriendshipContainer friendships={friendships} setFriendships={setFriendships} />
      )}
    </>
  )
}

export default ManageFriendshipPage
