import * as yup from 'yup'
import axios from 'axios'
import { useCsrfToken } from '../helpers/GetCsrfToken'
import Container from '@material-ui/core/Container'
import { Formik } from 'formik'
import SnackbarAlert from '../components/SnackbarAlert'
import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQrcode } from '@fortawesome/free-solid-svg-icons'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { get } from 'lodash'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import { print } from '../helpers/print'
import { useHistory, useParams } from 'react-router-dom'
import { imgUrl } from '../helpers/constants'

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'

const useStyles = makeStyles(theme => ({
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
}))

const PublicQrCodeForm = props => {
  const { handleSubmit, values, handleChange, setFieldTouched, errors, touched, isValid, dirty } = props
  const change = (name, e) => {
    e.persist()
    handleChange(e)
    setFieldTouched(name, true, false)
  }
  const classes = useStyles()

  const disableSubmit = !(isValid && dirty)
  return (
    <Grid container direction="column" alignItems="center" justify="center">
      <Avatar className={classes.avatar}>
        <FontAwesomeIcon icon={faQrcode} />
      </Avatar>
      <Typography component="h1" variant="h5">
        Get QR Code
      </Typography>
      <Typography variant="subtitle1">
        Enter the secret pass phrase to display the QR code for your location.
      </Typography>
      <form style={{ width: '100%' }}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          type="text"
          id="qr_pass_phrase"
          label="Pass Phrase"
          name="qr_pass_phrase"
          autoFocus
          value={values.qr_pass_phrase}
          onChange={change.bind(null, 'qr_pass_phrase')}
          helperText={touched.qr_pass_phrase ? errors.qr_pass_phrase : ''}
          error={touched.qr_pass_phrase && Boolean(errors.qr_pass_phrase)}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={disableSubmit}
        >
          Submit
        </Button>
      </form>
    </Grid>
  )
}

const QrCodeContainer = props => {
  const { destData, setDestData } = props

  const qrCodeSrc = `${imgUrl}qrcodes/${destData.qr_code_file_name}`

  const printQrCode = () => {
    let content = `
      <table style="font-family: Roboto, Helvetica, Arial, sans-serif; text-align: center; margin: auto;">
      <tr><td><h4 style="font-size: 2.725rem; font-weight: 400; line-height: 1.235; letter-spacing: 0.00735em; margin: 0;">Vagabongo</h4></td></tr>
      <tr><td><h5 style="font-size: 2.125rem; font-weight: 400; line-height: 1.235; letter-spacing: 0.00735em; margin: 0;">Scan to get your stamp!</h5></td></tr>
      <tr><td><img src=${qrCodeSrc} style="max-width: 100%" alt=${destData.name} /></td></tr>
      <tr><td><h4 style="font-size: 2.725rem; font-weight: 400; line-height: 1.235; letter-spacing: 0.00735em; margin: 0;">${destData.name}</h4></td></tr>
      <tr><td><h5 style="font-size: 2.125rem; font-weight: 400; line-height: 1.235; letter-spacing: 0.00735em; margin: 0;">${destData.address}</h5></td></tr>
      <tr><td><h5 style="font-size: 2.125rem; font-weight: 400; line-height: 1.235; letter-spacing: 0.00735em; margin: 0;">${destData.city} ${destData.state} ${destData.zip}</h5></td></tr>
      <tr><td><h5 style="font-size: 2.125rem; font-weight: 400; line-height: 1.235; letter-spacing: 0.00735em; margin-top: 40px;">Don't have a passport? Sign up at Vagabongo.com</h5></td></tr>
      </table>`

    print(content)
  }

  return (
    <Grid container justify="center">
      <Grid item xs={12} sm={12} style={{ marginTop: 20 }}>
        <Typography variant="h4">Scan to get your stamp!</Typography>
      </Grid>
      <Grid item xs={12} sm={12}>
        <img src={qrCodeSrc} style={{ maxWidth: '100%' }} alt={destData.name} />
      </Grid>
      <Grid item xs={12} sm={12}>
        <Typography variant="h4">{destData.name}</Typography>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Typography variant="h6">{destData.address}</Typography>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Typography variant="h6">{destData.city} {destData.state} {destData.zip}</Typography>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => printQrCode()}
          style={{ marginTop: 20, marginRight: 10 }}
        >
          Print
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => setDestData(null)}
          style={{ marginTop: 20, marginLeft: 10 }}
        >
          Close
        </Button>
      </Grid>
    </Grid>
  )
}

const schema = yup.object({
  qr_pass_phrase: yup.string().required(),
})

const PublicQrCodePage = () => {
  const { passphrase } = useParams()
  const [errorMsg, setErrorMsg] = useState('')
  const [destData, setDestData] = useState()
  const history = useHistory()

  const onSubmit = (values, {resetForm}) => {
    history.push(`/qr/${values.qr_pass_phrase}`)
    resetForm()
  }

  useEffect(() => {
    const getQrData = () => axios.post('/api/qr_pass_phrase/', {qr_pass_phrase: passphrase})
      .then(res => {
        setDestData(res.data)
      }).catch(err => {
      if (get(err, 'response.data.msg')) {
        setErrorMsg(err.response.data.msg)
      } else {
        setErrorMsg('Unknown error - try again')
      }
    })

    if (passphrase) {
      getQrData()
    }

  }, [passphrase])

  useCsrfToken()
  return (
    <Container component="main" maxWidth="sm" style={{ marginTop: 20, marginBottom: 20 }}>
      {destData ? (
        <QrCodeContainer destData={destData} setDestData={setDestData} />
      ) : (
        <Formik
          validationSchema={schema}
          onSubmit={onSubmit}
          initialValues={{
            qr_pass_phrase: '',
          }}
          component={PublicQrCodeForm}
        />
      )}
      <SnackbarAlert
        showSnackbar={!!(errorMsg)}
        setShowSnackbar={setErrorMsg}
        severity="error"
        message={errorMsg}
        disableAutoHide
      />
    </Container>
  )
}

export default PublicQrCodePage
