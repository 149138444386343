import React from 'react'
import GridListTile from '@material-ui/core/GridListTile'
import GridList from '@material-ui/core/GridList'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { imgUrl } from '../helpers/constants'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: 500,
    maxHeight: 500,
  },
}))

const ImageGridList = props => {
  const { photos, imgDirectory } = props
  const classes = useStyles()
  let items = []

  const getGridListItems = () => {
    let sum = 0
    photos.forEach((photo, idx) => {
      sum = sum + idx + 1
      let idxIsEven = idx % 2 === 0
      let sumIsEven = sum % 2 === 0
      let colsCount
      if (idxIsEven === sumIsEven) {
        colsCount = 2
      } else {
        colsCount = 1
      }
      items.push(
        <GridListTile key={photo.id} cols={colsCount}>
          <img src={`${imgUrl}${imgDirectory}/${photo.name}`} alt={photo.name} />
        </GridListTile>,
      )
    })
  }

  if (photos) {
    getGridListItems()
  }

  return (
    <div className={classes.root}>
      <GridList cellHeight={160} className={classes.gridList} cols={3}>
        {items}
      </GridList>
    </div>
  )
}

export default ImageGridList