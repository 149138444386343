import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useFetchItem } from '../helpers/apiFetchHooks'
import DestinationDetails from '../components/DestinationDetails'
import MapContainer from '../containters/MapContainer'
import PaperContainer from '../containters/PaperContainer'
import RatingOverview from '../components/RatingOverview'
import { imgUrl } from '../helpers/constants'




const ViewDestinationContainer = props => {
  const { history, destination, rating } = props
  const imgSrc = destination.image ? `${imgUrl}destinations/${destination.image}` : false

  return (
    <PaperContainer
      imgSrc={imgSrc}
      history={history}
      name={destination.name}
      subHeader={<RatingOverview rating={rating} destinationId={destination.id} />}
    >
      <DestinationDetails destination={destination} />
      <MapContainer destinations={[{ ...destination, isStamped: false }]} height={'35vh'} />
    </PaperContainer>
  )
}


const ViewDestinationPage = () => {
  const { id } = useParams()
  const history = useHistory()
  const { itemData: destination, fetched } = useFetchItem('destination_view', id)
  const { itemData: rating, fetched: ratingFetched } = useFetchItem('rating_review', id)

  if (!fetched || !ratingFetched) {
    return (
      <>
        <h2>Loading</h2>
      </>
    )
  } else if (fetched) {
    return (
      <ViewDestinationContainer rating={rating} destination={destination} history={history} />
    )
  } else {
    return null
  }
}

export default ViewDestinationPage
