import React from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import { normalizePhoneNumber } from '../helpers/utils'
import ProfileAvatar from '../components/ProfileAvatar'
import Grid from '@material-ui/core/Grid'
import { DropzoneArea } from 'material-ui-dropzone'
import { makeStyles } from '@material-ui/core/styles'
import axios from 'axios'
import { CircularProgress } from '@material-ui/core'
import { imgUrl } from '../helpers/constants'

const useStyles = makeStyles(theme => ({
  dropZone: {
    width: '100%',
    minHeight: 175,
  },
}))

const ProfileEditForm = props => {
  const {
    handleSubmit,
    values,
    handleChange,
    setFieldTouched,
    errors,
    touched,
    isValid,
    phoneValue,
    setPhoneValue,
    setImageFile,
    imageFile,
    image,
    setProfile,
    saving,
    setSaving,
  } = props

  const change = (name, e) => {
    e.persist()
    if (name === 'phone') {
      const prettyPhone = normalizePhoneNumber(e.target.value || '')
      setPhoneValue(prettyPhone)
    } else {
      handleChange(e)
    }
    setFieldTouched(name, true, false)
  }

  const handleDeleteImage = () => {
    setSaving(true)
    axios.patch('/api/profile/1/')
      .then(res => {
        setProfile(res.data)
        setSaving(false)
      })
      .catch(() => {
        setSaving(false)
      })
  }

  const imgSrc = image ? `${imgUrl}profiles/${image}` : null
  const imagePath = imageFile ? URL.createObjectURL(imageFile) : null
  const dropZonePrompt = image ? 'Change profile photo' : 'Add profile photo'

  const classes = useStyles()

  const disableSubmit = !(isValid && phoneValue.length === 14)
  return (
    <form style={{ width: '100%' }}>
      <Grid container justify="center" direction="column" alignItems="center">
        <ProfileAvatar
          firstName={values.firstName}
          src={imagePath || imgSrc}
          fontSize='3.0rem'
          height={75}
          width={75}
        />
        {image && !saving && (
          <Button
            variant="contained"
            color="secondary"
            style={{ width: 200, marginTop: 10, marginBottom: 20 }}
            onClick={handleDeleteImage}
            disabled={saving}
          >
            {saving ? `Loading` : `Delete Profile Photo`}
          </Button>)}
        <DropzoneArea
          acceptedFiles={['image/*']}
          dropzoneClass={classes.dropZone}
          dropzoneText={dropZonePrompt}
          filesLimit={1}
          useChipsForPreview
          style={{ outline: 'none' }}
          maxFileSize={75000000}
          onChange={files => {
            setImageFile(files[0])
          }}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="firstName"
          label="First Name"
          name="firstName"
          autoComplete="firstName"
          value={values.firstName}
          onChange={change.bind(null, 'firstName')}
          helperText={touched.firstName ? errors.firstName : ''}
          error={touched.firstName && Boolean(errors.firstName)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="lastName"
          label="Last Name"
          id="lastName"
          autoComplete="lastName"
          value={values.lastName}
          onChange={change.bind(null, 'lastName')}
          helperText={touched.lastName ? errors.lastName : ''}
          error={touched.lastName && Boolean(errors.lastName)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          value={values.email}
          onChange={change.bind(null, 'email')}
          helperText={touched.email ? errors.email : ''}
          error={touched.email && Boolean(errors.email)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="phone"
          label="Phone Number"
          name="phone"
          autoComplete="phone"
          value={phoneValue}
          onChange={change.bind(null, 'phone')}
          helperText={touched.phone ? errors.phone : ''}
          error={touched.phone && Boolean(errors.phone)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          name="address"
          label="Address"
          id="address"
          autoComplete="address"
          value={values.address}
          onChange={change.bind(null, 'address')}
          helperText={touched.address ? errors.address : ''}
          error={touched.address && Boolean(errors.address)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          name="city"
          label="City"
          id="city"
          autoComplete="city"
          value={values.city}
          onChange={change.bind(null, 'city')}
          helperText={touched.city ? errors.city : ''}
          error={touched.city && Boolean(errors.city)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          name="state"
          label="State"
          id="state"
          autoComplete="state"
          value={values.state}
          onChange={change.bind(null, 'state')}
          helperText={touched.state ? errors.state : ''}
          error={touched.state && Boolean(errors.state)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          name="zip"
          label="Zip"
          id="zip"
          autoComplete="zip code"
          value={values.zip}
          onChange={change.bind(null, 'zip')}
          helperText={touched.zip ? errors.zip : ''}
          error={touched.zip && Boolean(errors.zip)}
        />
        {!saving ? (
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={disableSubmit}
          >
            Save Profile
          </Button>
        ) : (
          <CircularProgress />
        )}
      </Grid>
    </form>
  )
}

export default ProfileEditForm
