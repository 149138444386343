import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import React from 'react'

const useStyles = makeStyles(theme => ({
  button: {
    height: props => props.height,
    textTransform: 'none',
    margin: 0,
    lineHeight: props => props.lineHeight,
    fontSize: props => props.fontSize || "0.875rem",
  },
  label: {
    color: props => props.color,
    flexDirection: 'column',
    margin: 0,
  },
}))

export const IconTextButton = props => {
  const {
    onClick,
    color,
    children,
    label,
    variant,
    labelColor,
    buttonHeight,
    labelFontSize,
    lineHeight,
  } = props
  const styleProps = {
    color: labelColor,
    height: buttonHeight,
    fontSize: labelFontSize,
    lineHeight: lineHeight || 0.5,
  }
  const classes = useStyles(styleProps)


  return (
    <Button
      /* Use classes property to inject custom styles */
      classes={{ root: classes.button, label: classes.label }}
      color={color}
      variant={variant}
      disableRipple={true}
      onClick={onClick}
    >
      {children}
      {label}
    </Button>
  )
}

export default IconTextButton
