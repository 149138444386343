import React, { useContext, useState } from 'react'
import { Formik } from 'formik'
import axios from 'axios'
import * as yup from 'yup'
import CssBaseline from '@material-ui/core/CssBaseline'
import Container from '@material-ui/core/Container'
import { makeStyles } from '@material-ui/core/styles'
import LoginForm from '../forms/LoginForm'
import { useHistory } from 'react-router-dom'
import { store } from '../store/Store'
import { get_csrf_token, useCsrfToken } from '../helpers/GetCsrfToken'
import SnackbarAlert from '../components/SnackbarAlert'
import { get } from 'lodash'

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}))

const schema = yup.object({
  email: yup.string().required().email(),
  password: yup.string().required().min(8),
})

const LoginPage = () => {
  const classes = useStyles()
  const { dispatch } = useContext(store)
  const history = useHistory()
  const [showError, setShowError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  //get_csrf_token()
  useCsrfToken()

  const submit = values => {
    axios.post('/api/login/', values).then(res => {
      const payload = { drfCookie: res.data.login, profileId: res.data.profile_id, username: values.email }
      dispatch({ type: 'login', payload })
      history.push('/passport-home')
    }).catch(err => {
      if (get(err, 'response.data.error')) {
        setErrorMessage('Email and password combination is invalid')
        setShowError(true)
      } else {
        setErrorMessage('Unable to login. Try again - make sure you are connected to internet.')
        setShowError(true)
      }
    })
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline/>
      <div className={classes.paper}>
        <Formik
          validationSchema={schema}
          onSubmit={submit}
          initialValues={{
            email: '',
            password: '',
          }}
          component={LoginForm}
        />
      </div>
      <SnackbarAlert
          showSnackbar={showError}
          setShowSnackbar={setShowError}
          severity="error"
          message={errorMessage}
        />
    </Container>
  )
}

export default LoginPage
