import React, { useReducer } from 'react'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import { Formik } from 'formik'
import * as yup from 'yup'
import DestinationForm from '../forms/DestinationForm'
import axios from 'axios'
import DestinationAutocomplete from '../components/DestinationAutocomplete'
import { imgUrl } from '../helpers/constants'

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'


const init = initialState => {
  return { ...initialState }
}

const destinationReducer = (state, action) => {
  switch (action.type) {
    case 'setDest': {
      return action.payload
    }
    case 'clearDest': {
      return action.payload
    }
    case 'fileChange':
      return { ...state, imageFile: action.payload }
    default:
      throw new Error()
  }
}

const precision = (a) => {
  let e = 1
  while (Math.round(a * e) / e !== a) e *= 10
  return Math.log(e) / Math.LN10
}

const CreateEditDestinationPage = props => {
  const { id, handleSubmit, initialState, destinationMeta } = props
  const [state, dispatch] = useReducer(destinationReducer, initialState, init)
  const qrCodeSrc = state.qr_code_file_name ? `${imgUrl}qrcodes/${state.qr_code_file_name}` : null

  const schema = yup.object({
    name: yup.string().required(),
    address: yup.string().required(),
    city: yup.string().required(),
    state: yup.string().required(),
    stamp_type: yup.string().required(),
    zip: yup.number().min(5, 'Please enter a valid zip code').required(),
    latitude: yup.number().test(
      'is-latitude',
      'Must be between 90 and -90 | max 7 decimal places',
      value => parseFloat(value) < 90 && parseFloat(value) > -90 && precision(value) < 8,
    ).required(),
    longitude: yup.number().test(
      'is-longitude',
      'Must be between 180 and -180 | max 7 decimal places',
      value => parseFloat(value) < 180 && parseFloat(value) > -180 && precision(value) < 8,
    ).required(),
    description: yup.string().required(),
  })

  return (
    <Container component="main" maxWidth="sm">
      <Typography component="h1" variant="h5" style={{ marginTop: 75, marginBottom: 20 }}>
        {id ? 'Edit' : 'Add'} destination
      </Typography>
      {qrCodeSrc && <img src={qrCodeSrc} style={{ width: "100%"}} />}
      <DestinationAutocomplete dispatch={dispatch} initialState={initialState} />
      <div>
        <Formik
          validationSchema={schema}
          onSubmit={(values, formikHelpers) => handleSubmit(values, formikHelpers, state.imageFile, dispatch)}
          enableReinitialize={true}
          initialValues={{
            name: state.name,
            address: state.address,
            city: state.city,
            state: state.state,
            zip: state.zip,
            description: state.description,
            image: state.image,
            latitude: state.latitude,
            longitude: state.longitude,
            difficulty: state.difficulty,
            time_to_complete_hours: state.time_to_complete_hours,
            time_to_complete_minutes: state.time_to_complete_minutes,
            distance: state.distance,
            distance_unit: state.distance_unit,
            elevation: state.elevation,
            elevation_unit: state.elevation_unit,
            destination_types: state.destination_types,
            accessibility_features: state.accessibility_features,
            second_image: state.second_image,
            stamp_type: state.stamp_type,
            qr_pass_phrase: state.qr_pass_phrase
          }}
          children={props => <DestinationForm
            {...props}
            buttonText={`${id ? 'Update' : 'Add'} destination`}
            isEdit={!!(id)}
            dispatch={dispatch}
            initialState={initialState}
            destinationMeta={destinationMeta}
          />}
        />
      </div>
    </Container>
  )
}

export default CreateEditDestinationPage
