import React from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import { normalizePhoneNumber } from '../helpers/utils'


const PhoneForm = props => {
  const {
    handleSubmit,
    values,
    handleChange,
    setFieldTouched,
    errors,
    touched,
    isValid,
    phoneValue,
    setPhoneValue,
  } = props

  const change = (name, e) => {
    e.persist()
    if (name === 'phone') {
      const prettyPhone = normalizePhoneNumber(e.target.value || '')
      setPhoneValue(prettyPhone)
    } else {
      handleChange(e)
    }
    setFieldTouched(name, true, false)
  }


  const disableSubmit = !(isValid && phoneValue && phoneValue.length === 14)
  return (
    <form style={{ width: '100%' }}>
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="name"
        label="Name"
        name="name"
        value={values.name}
        onChange={change.bind(null, 'name')}
        helperText={touched.name ? errors.name : ''}
        error={touched.name && Boolean(errors.name)}
      />
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        name="phone"
        label="Phone"
        id="phone"
        value={phoneValue}
        onChange={change.bind(null, 'phone')}
        helperText={touched.phone ? errors.phone : ''}
        error={touched.phone && Boolean(errors.phone)}
        InputProps={{
          endAdornment: <InputAdornment position="end">
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              type="submit"
              disabled={disableSubmit}
            >
              Add
            </Button>
          </InputAdornment>,
        }}
      />
    </form>
  )
}

export default PhoneForm
