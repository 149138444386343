import { useEffect, useState } from 'react'
import axios from 'axios'
import useLogin from '../store/useLogin'

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'

export const useFetchList = (endpoint, {id = ''} = {}) => {
  const [listData, setListData] = useState([])
  const [fetched, setFetched] = useState(false)

  useEffect(() => {
    const fetchList = async () => {
      if (id) {
        const res = await axios.get(`/api/${endpoint}/${id}/`)
        return res.data
      } else {
        const res = await axios.get(`/api/${endpoint}/`)
        return res.data
      }
    }

    fetchList()
      .then(data => {
        setListData(data)
        setFetched(true)
      })
      .catch(err => console.log(err))
  }, [endpoint, id])

  return { listData, fetched, setListData }
}

export const useFetchLoggedIn = endpoint => {
  const [loggedInData, setLoggedInData] = useState({})
  const [fetched, setFetched] = useState(false)
  const { drfCookie } = useLogin()

  useEffect(() => {
    const fetchList = async () => {
       if (drfCookie) {
        const res = await axios.get(`/api/${endpoint}/`)
        return res.data
      } else {
         return {}
       }
    }

    fetchList()
      .then(data => {
        setLoggedInData(data)
        setFetched(true)
      })
      .catch(err => console.log(err))
  }, [endpoint, drfCookie])

  return { loggedInData, fetched, setLoggedInData }
}


export const useFetchItem = (endpoint, id, dispatch, action) => {
  const [itemData, setItemData] = useState({})
  const [fetched, setFetched] = useState(false)


  useEffect(() => {
    const fetchItem = async () => {
      const res = await axios.get(`/api/${endpoint}/${id}/`)
      return res.data
    }

    if (id) {
      fetchItem().then(data => {
        setItemData(data)
        setFetched(true)
        if (dispatch) {
          dispatch({type: action, payload: data})
        }
      }).catch(err => {
        console.log(err)
      })
    } else {
      setFetched(true)
    }
  }, [endpoint, id, dispatch, action])

  return { itemData, fetched, setItemData }
}


export const useFetchItemQueryParams = (endpoint, id, queryParams) => {
  const [itemData, setItemData] = useState({})
  const [fetched, setFetched] = useState(false)


  useEffect(() => {
    const fetchItem = async () => {
      const res = await axios.get(`/api/${endpoint}/${id}/?${queryParams}`)
      return res.data
    }

    if (id) {
      fetchItem().then(data => {
        setItemData(data)
        setFetched(true)
      }).catch(err => {
        console.log(err)
      })
    } else {
      setFetched(true)
    }
  }, [endpoint, id, queryParams])

  return { itemData, fetched, setItemData }
}
