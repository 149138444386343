import Container from '@material-ui/core/Container'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import axios from 'axios'
import Button from '@material-ui/core/Button'
import { useHistory } from 'react-router-dom'
import { useFetchList } from '../helpers/apiFetchHooks'
import CrudListItem from '../components/CrudListItem'

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'

const DestinationListPage = () => {
  const { listData, fetched } = useFetchList('destination')
  const destinations = listData
  const history = useHistory()
  const handleClick = () => {
    history.push('/destination')
  }

  if (!fetched || !destinations) {
    return (
      <>
        <h2>Loading</h2>
      </>
    )
  } else {
    const destinationItems = destinations.map(dest => (
      <CrudListItem
        avatarVariant="circle"
        itemType="destination"
        imgDirectory="img/destinations"
        key={dest.id}
        {...dest}
        isEditList={true}
        viewLink={true}
      />
    ))
    return (
      <Container component="main" maxWidth="md" style={{ marginTop: 50, marginBottom: 20 }}>
        {destinations.length === 0 && fetched && (
          <>
            <Typography variant="h6">Gotta make some destinations!</Typography>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleClick}
              style={{ maxWidth: 200, marginTop: 20 }}
            >
              Make Destinations
            </Button>
          </>
        )}
        {destinations.length > 0 && destinationItems && (
          <div>
            <Typography variant="h6">Your Destinations</Typography>

            <List>{destinationItems}</List>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleClick}
              style={{ maxWidth: 200, marginTop: 20, marginBottom: 20 }}
            >
              Make Destinations
            </Button>
          </div>)}
      </Container>
    )
  }
}

export default DestinationListPage
