import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useFetchItem, useFetchList } from '../helpers/apiFetchHooks'
import CreateEditDestinationPage from './CreateEditDestinationPage'
import axios from 'axios'
import { Typography } from '@material-ui/core'
import { get } from 'lodash'

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'

const initialState = {
  googleDest: '',
  destSelected: false,
  latitude: '',
  longitude: '',
  name: '',
  address: '',
  city: '',
  state: '',
  zip: '',
  imageFile: {},
  image: '',
  description: '',
  difficulty: '',
  time_to_complete_hours: '',
  time_to_complete_minutes: '',
  distance: '',
  distance_unit: '',
  elevation: '',
  elevation_unit: '',
  destination_types: [],
  accessibility_features: [],
  secondImageFile: {},
  second_image: '',
  stamp_type: '',
  qr_code_file_name: '',
  qr_pass_phrase: '',
}


const getEditInitialState = apiData => {
  return {
    googleDest: '',
    destSelected: false,
    latitude: apiData.latitude || 'not-set',
    longitude: apiData.longitude || 'not-set',
    name: apiData.name || '',
    address: apiData.address || '',
    city: apiData.city || '',
    state: apiData.state || '',
    zip: apiData.zip || '',
    imageFile: {},
    image: apiData.image,
    description: apiData.description,
    difficulty: get(apiData, 'difficulty.name', ''),
    time_to_complete_hours: apiData.time_to_complete_hours ||'',
    time_to_complete_minutes: apiData.time_to_complete_minutes || '',
    distance: apiData.distance || '',
    distance_unit: get(apiData, 'distance_unit.name', ''),
    elevation: apiData.elevation || '',
    elevation_unit: get(apiData, 'elevation_unit.name', ''),
    destination_types: get(apiData, 'destination_types', []).map(d_type => d_type.name),
    accessibility_features: get(apiData, 'accessibility_features', []).map(a_feature => a_feature.name),
    secondImageFile: {},
    second_image: apiData.second_image || '',
    stamp_type: apiData.stamp_type || '',
    qr_code_file_name: apiData.qr_code_file_name || '',
    qr_pass_phrase: apiData.qr_pass_phrase || '',
  }
}


const ManageDestinationPage = props => {
  const { refresh, setData, toggleDest, ignoreId } = props
  const { id: idFromUrl } = useParams()
  const id = ignoreId ? null : idFromUrl
  let history = useHistory()
  const { itemData: destinationData, fetched } = useFetchItem('destination', id)
  const {listData: destinationMeta, fetched: fetchedMeta} = useFetchList('destination_meta')

  const handleSubmit = (values, { resetForm }, imageFile, dispatch, secondImageFile) => {
    let form_data = new FormData()

    for (const key in values) {
      form_data.append(key, values[key])
    }
    if (imageFile && imageFile.name) {
      form_data.append('image_file', imageFile, imageFile.name)
    }
    if (secondImageFile && secondImageFile.name) {
      form_data.append('image_file', imageFile, imageFile.name)
    }
    if (id) {
      form_data.append('id', id)
    }

    axios
      .post('/api/destination/', form_data, {
        headers: {
          'content-type': 'multipart/form-data',
        },
      })
      .then(res => {
        if (refresh) {
          axios.get('/api/destination/').then(res => {
            dispatch({ type: 'clearDest', payload: initialState })
            setData(res.data, values.name)
            resetForm({})
            toggleDest(false)
          })
        } else {
          history.push('/profile-home')
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  if (!fetched || !fetchedMeta) {
    return (
      <>
        <Typography variant="h2">Loading</Typography>
      </>
    )
  } else {
    const editInitialState = getEditInitialState(destinationData)
    return (
      <CreateEditDestinationPage
        id={id}
        handleSubmit={handleSubmit}
        initialState={id ? editInitialState : initialState}
        destinationMeta={destinationMeta}
      />
    )
  }
}

export default ManageDestinationPage
