import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import { DropzoneArea } from 'material-ui-dropzone'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Box from '@material-ui/core/Box'
import { Card, Typography } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import HtmlTooltip from '../components/HtmlTooltip'
import Grid from '@material-ui/core/Grid'
import InputAdornment from '@material-ui/core/InputAdornment'
import axios from 'axios'
import { imgUrl } from '../helpers/constants'

const useStyles = makeStyles(theme => ({
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

const StampInfoTooltip = () => {

  const divStyle = {
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'baseline',
    flexWrap: 'noWrap',
    marginBottom: 10,
    marginTop: 5,
  }

  return (
    <HtmlTooltip
      tooltipIcon={<HelpOutlineIcon />}
    >
      <div style={divStyle}>
        <Typography variant="h6" style={{ textAlign: 'left', marginRight: 5 }}>Honor:</Typography>
        <Typography variant="subtitle1">Users simply click a button.</Typography>
      </div>
      <div style={divStyle}>
        <Typography variant="h6" style={{ textAlign: 'left', marginRight: 5 }}>GPS:</Typography>
        <Typography variant="subtitle1">Users must be within a 1/2 mile to get stamp.</Typography>
      </div>
      <div style={divStyle}>
        <Typography variant="h6" style={{ textAlign: 'left', marginRight: 5 }}>QR:</Typography>
        <Typography variant="subtitle1">Users must scan a QR code.</Typography>
      </div>
    </HtmlTooltip>
  )
}

const DestinationForm = props => {
  const {
    handleSubmit,
    values,
    handleChange,
    setFieldTouched,
    setFieldValue,
    errors,
    touched,
    dispatch,
    buttonText,
    isValid,
    dirty,
    isEdit,
    destinationMeta,
  } = props
  const [showDropZone, setShowDropZone] = useState(!values.image)
  const [showImg, setShowImg] = useState(values.image)
  const isMobile = useMediaQuery('(max-width:760px)')
  const change = (name, e) => {
    e.persist()
    handleChange(e)
    setFieldTouched(name, true, false)
  }
  const classes = useStyles()
  const disableSubmit = isEdit ? !(isValid) : !(isValid && dirty)

  const handleFileChange = files => {
    if (files.length === 0) {
      dispatch({ type: 'fileChange', payload: {} })
    } else {
      dispatch({ type: 'fileChange', payload: files[0] })
    }
  }

  const getQrPassPhrase = () => {
    axios
      .get('/api/qr_pass_phrase/')
      .then(res => {
        setFieldValue('qr_pass_phrase', res.data)
        setFieldTouched('qr_pass_phrase', true, false)
      })
      .catch()
  }

  const handleImgDelete = () => {
    setShowDropZone(true)
    setShowImg(false)
    setFieldValue('image', 'delete_image')
  }

  const handleMultiChange = (value, fieldName) => {
    setFieldValue(
      fieldName,
      value,
    )
  }

  const onKeyDown = keyEvent => {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault()
    }
  }

  return (
    <>
      <form className={classes.form} onKeyDown={onKeyDown}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="name"
          label="Name"
          name="name"
          autoComplete="name"
          value={values.name}
          onChange={change.bind(null, 'name')}
          helperText={touched.name ? errors.name : ''}
          error={touched.name && Boolean(errors.name)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="address"
          label="Address"
          type="address"
          id="address"
          autoComplete="address"
          value={values.address}
          onChange={change.bind(null, 'address')}
          helperText={touched.address ? errors.address : ''}
          error={touched.address && Boolean(errors.address)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="city"
          label="City"
          name="city"
          autoComplete="city"
          value={values.city}
          onChange={change.bind(null, 'city')}
          helperText={touched.city ? errors.city : ''}
          error={touched.city && Boolean(errors.city)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          name="state"
          label="State"
          type="state"
          id="state"
          autoComplete="state"
          value={values.state}
          onChange={change.bind(null, 'state')}
          helperText={touched.state ? errors.state : ''}
          error={touched.state && Boolean(errors.state)}
          style={{ float: 'left', width: '60%' }}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          name="zip"
          label="Zip Code"
          type="zip"
          id="zip"
          autoComplete="zip"
          value={values.zip}
          onChange={change.bind(null, 'zip')}
          helperText={touched.zip ? errors.zip : ''}
          error={touched.zip && Boolean(errors.zip)}
          style={{ float: 'right', width: '35%' }}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          name="latitude"
          label="Latitude"
          type="number"
          step={0.000001}
          id="latitude"
          value={values.latitude}
          onChange={change.bind(null, 'latitude')}
          helperText={touched.latitude ? errors.latitude : ''}
          error={touched.latitude && Boolean(errors.latitude)}
          style={{ float: 'left', width: '47.5%' }}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          name="longitude"
          label="Longitude"
          type="number"
          step={0.000001}
          id="longitude"
          value={values.longitude}
          onChange={change.bind(null, 'longitude')}
          helperText={touched.longitude ? errors.longitude : ''}
          error={touched.longitude && Boolean(errors.longitude)}
          style={{ float: 'right', width: '47.5%' }}
        />
        <Grid container spacing={1} alignItems="flex-end">
          <Grid item xs={11}>
            <Autocomplete
              id="stamp_type"
              options={['', 'Honor', 'GPS', 'QR']}
              onChange={(_, value) => {
                setFieldValue('stamp_type', value)
              }}
              fullWidth
              getOptionLabel={option => option}
              value={values.stamp_type}
              disableClearable
              renderInput={(params) =>
                <TextField
                  {...params}
                  label="Stamp Type"
                  variant="outlined"
                  required
                  helperText={errors.stamp_type || ''}
                  error={Boolean(errors.stamp_type)}
                />
              }
            />
          </Grid>
          <Grid item xs={1} style={{ alignSelf: 'start' }}>
            <StampInfoTooltip />
          </Grid>
        </Grid>
        {values.stamp_type === 'QR' && (
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            type="text"
            id="qr_pass_phrase"
            label="QR Pass Phrase"
            name="qr_pass_phrase"
            value={values.qr_pass_phrase}
            onChange={change.bind(null, 'qr_pass_phrase')}
            helperText="Use this phrase at vagabongo.com/qr to display the qr code."
            InputProps={{
              endAdornment: <InputAdornment position="end">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={getQrPassPhrase}
                >
                  Get Code
                </Button>
              </InputAdornment>,
            }}
          />)}
        <TextField
          variant="outlined"
          margin="normal"
          required
          multiline
          rows={6}
          fullWidth
          name="description"
          label="Description"
          placeholder="Add a personalized description"
          type="description"
          id="description"
          autoComplete="description"
          value={values.description}
          onChange={change.bind(null, 'description')}
          helperText={touched.description ? errors.description : ''}
          error={touched.description && Boolean(errors.description)}
        />
        <Card variant="outlined" style={{ marginTop: 10, marginBottom: 10 }}>
          <Typography variant="subtitle1" style={{ marginTop: 5 }}>Completion Time</Typography>
          <TextField
            variant="outlined"
            margin="normal"
            name="time_to_complete_hours"
            label="Hours"
            type="number"
            step={1}
            id="time_to_complete_hours"
            value={values.time_to_complete_hours}
            onChange={change.bind(null, 'time_to_complete_hours')}
            helperText={touched.time_to_complete_hours ? errors.time_to_complete_hours : ''}
            error={touched.time_to_complete_hours && Boolean(errors.time_to_complete_hours)}
            style={{ float: 'left', width: '45%', marginLeft: 10, marginBottom: 10 }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            name="time_to_complete_minutes"
            label="Minutes"
            type="number"
            step={1}
            id="time_to_complete_minutes"
            value={values.time_to_complete_minutes}
            onChange={change.bind(null, 'time_to_complete_minutes')}
            helperText={touched.time_to_complete_minutes ? errors.time_to_complete_minutes : ''}
            error={touched.time_to_complete_minutes && Boolean(errors.time_to_complete_minutes)}
            style={{ float: 'right', width: '45%', marginRight: 10, marginBottom: 10 }}
          />
        </Card>
        <Autocomplete
          id="difficulty"
          options={['', ...destinationMeta['difficulty'].map(diff => diff.name)]}
          onChange={(_, value) => {
            setFieldValue('difficulty', value)
          }}
          getOptionLabel={option => option}
          value={values.difficulty}
          fullWidth
          renderInput={(params) =>
            <TextField {...params} label="Difficulty" variant="outlined" />
          }
        />
        <TextField
          variant="outlined"
          margin="normal"
          name="distance"
          label="Distance"
          type="number"
          step={0.01}
          id="distance"
          value={values.distance}
          onChange={change.bind(null, 'distance')}
          helperText={touched.distance ? errors.distance : ''}
          error={touched.distance && Boolean(errors.distance)}
          style={{ float: 'left', width: '55%' }}
        />
        <Autocomplete
          id="distance_unit"
          options={['', ...destinationMeta['distance_unit'].map(d_unit => d_unit.name)]}
          onChange={(_, value) => setFieldValue('distance_unit', value)}
          getOptionLabel={option => option}
          value={values.distance_unit}
          style={{ float: 'right', width: '40%', marginTop: 18 }}
          renderInput={(params) =>
            <TextField {...params} label="Distance Unit" variant="outlined" />
          }
        />
        <TextField
          variant="outlined"
          margin="normal"
          name="elevation"
          label="Elevation"
          type="number"
          step={0.01}
          id="elevation"
          value={values.elevation}
          onChange={change.bind(null, 'elevation')}
          helperText={touched.elevation ? errors.elevation : ''}
          error={touched.elevation && Boolean(errors.elevation)}
          style={{ float: 'left', width: '55%', marginBottom: 25 }}
        />
        <Autocomplete
          id="elevation_unit"
          options={['', ...destinationMeta['elevation_unit'].map(e_unit => e_unit.name)]}
          onChange={(_, value) => setFieldValue('elevation_unit', value)}
          getOptionLabel={option => option}
          value={values.elevation_unit}
          style={{ float: 'right', width: '40%', marginTop: 18, marginBottom: 25 }}
          renderInput={(params) =>
            <TextField {...params} label="Elevation Unit" variant="outlined" />
          }
        />
        <Autocomplete
          multiple
          id="destination_types"
          options={['', ...destinationMeta['destination_types'].map(d_type => d_type.name)]}
          onChange={(_, value) => handleMultiChange(value, 'destination_types')}
          value={values.destination_types}
          getOptionLabel={option => option}
          filterSelectedOptions
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Destination Types"
              placeholder="hiking"
            />
          )}
        />
        <Autocomplete
          style={{ marginTop: 25 }}
          multiple
          id="accessibility_features"
          options={['', ...destinationMeta['accessibility_features'].map(d_type => d_type.name)]}
          onChange={(_, value) => handleMultiChange(value, 'accessibility_features')}
          value={values.accessibility_features}
          getOptionLabel={option => option}
          filterSelectedOptions
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Accessibility Features"
              placeholder="wheelchair accessible"
            />
          )}
        />
        {showImg && (
          <>
            <Box flexGrow={1}>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleImgDelete}
                style={{ marginTop: 10, marginBottom: 10 }}
              >
                Delete Image
              </Button>
            </Box>
            <Box flexGrow={1}>
              <img height="300" src={`${imgUrl}destinations/${values.image}`} alt={values.name} />
              <input type="hidden" value={values.image} name="image" />
            </Box>
          </>
        )}
        {showDropZone && (
          <DropzoneArea
            acceptedFiles={['image/*']}
            dropzoneText={'Add or take a photo'}
            filesLimit={1}
            useChipsForPreview={isMobile}
            style={{ outline: 'none' }}
            maxFileSize={75000000}
            onChange={files => {
              handleFileChange(files)
            }}
          />
        )}
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={handleSubmit}
          disabled={disableSubmit}
        >
          {buttonText}
        </Button>
      </form>
    </>
  )
}

export default DestinationForm
