import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import BottomNavigation from '@material-ui/core/BottomNavigation'
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction'
import GroupIcon from '@material-ui/icons/Group'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPassport } from '@fortawesome/free-solid-svg-icons'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import SettingsIcon from '@material-ui/icons/Settings';
import { useHistory } from 'react-router-dom'
import { useFetchListLoggedIn, useFetchLoggedIn } from '../helpers/apiFetchHooks'


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    position: 'fixed',
    color: 'white',
    bottom: 0,
    backgroundColor: theme.palette.primary.main,
    zIndex: 100000000000,
  },
  action: {
    color: 'lightgray',
  },
  actionSelected: {
    color: 'white',
  },
}))

const BottomNavBar = () => {
  const history = useHistory()
  const classes = useStyles()
  const [value, setValue] = React.useState(0)
  const { loggedInData: permission, fetched: fetchedPermission } = useFetchLoggedIn('permissions')

  const handleOnClick = endpoint => {
    history.push(endpoint)
  }

  return (
    <BottomNavigation
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue)
      }}
      showLabels
      className={classes.root}
    >
      <BottomNavigationAction
        onClick={() => handleOnClick('/passport-home')}
        classes={{ root: classes.action, selected: classes.actionSelected }}
        label="Passports"
        icon={<FontAwesomeIcon icon={faPassport} style={{ color: 'white' }} />}
      />
      <BottomNavigationAction
        onClick={() => handleOnClick('/social-home')}
        classes={{ root: classes.action, selected: classes.actionSelected }}
        label="Social"
        icon={<GroupIcon style={{ color: 'white' }} />} />
      <BottomNavigationAction
        onClick={() => handleOnClick('/profile-home')}
        classes={{ root: classes.action, selected: classes.actionSelected }}
        label="Profile"
        icon={<AccountCircleIcon style={{ color: 'white' }} />} />
      {fetchedPermission && permission.is_superuser && (
        <BottomNavigationAction
          onClick={() => handleOnClick('/admin-home')}
          classes={{ root: classes.action, selected: classes.actionSelected }}
          label="Admin"
          icon={<SettingsIcon style={{ color: 'white' }} />} />)}
    </BottomNavigation>
  )
}

export default BottomNavBar
