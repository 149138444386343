import Typography from '@material-ui/core/Typography'
import React from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Button from '@material-ui/core/Button'
import { useHistory } from 'react-router-dom'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Container } from '@material-ui/core'
import { truncate } from '../helpers/utils'
import { imgUrl } from '../helpers/constants'


const useStyles = makeStyles(() => ({
  root: {
    maxWidth: 400,
    flexGrow: 1,
    height: 450,
    backgroundColor: '#4050B5',
  },
}))


const FeaturedPassportCard = props => {
  const { card, imgDirectory, linkRoot } = props
  const history = useHistory()
  const styles = useStyles()
  return (
    <Button
      onClick={() => history.push(`/${linkRoot}/${card.id}`)}
      style={{ textTransform: 'none' }}
    >
      <Card className={styles.root}>
        <CardMedia
          component="img"
          alt={card.name}
          height="75%"
          image={card.image ? `${imgDirectory}/${card.image}` : null}
        />
        <CardContent>
          <Typography variant="h5" component="h2" style={{ color: 'white' }}>
            {truncate(card.name, 50)}
          </Typography>
        </CardContent>
      </Card>
    </Button>
  )
}


const FeaturedPassportContainer = props => {
  const { featuredPassports } = props

  const featuredCardItems = featuredPassports.map(passport => (
      <FeaturedPassportCard
        card={passport}
        key={passport.id}
        imgDirectory={`${imgUrl}passports`}
        linkRoot={'passport-featured'}
      />
    ),
  )

  return (
    <Container maxWidth="sm">
      <Typography
        component="h1"
        variant="h4"
        style={{ marginTop: 50, marginBottom: 10 }}
      >
        Featured Passports
      </Typography>
      {featuredCardItems}
    </Container>
  )
}

export default FeaturedPassportContainer
