import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import MobileStepper from '@material-ui/core/MobileStepper'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import SwipeableViews from 'react-swipeable-views'
import Container from '@material-ui/core/Container'
import { useHistory } from 'react-router-dom'
import { truncate } from '../helpers/utils'

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 400,
    flexGrow: 1,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    maxHeight: 150,
    minHeight: 50,
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
  },
  img: {
    height: 255,
    display: 'block',
    maxWidth: 400,
    overflow: 'hidden',
    width: '100%',
  },
  paper: {
    elevation: 5,
  },
}))


const PassportWalletPage = props => {
  const { passportItems, activeStep, setActiveStep } = props
  const classes = useStyles()
  const theme = useTheme()
  const history = useHistory()

  const maxSteps = passportItems.length

  const handleClick = (id) => {
    history.push(`/passport-explore/${id}/`)
  }

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const handleStepChange = step => {
    setActiveStep(step)
  }


  if (!passportItems) {
    return null
  } else {
    return (
      <Container component="main" maxWidth="xs">
        <Typography component="h1" variant="h4" style={{ marginTop: 30, marginBottom: 20 }}>
          Active Passports
        </Typography>
        <div className={classes.root}>
          <Paper square elevation={5} className={classes.header}>
            <Typography>{truncate(passportItems[activeStep].label, 45)}</Typography>
          </Paper>
          <SwipeableViews
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={activeStep}
            onChangeIndex={handleStepChange}
            enableMouseEvents
          >
            {passportItems.map((step, index) => (
              <div key={step.label}>
                {Math.abs(activeStep - index) <= 2 ? (
                  <img className={classes.img} src={step.imgPath} alt={step.label} />
                ) : null}
              </div>
            ))}
          </SwipeableViews>
          <Paper elevation={5}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              onClick={() => handleClick(passportItems[activeStep].id)}
              style={{ marginTop: 5, marginBottom: 5 }}
            >
              Open Passport
            </Button>
            <Typography>{truncate(passportItems[activeStep].description, 45)}</Typography>
            <MobileStepper
              steps={maxSteps}
              position="static"
              variant="text"
              activeStep={activeStep}
              nextButton={
                <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
                  Next
                  {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                </Button>
              }
              backButton={
                <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                  {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                  Back
                </Button>
              }
            />
          </Paper>
        </div>
      </Container>
    )
  }
}

export default PassportWalletPage
