import React from 'react'
import './App.css'
import NavigationBar from './components/NavigationBar'
import usePageTracking from './helpers/usePageTracking'
import useLogin from './store/useLogin'
import BottomNavBar from './components/BottomNavBar'
import Routes from './config/Routes'


const App = () => {
  usePageTracking()
  const { drfCookie } = useLogin()
  const appStyle = drfCookie ? { display: 'block', height: '100vh' } : {}
  const contentStyle = drfCookie ? { flex: 1, overflowY: 'auto', paddingTop: 50, paddingBottom: 80, overflowX: 'hidden' } : {paddingTop: 50}

  return (
    <div className="App" style={appStyle}>
      <div style={contentStyle}>
        <NavigationBar />
        <Routes/>
      </div>
      {drfCookie && (<BottomNavBar />)}
    </div>
  )
}

export default App
