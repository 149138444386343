import SocialModal from '../components/SocialModal'
import React, { useState } from 'react'
import { isMobile } from 'react-device-detect'
import * as yup from 'yup'
import { formatPhoneNumber } from '../helpers/utils'
import axios from 'axios'
import { get } from 'lodash'
import { Formik } from 'formik'
import PhoneForm from '../forms/PhoneForm'

const WrappedSocialModal = props => {
  const { openModal, setOpenModal, title, text, personName } = props

  return (
    <SocialModal
      modalHeader={'We cannot text your friend without their permission. But you can! Tell them about Vagabongo!'}
      handleCancelClick={() => setOpenModal(false)}
      openModal={openModal}
      shareTitle={title}
      shareSummary={text}
      hashtags={['vagabongo']}
      sharePersonName={personName}
    />
  )
}

const FriendPhoneInviteContainer = props => {
  const { phoneInvites, setPhoneInvites, setErrorMessage } = props
  const [phoneValue, setPhoneValue] = useState('')
  const [openModal, setOpenModal] = useState(false)
  const [personName, setPersonName] = useState()

  const shareData = {
    title: 'Join me on Vagabongo!',
    text: 'Come explore with me!',
    url: 'https://vagabongo.com/profile',
  }

  const handleShare = () => {
    if (isMobile && navigator.share) {
      // share api
      navigator.share(shareData)
        .then(() => console.log('successfully shared'))
        .catch(() => console.log('uh oh - no share'))
    } else {
      // not mobile so do regular website share methods
      setOpenModal(true)
    }
  }

  const phoneSchema = yup.object({
    name: yup.string().required(),
  })

  const handlePhoneSubmit = (values, actions) => {
    const formattedPhone = formatPhoneNumber(phoneValue)
    const data = { phone: formattedPhone, name: values.name }
    axios.post('/api/friend_invite/', data)
      .then(res => {
        const phoneInvite = { phone: formattedPhone, name: values.name, id: res.data.id }
        const newPhones = [...phoneInvites, phoneInvite]
        setPhoneInvites([...newPhones])
        setPhoneValue('')
        setErrorMessage('')
        setPersonName(values.name)
        actions.resetForm({
          values: {
            phone: '',
            name: '',
          },
        })
        handleShare()
      }).catch(err => {
      if (get(err, 'response.data')) {
        setErrorMessage(err.response.data[0])
      }
    })
  }

  return (
    <>
      <Formik
        validationSchema={phoneSchema}
        validateOnChange={true}
        initialValues={{ phone: '', name: '' }}
        onSubmit={handlePhoneSubmit}
        children={props => (
          <PhoneForm
            {...props}
            phoneValue={phoneValue}
            setPhoneValue={setPhoneValue}
          />)}
      />
      <WrappedSocialModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        title={shareData.title}
        text={shareData.text}
        personName={personName}
      />
    </>
  )
}

export default FriendPhoneInviteContainer
