import * as yup from 'yup'
import axios from 'axios'
import { makeStyles } from '@material-ui/core/styles'
import { useCsrfToken } from '../helpers/GetCsrfToken'
import Container from '@material-ui/core/Container'
import CssBaseline from '@material-ui/core/CssBaseline'
import { Formik } from 'formik'
import SnackbarAlert from '../components/SnackbarAlert'
import React, { useState } from 'react'
import PasswordResetForm from '../forms/PasswordResetForm'

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}))

const schema = yup.object({
  email: yup.string().required().email(),
})

const PasswordResetPage = () => {
  const [showSuccess, setShowSuccess] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')
  const classes = useStyles()

  const submit = (values, {resetForm}) => {
    axios.post('/api/password_reset/', values)
      .then(res => {
        setSuccessMessage(res.data.success)
        setShowSuccess(true)
      }).catch(err => {
        console.log(err)
    })
    resetForm()
  }

  useCsrfToken()
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline/>
      <div className={classes.paper}>
        <Formik
          validationSchema={schema}
          onSubmit={submit}
          initialValues={{
            email: '',
          }}
          component={PasswordResetForm}
        />
      </div>
      <SnackbarAlert
          showSnackbar={showSuccess}
          setShowSnackbar={setShowSuccess}
          severity="success"
          message={successMessage}
        />
    </Container>
  )

}

export default PasswordResetPage
