import { Typography } from '@material-ui/core'
import DestinationRating from './DestinationRating'
import React from 'react'
import ReviewsLink from './ReviewsLink'
import { get } from 'lodash'

const RatingOverview = props => {
  const { destinationId, rating } = props

  const rawRating = get(rating, 'number_of_stars')

  const formattedRating = rawRating ? rawRating.toFixed(1) : 'No Ratings'

  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexWrap: 'wrap',
      marginBottom: 10,
      marginTop: 5,
    }}>
      <Typography variant="h6" style={{ textAlign: 'right', marginRight: 10 }}>
        {formattedRating}
      </Typography>
      <DestinationRating rating={rating} readOnly precision={0.5} />
      {!rating.number_of_reviews ? (
        <Typography variant="h6" style={{ marginLeft: 10 }}>
          Not Reviewed
        </Typography>
      ) : (
        <ReviewsLink rating={rating} destinationId={destinationId}/>
      )}
    </div>
  )
}

export default RatingOverview
