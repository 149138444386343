import React, { useState } from 'react'
import ManageAddFriendPage from './ManageAddFriendPage'
import Paper from '@material-ui/core/Paper'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { AppBar, Typography } from '@material-ui/core'
import InvitationContainer from '../containters/InvitationContainer'
import ManageFriendshipPage from './ManageFriendshipPage'


const FriendTabs = props => {
  const { activeTab, setActiveTab } = props

  const handleChange = (event, tab) => {
    setActiveTab(tab)
  }

  return (
    <AppBar position="fixed" color="default" style={{ marginTop: 55 }}>
      <Paper square>
        <Tabs
          value={activeTab}
          variant="fullWidth"
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
          aria-label="social options"
        >
          <Tab label="Feed" />
          <Tab label="Friends" />
          <Tab label="Invites" />
          <Tab label="Activity" />
        </Tabs>
      </Paper>
    </AppBar>
  )
}

const ManageSocialHomePage = () => {
  const [activeTab, setActiveTab] = useState(1)

  return (
    <>
      <FriendTabs activeTab={activeTab} setActiveTab={setActiveTab} />
      {(activeTab === 0 || activeTab === 3) && (<Typography variant="h5" style={{ marginTop: 75 }}>Coming Soon</Typography>)}
      {activeTab === 1 && (<><ManageAddFriendPage /><ManageFriendshipPage /></>)}
      {activeTab === 2 && <InvitationContainer />}
    </>
  )
}

export default ManageSocialHomePage
