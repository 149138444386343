import React, { useState } from 'react'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import { DropzoneArea } from 'material-ui-dropzone'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Checkbox from '@material-ui/core/Checkbox'
import { Container, FormControlLabel, Grid } from '@material-ui/core'
import { imgUrl } from '../helpers/constants'

const useStyles = makeStyles(theme => ({
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

const PassportSettingsForm = props => {
  const { values, handleChange, setFieldTouched, errors, touched, dispatch } = props
  const [showDropZone, setShowDropZone] = useState(!values.image)
  const [showImg, setShowImg] = useState(values.image)
  const isMobile = useMediaQuery('(max-width:760px)')

  const change = (name, e) => {
    e.persist()
    handleChange(e)
    setFieldTouched(name, true, false)
    dispatch({ type: name, payload: e.target.value })
  }
  const classes = useStyles()

  const handleFileChange = files => {
    if (files.length === 0) {
      dispatch({ type: 'fileChange', payload: {} })
    } else {
      dispatch({ type: 'fileChange', payload: files[0] })
    }
  }

  const handleImgDelete = () => {
    setShowDropZone(true)
    setShowImg(false)
    dispatch({ type: 'image', payload: 'delete_image' })
  }

  return (
    <>
      <form className={classes.form}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="name"
          label="Name"
          name="name"
          autoComplete="name"
          autoFocus
          value={values.name}
          onChange={change.bind(null, 'name')}
          helperText={touched.name ? errors.name : ''}
          error={touched.name && Boolean(errors.name)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          multiline
          rows={6}
          fullWidth
          name="description"
          label="Description"
          placeholder="Add a personalized description"
          type="description"
          id="description"
          autoComplete="description"
          value={values.description}
          onChange={change.bind(null, 'description')}
          helperText={touched.description ? errors.description : ''}
          error={touched.description && Boolean(errors.description)}
        />
        <Container maxWidth="sm">
        <FormControlLabel
          label="Featured Passport"
          control={
            <Checkbox
              onChange={change.bind(null, 'featured')}
              color="primary"
              inputProps={{ 'aria-label': 'featured' }}
              checked={values.featured}
            />}
        />
        </Container>
        <Container maxWidth="sm">
        <FormControlLabel
          label="Disable Undo Stamp"
          control={
            <Checkbox
              onChange={change.bind(null, 'disable_undo_stamp')}
              color="primary"
              inputProps={{ 'aria-label': 'disable_undo_stamp' }}
              checked={values.disable_undo_stamp}
            />}
        />
        </Container>
        {showImg && (
          <>
            <Box flexGrow={1}>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleImgDelete}
                style={{ marginTop: 10, marginBottom: 10 }}
              >
                Delete Image
              </Button>
            </Box>
            <Box flexGrow={1}>
              <img height="300" src={`${imgUrl}passports/${values.image}`} alt={values.name} />
              <input type="hidden" value={values.image} name="image" />
            </Box>
          </>
        )}
        {showDropZone && (
          <DropzoneArea
            acceptedFiles={['image/*']}
            dropzoneText={'Add a cover image here'}
            filesLimit={1}
            maxFileSize={75000000}
            useChipsForPreview={isMobile}
            onChange={files => {
              handleFileChange(files)
            }}
          />
        )}
      </form>
    </>
  )
}

export default PassportSettingsForm
