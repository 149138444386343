import React, { useRef, useState } from 'react'
import GoogleMapReact from 'google-map-react'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import PersonPinRoundedIcon from '@material-ui/icons/PersonPinRounded'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import { ClickAwayListener, withStyles } from '@material-ui/core'
import { Link } from 'react-router-dom'
import useSupercluster from 'use-supercluster'
import Avatar from '@material-ui/core/Avatar'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { imgUrl } from '../helpers/constants'

const StyledTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 275,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip)

const MapItem = ({ destination, passportId }) => {
  const [open, setOpen] = useState(false)
  const imgSrc = destination.image ? `${imgUrl}destinations/${destination.image}` : false
  const handleTooltipClose = () => {
    setOpen(false)
  }

  const handleTooltipOpen = () => {
    setOpen(true)
  }

  return (
    <>
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <StyledTooltip
          onClick={handleTooltipOpen}
          arrow
          interactive
          open={open}
          title={
            <>
              {passportId ? (<Link to={`/destination-explore/${passportId}/${destination.id}`}>
                <Typography variant="h6">{destination.name}</Typography>
              </Link>) : (
                <Typography variant="h6">{destination.name}</Typography>
              )}
              {destination.address && (<Typography variant='subtitle1'>{destination.address}</Typography>)}
              {destination.isStamped && <Typography variant="subtitle1">Visited on {destination.stampDate}</Typography>}
              {imgSrc && (<img src={imgSrc} alt={destination.name} style={{ maxWidth: 255, maxHeight: 265 }} />)}
            </>
          }
        >
          {destination.isStamped || destination.userLocation ?
            (
              <PersonPinRoundedIcon fontSize="large" color="primary" />
            ) : (
              <LocationOnIcon fontSize="large" color="primary" />
            )
          }
        </StyledTooltip>
      </ClickAwayListener>
    </>
  )
}

const handleApiLoaded = (map, maps, latLngs) => {
  let bounds = map.getBounds()
  latLngs.forEach(latLng => bounds.extend(latLng))
  map.fitBounds(bounds)
  map.setCenter(bounds.getCenter())
  map.setZoom(map.getZoom())
  if (map.getZoom() > 15) {
    map.setZoom(15)
  }
  map.setClickableIcons(false)
  map.setOptions({fullscreenControl: false})
}

const getLatLng = destinations => {
  return destinations.map(dest => ({ lat: parseFloat(dest.latitude), lng: parseFloat(dest.longitude) }))
}

const Marker = ({ children }) => children

const useStyles = makeStyles((theme) => ({
  blue: {
    backgroundColor: theme.palette.primary.main,
  },
}))


const MapContainer = props => {
  const mapRef = useRef()
  const [bounds, setBounds] = useState(null)
  const [zoom, setZoom] = useState(10)
  const defaultZoom = 11
  const { destinations, height, id } = props
  const latLngs = getLatLng(destinations)
  const defaultCenter = latLngs[0]
  const classes = useStyles()

  const points = destinations.map(dest => ({
    type: 'Feature',
    properties: { cluster: false, destination: dest, passportId: id },
    geometry: {
      type: 'Point',
      coordinates: [
        parseFloat(dest.longitude),
        parseFloat(dest.latitude),
      ],
    },
  }))


  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    options: { radius: 75, maxZoom: 20 },
  })

  return (
    <div style={{ height: height, width: '100%' }}>
      <GoogleMapReact
        defaultCenter={defaultCenter}
        defaultZoom={defaultZoom}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => {
          handleApiLoaded(map, maps, latLngs)
          mapRef.current = map
        }}
        onChange={({ zoom, bounds }) => {
          setZoom(zoom)
          setBounds([
            bounds.nw.lng,
            bounds.se.lat,
            bounds.se.lng,
            bounds.nw.lat,
          ])
        }}
      >
        {clusters.map(cluster => {
          const [longitude, latitude] = cluster.geometry.coordinates
          const {
            cluster: isCluster,
            point_count: pointCount,
          } = cluster.properties

          if (isCluster) {
            return (
              <Marker
                key={`cluster-${cluster.id}`}
                lat={latitude}
                lng={longitude}
              >
                <Avatar
                  style={{
                    width: `${20 + (pointCount / points.length) * 40}px`,
                    height: `${20 + (pointCount / points.length) * 40}px`,
                  }}
                  className={classes.blue}
                  onClick={() => {
                    const expansionZoom = Math.min(
                      supercluster.getClusterExpansionZoom(cluster.id),
                      20,
                    )
                    mapRef.current.setZoom(expansionZoom)
                    mapRef.current.panTo({ lat: latitude, lng: longitude })
                  }}
                >
                  {pointCount}
                </Avatar>
              </Marker>
            )
          }
          return (
            <MapItem
              key={cluster.properties.destination.id}
              lat={latitude}
              lng={longitude}
              destination={cluster.properties.destination}
              passportId={cluster.properties.passportId}
            />
          )
        })}
      </GoogleMapReact>
    </div>
  )
}

export default MapContainer
