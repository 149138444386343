import { useHistory, useParams } from 'react-router-dom'
import { useFetchItem } from '../helpers/apiFetchHooks'
import React from 'react'
import ViewPassportContainer from '../containters/ViewPassportContainer'

const ViewPassportPage = () => {
  const { id } = useParams()
  const history = useHistory()
  const { itemData: passport, fetched } = useFetchItem('passport_view', id)

  if (!fetched) {
    return (
      <>
        <h2>Loading</h2>
      </>
    )
  } else if (fetched) {
    return (
      <ViewPassportContainer passport={passport} history={history}/>
    )
  } else {
    return null
  }
}

export default ViewPassportPage
