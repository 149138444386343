import React, { useState } from 'react'
import axios from 'axios'
import Grid from '@material-ui/core/Grid'
import ProfileAvatar from '../components/ProfileAvatar'
import { Button, CircularProgress, Typography } from '@material-ui/core'
import IconTextButton from '../components/IconTextButton'
import BlockIcon from '@material-ui/icons/Block'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import Container from '@material-ui/core/Container'
import { useFetchList } from '../helpers/apiFetchHooks'
import SnackbarAlert from '../components/SnackbarAlert'
import { get } from 'lodash'
import { imgUrl } from '../helpers/constants'

const ReceivedInvitationsContainer = props => {
  const { invitations, setInvitations } = props
  const [showIgnored, setShowIgnored] = useState(false)
  const [errorMessage, setErrorMessage] = useState()

  const handleAcceptInvite = inviteId => {
    const idx = invitations.findIndex(x => x.id === inviteId)
    axios
      .patch(`/api/friend_invite/${inviteId}/`, { response: 'Accepted' })
      .then(() => {
        const newInvites = [...invitations]
        newInvites.splice(idx, 1)
        setInvitations([...newInvites])
      }).catch(err => {
      if (get(err, 'response.data')) {
        setErrorMessage(err.response.data['msg'])
      }
    })
  }

  const handleIgnoreInvite = inviteId => {
    const idx = invitations.findIndex(x => x.id === inviteId)
    axios
      .patch(`/api/friend_invite/${inviteId}/`, { response: 'Ignore' })
      .then(res => {
        const newInvites = [...invitations]
        newInvites.splice(idx, 1)
        setInvitations([...newInvites, res.data])
      })
  }

  const getInviteItems = (invitations, responseChoice) => {
    return invitations
      .filter(invite => invite.response === responseChoice)
      .map(invite => (
        <>
          <Grid item sm={1} xs={1}>
            <ProfileAvatar
              firstName={invite.creator.first_name}
              src={invite.creator.image ? `${imgUrl}profiles/${invite.creator.image}` : null}
              fontSize='1.75rem'
              height={45}
              width={45}
            />
          </Grid>
          <Grid item sm={8} xs={8}>
            <Typography variant="h6">{invite.creator.first_name} {invite.creator.last_name}</Typography>
          </Grid>
          {responseChoice === 'No Reply' && (
            <Grid item sm={1} xs={1} style={{ marginRight: 20 }}>
              <IconTextButton
                onClick={() => handleIgnoreInvite(invite.id)}
                label="Ignore"
                lineHeight={1.5}
              >
                <BlockIcon fontSize="large" color="secondary" />
              </IconTextButton>
            </Grid>)}
          <Grid item sm={1} xs={1} style={{ marginRight: 10 }}>
            <IconTextButton
              onClick={() => handleAcceptInvite(invite.id)}
              label="Accept"
              lineHeight={1.5}
            >
              <CheckCircleIcon fontSize="large" color="primary" />
            </IconTextButton>
          </Grid>
        </>
      ))
  }

  const pendingInviteItems = getInviteItems(invitations, 'No Reply')
  const ignoreButtonText = showIgnored ? 'Hide Ignored Invites' : 'View Ignored Invites'
  const hasIgnoredInvites = pendingInviteItems.length !== invitations.length

  return (
    <Container component="main" maxWidth="sm">
      {pendingInviteItems.length > 0 ? (
        <>
          <Typography variant="h5" style={{ marginTop: 20 }}>Received Invitations</Typography>
          <Grid container direction="row" justify="space-between" alignItems="center" style={{ width: '100%' }}>
            {pendingInviteItems}
          </Grid>
        </>) : (
          <Typography variant="h5" style={{ marginTop: 20 }}>No Invitations Awaiting Reply</Typography>
      )}
      {hasIgnoredInvites && (
        <Button variant="outlined" color="primary" onClick={() => setShowIgnored(!showIgnored)}
                style={{ marginTop: 20 }}>
          {ignoreButtonText}
        </Button>)}
      {hasIgnoredInvites > 0 && showIgnored && (
        <>
          <Typography variant="h5" style={{ marginTop: 20 }}>Ignored Invitations</Typography>
          <Grid container direction="row" justify="space-between" alignItems="center" style={{ width: '100%' }}>
            {getInviteItems(invitations, 'Ignore')}
          </Grid>
        </>)}
      <SnackbarAlert
        showSnackbar={!!(errorMessage)}
        setShowSnackbar={() => setErrorMessage('')}
        severity="error"
        message={errorMessage}
        disableAutoHide
      />
    </Container>
  )
}

const ManageReceivedInvitationPage = () => {
  const {
    listData: invitations,
    fetched: fetchedInvitations,
    setListData: setInvitations,
  } = useFetchList('friend_invite_read')

  return (
    <>
      {!fetchedInvitations ? (
        <CircularProgress />
      ) : (
        <ReceivedInvitationsContainer invitations={invitations} setInvitations={setInvitations} />
      )}
    </>
  )
}

export default ManageReceivedInvitationPage
