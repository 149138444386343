import Alert from '@material-ui/lab/Alert'
import Snackbar from '@material-ui/core/Snackbar'
import React from 'react'


const SnackbarAlert = props => {
  const {
    showSnackbar,
    setShowSnackbar,
    severity,
    message,
    disableAutoHide,
  } = props

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setShowSnackbar(false)
  }
  const hideDuration = disableAutoHide ? null : 3000

  return (
    <Snackbar
      open={showSnackbar}
      autoHideDuration={hideDuration}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      style={{ marginTop: 50 }}
    >
      <Alert elevation={6} variant="filled" onClose={handleClose} severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  )
}

export default SnackbarAlert
