import React from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import { geocodeByPlaceId, getLatLng } from './GoogleAutocomplete/helpers/geoCodeHelpers'
import GooglePlacesAutocomplete from './GoogleAutocomplete/GooglePlacesAutocomplete'


const PlacesAutocomplete = props => {
  const { suggestions, loading, dispatch, initialState } = props
  return (
    <Autocomplete
      id="combo-box-demo"
      options={suggestions}
      getOptionLabel={option => option.description}
      getOptionSelected={() => true}
      style={{ width: '100%', margin: 'auto' }}
      onChange={(e, d, r, f) => handleDestSelect(e, d, r, f, dispatch, initialState)}
      renderInput={params => (
        <TextField
          {...params}
          autoFocus
          label="Search for destination"
          variant="outlined"
          onChange={props.onChange}
        />
      )}
      loading={loading}
    />
  )
}

const handleDestSelect = async (e, dest, reason, f, dispatch, initialState) => {
  if (reason === 'select-option') {
    const name = dest.structured_formatting.main_text
    const geoCodeInfo = await fetchGeoCodeInfo(dest.place_id)
    const { lat, lng } = await parseLatLongInfo(geoCodeInfo)
    const address = parseAddressComponents(geoCodeInfo.address_components, initialState)
    const payload = {
      ...initialState,
      googleDest: dest,
      destSelected: true,
      latitude: lat,
      longitude: lng,
      name: name,
      ...address
    }
    dispatch({ type: 'setDest', payload })
  } else {
    dispatch({ type: 'clearDest', payload: initialState })
  }
}

const fetchGeoCodeInfo = async placeId => {
  const geoCodeInfo = await geocodeByPlaceId(placeId)
  return geoCodeInfo[0]
}

const parseLatLongInfo = async geoInfo => {
  const latlng = await getLatLng(geoInfo)
  return latlng
}

const parseAddressComponents = (address, initialState) => {
  let parsedAddress = initialState.destFormValues
  address.forEach(part => {
    switch (part.types[0]) {
      case 'street_number':
        parsedAddress = { ...parsedAddress, streetNumber: part.long_name }
        break
      case 'route':
        parsedAddress = { ...parsedAddress, street: part.long_name }
        break
      case 'locality':
        parsedAddress = { ...parsedAddress, city: part.long_name }
        break
      case 'administrative_area_level_1':
        parsedAddress = { ...parsedAddress, state: part.long_name }
        break
      case 'postal_code':
        parsedAddress = { ...parsedAddress, zip: part.long_name }
        break
      default: {
      }
    }
  })
  parsedAddress = { ...parsedAddress, address: `${parsedAddress.streetNumber} ${parsedAddress.street}` }
  delete parsedAddress.street
  delete parsedAddress.streetNumber
  return parsedAddress
}

const DestinationAutocomplete = props => {
  const { dispatch, initialState } = props
  return (
    <GooglePlacesAutocomplete
      renderInput={props => (
        <PlacesAutocomplete {...props} dispatch={dispatch} initialState={initialState}/>
      )}
    />
  )
}

export default DestinationAutocomplete
