import axios from 'axios'
import { get } from 'lodash'
import * as yup from 'yup'
import { Formik } from 'formik'
import EmailForm from '../forms/EmailForm'
import React, { useState } from 'react'

import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

const EmailFriendDialog = props => {
  const { open, setOpen, formValues, handleEmailSubmit } = props
  const [customMessage, setCustomMessage] = useState('');

  const handleChange = (event) => {
    setCustomMessage(event.target.value)
  }

  const handleClose = () => {
    handleEmailSubmit(formValues, customMessage)
    setOpen(false)
    setCustomMessage('')
  }

  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="email-friend-dialog-title">Invite {formValues.name}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Add an optional message to the invite email we'll send to {formValues.name}.
          </DialogContentText>
          <TextField
            autoFocus
            variant="outlined"
            margin="dense"
            id="customMessage"
            multiline
            value={customMessage}
            onChange={handleChange}
            label="Custom Message"
            type="text"
            rows={4}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

const FriendEmailInviteContainer = props => {
  const { emailInvites, setEmailInvites, setErrorMessage } = props
  const [open, setOpen] = useState(false)
  const [formValues, setFormValues] = useState({})
  const [formActions, setFormActions] = useState()

  const handleEmailSubmit = (values, customMessage) => {
    const data = { email: values.email, name: values.name, custom_message: customMessage }
    axios.post('/api/friend_invite/', data)
      .then(res => {
        const emailInvite = { email: values.email, name: values.name, id: res.data.id }
        const newEmailInvites = [...emailInvites, emailInvite]
        setEmailInvites([...newEmailInvites])
        setErrorMessage('')
        formActions.resetForm({
          values: {
            email: '',
            name: '',
          },
        })
      }).catch(err => {
      if (get(err, 'response.data')) {
        setErrorMessage(err.response.data[0])
      }
    })
  }

  const handleAddEmailClick = (values, actions) => {
    setFormValues({...values})
    setFormActions(actions)
    setOpen(true)
  }

  const emailSchema = yup.object({
    name: yup.string().required(),
    email: yup.string().required().email(),
  })

  return (
    <>
      <EmailFriendDialog
        open={open}
        setOpen={setOpen}
        formValues={formValues}
        handleEmailSubmit={handleEmailSubmit}
      />
      <Formik
        validationSchema={emailSchema}
        validateOnChange={true}
        initialValues={{ email: '', name: '' }}
        onSubmit={handleAddEmailClick}
        children={props => <EmailForm {...props} />}
      />
    </>
  )
}

export default FriendEmailInviteContainer
