import { useHistory, useParams } from 'react-router-dom'
import { useFetchItem, useFetchList } from '../helpers/apiFetchHooks'
import ViewPassportContainer from '../containters/ViewPassportContainer'
import React, { useState } from 'react'
import { Button, CircularProgress, Container, Typography } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import { Autocomplete } from '@material-ui/lab'
import axios from 'axios'

const PrizeFormItem = props => {
  const {
    prizeLevels,
    idx,
    setPrizeLevels,
    prizeOptions,
    destinationCount,
  } = props

  const handleMultiChange = value => {
    let newPrizeLevels = [...prizeLevels]
    newPrizeLevels[idx] = { stamps: newPrizeLevels[idx].stamps, prizes: value }
    setPrizeLevels(newPrizeLevels)
  }

  const handleChange = event => {
    let newPrizeLevels = [...prizeLevels]
    newPrizeLevels[idx] = { stamps: event.target.value, prizes: newPrizeLevels[idx].prizes }
    setPrizeLevels(newPrizeLevels)
  }

  return (
    <Grid item sm={12}>
      <Typography
        variant="h6"
        style={{ marginTop: 10, marginBottom: 10 }}
      >
        Prize Level {(idx + 1).toString()}
      </Typography>
      <TextField
        variant="outlined"
        margin="normal"
        name={`stamps_prize_level_${idx}`}
        label="Required Stamps"
        type="number"
        step={1}
        inputProps={{ 'max': destinationCount, 'min': 0 }}
        id={`stamps_prize_level_${idx}`}
        value={prizeLevels[idx].stamps}
        onChange={(event) => handleChange(event)}
        fullWidth
      />
      <Autocomplete
        style={{ marginTop: 25 }}
        multiple
        id={`prizes_prize_level_${idx}`}
        options={prizeOptions}
        onChange={(_, value) => handleMultiChange(value)}
        value={prizeLevels[idx].prizes}
        getOptionSelected={(option, value) => option.id === value.id}
        getOptionLabel={option => option.name}
        filterSelectedOptions
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Prizes"
          />
        )}
      />
    </Grid>
  )
}


const PassportPrizeSettingsForm = props => {
  const { destinationCount, prizes, apiPrizeLevels, handleSubmit } = props
  const [prizeLevels, setPrizeLevels] = useState(apiPrizeLevels || [{ stamps: 0, prizes: [] }])

  const prizeOptions = prizes.map(prize => (
      { id: prize.id, name: prize.name }
    ),
  )

  const removePrizeLevel = () => {
    const newPrizeLevels = prizeLevels.splice(0, prizeLevels.length - 1)
    setPrizeLevels(newPrizeLevels)
  }

  const PrizeFormItems = prizeLevels.map((_, idx) => (
    <PrizeFormItem
      key={idx}
      idx={idx}
      prizeLevels={prizeLevels}
      prizeOptions={prizeOptions}
      setPrizeLevels={setPrizeLevels}
      destinationCount={destinationCount}
    />
  ))

  return (
    <Container component="form" maxWidth="sm">
      {PrizeFormItems}
      <Grid item sm={12}>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => setPrizeLevels([...prizeLevels, { stamps: 0, prizes: [] }])}
          style={{ marginTop: 20, marginBottom: 10, marginRight: 10 }}
        >
          Add Prize Level
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          onClick={removePrizeLevel}
          style={{ marginTop: 20, marginBottom: 10, marginLeft: 10 }}
        >
          Remove Prize Level
        </Button>
      </Grid>
      <Grid item sm={12}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleSubmit(prizeLevels)}
          style={{ marginTop: 20, marginBottom: 10 }}
        >
          Submit
        </Button>
      </Grid>
    </Container>
  )
}

const ManagePassportPrizePage = () => {
  const { id: passportId } = useParams()
  const history = useHistory()
  const { itemData: passport, fetched: fetchedPassport } = useFetchItem('passport_view', passportId)
  const { listData: prizes, fetched: fetchedPrizes } = useFetchList('prize')
  const { listData: prizeLevelData, fetched: fetchedPrizeLevelData } = useFetchList('prize_level', { id: passportId })

  const formatFormData = pLevels => {
    return pLevels.map(pLevel => (
        {
          id: pLevel.id || null,
          passport: passportId,
          stamps: pLevel.stamps,
          prizes: [...pLevel.prizes.map(pz => pz.id)],
        }
      ),
    )
  }

  const formatApiData = data => {
    return data.map(pLevel => (
      {
        id: pLevel.id,
        stamps: pLevel.stamps,
        prizes: pLevel.prizes,
      }
    ))
  }

  const handleSubmit = values => {
    const prizeLevelsData = formatFormData(values)
    const data = {
      passport: passportId,
      prize_levels: prizeLevelsData,
    }
    axios.post('/api/prize_level/', data)
      .then(res => history.push('/profile-home'))
      .catch(err => console.log(err))
  }

  if (!fetchedPassport || !fetchedPrizes || !fetchedPrizeLevelData) {
    return (
      <CircularProgress />
    )
  } else {
    return (
      <>
        <ViewPassportContainer passport={passport} history={history} collapsed={true} />
        <PassportPrizeSettingsForm
          destinationCount={passport.destinations.length}
          prizes={prizes}
          apiPrizeLevels={formatApiData(prizeLevelData)}
          handleSubmit={handleSubmit}
        />
      </>
    )
  }
}

export default ManagePassportPrizePage
