import React, { useState } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Button, TextField, Typography } from '@material-ui/core'
import Modal from '@material-ui/core/Modal'
import Grid from '@material-ui/core/Grid'
import { Email, Facebook, LinkedIn, Twitter } from '@material-ui/icons'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import { CopyContentIcon } from './CustomIcons'


function getModalStyle() {
  const top = 60
  const left = 60

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 300,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
  },
}))


const SocialModal = props => {
  const {
    modalHeader,
    cancelButtonText,
    handleCancelClick,
    openModal,
    shareUrl,
    shareTitle,
    shareSummary,
    hashtags,
    sharePersonName,
  } = props
  const classes = useStyles()
  const [modalStyle] = useState(getModalStyle)
  const [clipBoardHelperText, setClipBoardHelperText] = useState('')
  const vagabongoUrl = shareUrl ? shareUrl : 'https://vagabongo.com/profile'

  const fbUrl = `https://www.facebook.com/sharer.php?u=${encodeURI(vagabongoUrl)}`

  const twitterUrl = `https://twitter.com/intent/tweet?${encodeURI(`url=${vagabongoUrl}&text=${shareTitle}&hashtags=${hashtags.join()}`)}`

  const linkedInUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURI(`${vagabongoUrl}&title=${shareTitle}&summary=${shareSummary}`)}`

  const emailUrl = `mailto:${encodeURI(`${sharePersonName}?subject=${shareTitle}&body=${shareSummary} ${vagabongoUrl}`)}`



  const handleCopyToClipBoard = (url) => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(url)
        .then(() => setClipBoardHelperText('Link copied to clipboard'))
        .catch(() => setClipBoardHelperText('Error copying to clipboard'))
    } else {
      setClipBoardHelperText('Clipboard unavailable')
    }
  }

  const handleCloseModal = () => {
    setClipBoardHelperText('')
    handleCancelClick()
  }

  return (
    <Modal
      open={openModal}
      onClose={handleCloseModal}
      aria-labelledby="delete-modal-title"
      aria-describedby="delete-modal-description"
    >
      <div style={modalStyle} className={classes.paper}>
        <Grid
          container
          spacing={1}
          alignItems="center"
          justify="space-around"
          alignContent="center"
        >
          <Grid item xs={12}>
            <Typography
              variant="h5"
              align="center"
              style={{ margin: 10 }}
            >
              {modalHeader}
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <Button
              variant="outlined"
              color="primary"
              href={fbUrl}
              target="_blank"
              endIcon={<Facebook />}
              style={{ marginLeft: 7 }}
            >
              Facebook
            </Button>
          </Grid>
          <Grid item xs={5}>
            <Button
              variant="outlined"
              color="primary"
              href={twitterUrl}
              target="_blank"
              endIcon={<Twitter />}
            >
              Twitter
            </Button>
          </Grid>
          <Grid item xs={5}>
            <Button
              variant="outlined"
              color="primary"
              href={linkedInUrl}
              target="_blank"
              endIcon={<LinkedIn />}
              style={{ marginLeft: 7 }}
            >
              LinkedIn
            </Button>
          </Grid>
          <Grid item xs={5}>
            <Button
              variant="outlined"
              color="primary"
              href={emailUrl}
              target="_blank"
              endIcon={<Email />}
            >
              Email
            </Button>
          </Grid>
          <Grid item xs={10} style={{ marginLeft: 10, marginRight: 20, marginTop: 10}}>
            <TextField
              variant="outlined"
              disabled
              value={vagabongoUrl}
              size="small"
              fullWidth
              label={clipBoardHelperText}
              InputProps={{
             endAdornment:
               <InputAdornment position="end">
                  <IconButton
                    onClick={() => handleCopyToClipBoard(vagabongoUrl)}
                    edge="end"
                  >
                    <CopyContentIcon fill="#ffffff" stroke="#ffffff"/>
                  </IconButton>
                </InputAdornment>
           }}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              alignContent="center"
              justify="center"
              alignItems="center"
              style={{ marginTop: 10, marginBottom: 10 }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={handleCloseModal}
                size="small"
              >
                {cancelButtonText ? cancelButtonText : 'Close'}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Modal>
  )
}

export default SocialModal
