import { useHistory, useParams } from 'react-router-dom'
import React from 'react'
import { useFetchItem, useFetchList } from '../helpers/apiFetchHooks'
import MapContainer from '../containters/MapContainer'
import PassportExploreContainer from '../containters/PassportExploreContainer'
import { formatDate } from '../helpers/utils'
import { imgUrl } from '../helpers/constants'


const PassportExploreMapPage = () => {
  const { id } = useParams()
  const history = useHistory()
  const { itemData: passport, fetched } = useFetchItem('passport_wallet', id)
  const { listData: stamps, fetched: stampsFetched } = useFetchList('stamp', { id: id })
  const imgSrc = passport.image ? `${imgUrl}passports/${passport.image}` : false

  if (!fetched || !stampsFetched) {
    return (
      <>
        <h2>Loading</h2>
      </>
    )
  } else {
    const stampDestinationIds = () => stamps.map(stamp => stamp.destination)

    const destinations = passport.destinations.map(dest => {
      const stampIdx = stampDestinationIds().indexOf(dest.id)
      if (stampIdx > -1) {
        dest['isStamped'] = true
        dest['stampDate'] = formatDate(stamps[stampIdx].created)
      }
      return dest
    })


    return (
      <PassportExploreContainer history={history} name={passport.name} id={id} imgSrc={imgSrc}>
        <MapContainer destinations={destinations} id={id} height={'75vh'} />
      </PassportExploreContainer>
    )
  }
}

export default PassportExploreMapPage
