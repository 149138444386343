import React, { useState } from 'react'
import { CircularProgress, ClickAwayListener, Tooltip } from '@material-ui/core'
import { get } from 'lodash'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { Formik } from 'formik'
import * as yup from 'yup'
import { useFetchItem, useFetchItemQueryParams, useFetchList } from '../helpers/apiFetchHooks'
import { useHistory, useParams } from 'react-router-dom'
import axios from 'axios'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import { DropzoneArea } from 'material-ui-dropzone'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { formatDate } from '../helpers/utils'
import ImageGridList from '../components/ImageGridList'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import IconTextButton from '../components/IconTextButton'
import MotherMayIModal from '../components/MotherMayIModal'
import DestinationRating from '../components/DestinationRating'
import DestinationExploreContainer from '../containters/DestinationExploreContainer'
import { imgUrl } from '../helpers/constants'


const DestinationReviewPhoto = props => {
  const { photo, reviewId, destinationId, setReviews } = props

  const handleDelete = () => {
    const data = {
      review_photo_id: photo.id,
      destination: destinationId,
    }
    axios.patch(`/api/review/${reviewId}/`, data)
      .then(res => {
        setReviews(res.data)
      }).catch()
  }

  return (
    <Grid item style={{ maxWidth: '90wh', maxHeight: '90vh' }}>
      <img src={`${imgUrl}reviews/${photo.name}`} alt={photo.name} style={{ maxWidth: '100%', maxHeight: '90vh' }} />
      <Button
        variant="contained"
        color="secondary"
        onClick={handleDelete}
        style={{ marginTop: 10, marginBottom: 10 }}
      >
        Delete Image
      </Button>
    </Grid>
  )
}

const DestinationReviewForm = props => {
  const {
    handleSubmit,
    values,
    handleChange,
    setFieldTouched,
    errors,
    touched,
    isValid,
    dirty,
    setImageFiles,
    edit,
    setEdit,
    review,
    setReviews,
    destinationId,
  } = props
  const isMobile = useMediaQuery('(max-width:760px)')
  const disableSubmit = edit ? !(isValid) : !(isValid && dirty)
  const [open, setOpen] = useState(true)

  const imageLimit = !edit ? 6 : (6 - review.review_photos.length || 0)

  const showDropZone = !edit || !review.review_photos || review.review_photos.length < 6

  const getDestinationReviewPhotos = photos => {
    return photos.map((photo, idx) => (
      <DestinationReviewPhoto
        photo={photo}
        key={idx}
        setReviews={setReviews}
        destinationId={destinationId}
        reviewId={review.id}
      />
    ))
  }

  const DestinationReviewPhotos = edit && review.review_photos.length > 0 ? getDestinationReviewPhotos(review.review_photos) : null

  const submitButtonText = edit ? 'Save Changes' : 'Submit Review'

  const handleFileChange = files => {
    setImageFiles(files)
  }

  const change = (name, e) => {
    e.persist()
    handleChange(e)
    setFieldTouched(name, true, false)
  }

  const onKeyDown = keyEvent => {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault()
    }
  }

  const handleTooltipClose = () => {
    setOpen(false)
  }

  const handleTooltipOpen = () => {
    setOpen(true)
  }

  return (
    <>
      <Grid item xs={12} sm={12}>
        <Grid container justify="flex-start">
          <Grid item xs={1} sm={1}>
            <ClickAwayListener onClickAway={handleTooltipClose}>
              <Tooltip
                PopperProps={{
                  disablePortal: true,
                }}
                onClose={handleTooltipClose}
                open={open}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                style={{ maxWidth: 20 }}
                title="Reviews will be shared publicly."
                arrow
                placement="right"
              >
                <InfoOutlinedIcon onClick={handleTooltipOpen} />
              </Tooltip>
            </ClickAwayListener>
          </Grid>
          {edit && (<Grid item sm={11} xs={11}>
            <Typography
              style={{ textAlign: 'center' }}
              variant="h6"
            >
              {`Editing review from ${formatDate(review.updated)}`}
            </Typography>
          </Grid>)}
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12}>
        <form style={{ width: '100%' }} onKeyDown={onKeyDown}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            multiline
            rows={6}
            fullWidth
            name="review"
            label="Review"
            placeholder="Add a personalized review"
            id="review"
            value={values.review}
            onChange={change.bind(null, 'review')}
            helperText={touched.review ? errors.review : ''}
            error={touched.review && Boolean(errors.review)}
            style={{ marginBottom: 15 }}
          />
          {DestinationReviewPhotos}
          {showDropZone && (
            <DropzoneArea
              acceptedFiles={['image/*']}
              dropzoneText={`Add up to ${imageLimit} photos`}
              filesLimit={imageLimit}
              useChipsForPreview={isMobile}
              style={{ outline: 'none' }}
              maxFileSize={75000000}
              onChange={files => {
                handleFileChange(files)
              }}
            />
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={disableSubmit}
            style={{ marginTop: 15 }}
          >
            {submitButtonText}
          </Button>
          {edit && (
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => setEdit(false)}
              style={{ marginBottom: 10, marginTop: 20 }}
            >
              Close Edit Review
            </Button>
          )}
        </form>
      </Grid>
    </>
  )
}

const ReviewItem = props => {
  const [openModal, setOpenModal] = useState(false)
  const [edit, setEdit] = useState(false)
  const [imageFiles, setImageFiles] = useState([])
  const {
    review,
    idx,
    handleDeleteReview,
    schema,
    setReviews,
    destinationId,
  } = props

  const handleDelete = (id) => {
    axios.delete(`/api/review/${id}/`)
      .then(res => {
        setOpenModal(false)
        handleDeleteReview(idx)
      })
      .catch()
  }

  const handleEditSubmit = (values, { resetForm }) => {
    let form_data = new FormData()
    form_data.append('body', values.review)
    form_data.append('destination', destinationId)
    imageFiles.forEach((imgFile, idx) => {
      form_data.append(`image_file_${idx}`, imgFile, imgFile.name)
    })

    axios.put(`/api/review/${review.id}/`, form_data, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    }).then(res => {
      resetForm()
      setEdit(false)
      setReviews([...res.data])
    })
      .catch()
  }

  const handleOpenModal = () => setOpenModal(true)
  const handleCloseModal = () => setOpenModal(false)

  return (
    <>
      <MotherMayIModal
        id={review.id}
        title="Really Delete?"
        description={`This will permanently delete your review from ${formatDate(review.updated)}.`}
        confirmButtonText="Delete"
        handleConfirmClick={handleDelete}
        cancelButtonText="Cancel"
        openModal={openModal}
        handleCancelClick={handleCloseModal}
      />
      <Grid item xs={12} sm={12} style={{ marginTop: 30 }}>
        {idx !== 0 && (<hr />)}
        {!edit && (
          <Grid container justify="flex-start" alignItems="baseline" direction="row">
            <Grid item xs={4} sm={4}>
              <Typography variant="h6" style={{ textAlign: 'left' }}>Review Date:</Typography>
            </Grid>
            <Grid item xs={3} sm={3}>
              <Typography variant="subtitle1" style={{ textAlign: 'left' }}>{formatDate(review.updated)}</Typography>
            </Grid>
            <Grid item xs={5} sm={5}>
              <Grid container justify="flex-start" alignItems="baseline" direction="row-reverse">
                <Grid item xs={1} sm={1} />
                <Grid item xs={3} sm={3}>
                  <IconTextButton
                    label="Delete"
                    lineHeight={1.5}
                    labelFontSize="1.0rem"
                    onClick={handleOpenModal}
                  >
                    <DeleteIcon />
                  </IconTextButton>
                </Grid>
                <Grid item xs={1} sm={1} />
                <Grid item xs={3} sm={3}>
                  <IconTextButton
                    label="Edit"
                    lineHeight={1.5}
                    labelFontSize="1.0rem"
                    onClick={() => setEdit(true)}
                  >
                    <EditIcon />
                  </IconTextButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4} sm={4}>
              <Typography variant="h6" style={{ textAlign: 'left' }}>Body:</Typography>
            </Grid>
            <Grid item xs={8} sm={8}>
              <Typography style={{ textAlign: 'left' }} variant="subtitle1">{review.body}</Typography>
            </Grid>
          </Grid>
        )}
        {edit && (
          <Formik
            validationSchema={schema}
            onSubmit={handleEditSubmit}
            enableReinitialize={true}
            initialValues={{
              review: review.body,
            }}
            children={props => (
              <DestinationReviewForm
                {...props}
                setImageFiles={setImageFiles}
                review={review}
                edit={edit}
                setEdit={setEdit}
                setReviews={setReviews}
                destinationId={destinationId}
              />
            )}
          />
        )}
      </Grid>
      {!edit && (<ImageGridList photos={review.review_photos} imgDirectory={'reviews'} />)}
    </>
  )
}

const ReviewItems = props => {
  const { reviews, handleDeleteReview, schema, setReviews, destinationId } = props

  return reviews.map((review, idx) => (
      <ReviewItem
        review={review}
        idx={idx}
        key={idx}
        handleDeleteReview={handleDeleteReview}
        schema={schema}
        setReviews={setReviews}
        destinationId={destinationId}
      />
    ),
  )
}


const CreateEditReviewPage = props => {
  const { destinationId, reviews, setReviews, handleDeleteReview } = props
  const [imageFiles, setImageFiles] = useState([])
  const [showNewReview, setShowNewReview] = useState(reviews.length === 0)

  const handleReviewSubmit = (values, { resetForm }) => {
    let form_data = new FormData()
    form_data.append('body', values.review)
    form_data.append('destination', destinationId)
    imageFiles.forEach((imgFile, idx) => {
      form_data.append(`image_file_${idx}`, imgFile, imgFile.name)
    })

    axios.post('/api/review/', form_data, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    }).then(res => {
      resetForm()
      setShowNewReview(false)
      setReviews([res.data, ...reviews])
    })
      .catch()
  }

  const schema = yup.object({
    review: yup.string().required(),
  })

  return (
    <>
      <ReviewItems reviews={reviews} handleDeleteReview={handleDeleteReview} schema={schema} setReviews={setReviews}
                   destinationId={destinationId} />
      {showNewReview && (
        <Grid item xs={12} sm={12} style={{ marginTop: 20 }}>
          <Formik
            validationSchema={schema}
            onSubmit={handleReviewSubmit}
            enableReinitialize={true}
            initialValues={{
              review: '',
            }}
            children={props => <DestinationReviewForm {...props} setImageFiles={setImageFiles} />}
          />
          {!!reviews && (
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => setShowNewReview(false)}
              style={{ marginBottom: 10, marginTop: 20 }}
            >
              Close New Review
            </Button>
          )}
        </Grid>
      )}
      {!!reviews && !showNewReview && (
        <Button
          variant="contained"
          color="primary"
          onClick={() => setShowNewReview(true)}
          style={{ marginBottom: 10, marginTop: 20 }}
        >
          Add New Review
        </Button>
      )}
    </>
  )
}


const DestinationExploreReviewPage = () => {
  const { destinationId, passportId } = useParams()
  const [updatingRating, setUpdatingRating] = useState(false)
  const {
    listData: reviews,
    fetched: reviewFetched,
    setListData: setReviews,
  } = useFetchList('review', { id: destinationId })
  const history = useHistory()
  const { itemData: destination, fetched: destinationFetched } = useFetchItem('destination_view', destinationId)
  const {
    itemData: rating,
    fetched: ratingFetched,
    setItemData: setRating,
  } = useFetchItem('rating', destinationId)
  const {
    itemData: stamp,
    fetched: stampFetched,
  } = useFetchItemQueryParams('stamp', passportId, `destination=${destinationId}`)
  const { itemData: ratingReview, fetched: ratingReviewFetched } = useFetchItem('rating_review', destinationId)

  const imgSrc = destination.image ? `${imgUrl}destinations/${destination.image}` : false
  const pageHeader = reviews && reviews.length > 0 ? 'Your Reviews' : 'Write a review'


  const handleRatingChange = (event, newRatingValue) => {
    setUpdatingRating(true)
    const data = {
      destination: destinationId,
      number_of_stars: newRatingValue,
    }
    if (get(rating, 'id', false)) {
      axios.put(`/api/rating/${rating.id}/`, data)
        .then(res => {
          setRating(res.data)
          setUpdatingRating(false)
        })
        .catch(err => {
          console.log(err)
          setUpdatingRating(false)
        })
    } else {
      axios.post('/api/rating/', data)
        .then(res => {
          setRating(res.data)
          setUpdatingRating(false)
        })
        .catch(err => {
          console.log(err)
          setUpdatingRating(false)
        })
    }
  }

  const handleDeleteReview = idx => {
    const newReviews = [...reviews]
    newReviews.splice(idx, 1)
    setReviews(newReviews)
  }

  if (!destinationFetched || !ratingFetched || !reviewFetched || !stampFetched || !ratingReviewFetched) {
    return (
      <CircularProgress color="inherit" />
    )
  } else {
    return (
      <DestinationExploreContainer
        imgSrc={imgSrc}
        history={history}
        name={destination.name}
        rating={ratingReview}
        id={destinationId}
        stamped={stamp.status === 'stamped'}
        stampDate={stamp.created}
        passportId={passportId}
      >
        <Grid container justify="center" alignItems="center">
          <Grid item xs={12} sm={12}>
            <DestinationRating
              rating={rating}
              handleRatingChange={handleRatingChange}
              updatingRating={updatingRating}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography variant="h5">{pageHeader}</Typography>
          </Grid>
          <CreateEditReviewPage
            destinationId={destinationId}
            reviews={reviews}
            setReviews={setReviews}
            handleDeleteReview={handleDeleteReview}
          />
        </Grid>
      </DestinationExploreContainer>
    )
  }
}

export default DestinationExploreReviewPage
