import { useContext } from 'react'
import { store } from './Store'
import { isEmpty } from 'lodash'

const useActivePassport = id => {
  const { state: { activePassport } } = useContext(store)
  if (id === activePassport.id) {
    return activePassport
  } else if (id === 'last' && !isEmpty(activePassport)) {
    return activePassport
  } else {
    return null
  }
}

export default useActivePassport
