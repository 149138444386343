import React, { useReducer, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { Container } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import CrudListItem from '../components/CrudListItem'
import { useFetchList } from '../helpers/apiFetchHooks'
import List from '@material-ui/core/List'
import VerifyPassportContainer from '../containters/VerifyPassportContainer'
import PassportSettingsFormContainer from '../containters/PassportSettingsFormContainer'
import axios from 'axios'
import StepButton from '@material-ui/core/StepButton'
import ManageDestinationPage from './ManageDestinationPage'
import { imgUrl } from '../helpers/constants'

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}))

const init = initialState => {
  return { ...initialState }
}

const passportReducer = (state, action) => {
  switch (action.type) {
    case 'name': {
      return { ...state, name: action.payload }
    }
    case 'description': {
      return { ...state, description: action.payload }
    }
    case 'featured': {
      return {...state, featured: !state.featured }
    }
    case 'disable_undo_stamp': {
      return {...state, disable_undo_stamp: !state.disable_undo_stamp }
    }
    case 'reset': {
      return { ...action.payload }
    }
    case 'fileChange': {
      return { ...state, imageFile: action.payload }
    }
    case 'addDest': {
      return { ...state, destinations: [...state.destinations, action.payload] }
    }
    case 'image': {
      return { ...state, image: action.payload }
    }
    case 'removeDest': {
      let tmpArr = state.destinations
      const index = tmpArr.indexOf(action.payload)
      if (index > -1) {
        tmpArr.splice(index, 1)
      }
      return { ...state, destinations: [...tmpArr] }
    }
    default:
      throw new Error()
  }
}

function getSteps() {
  return ['Passport Settings', 'Add Destinations', 'Verify and Save']
}

function getStepContent(stepIndex) {
  switch (stepIndex) {
    case 0:
      return 'Configure passport settings'
    case 1:
      return 'Add destinations to the passport'
    case 2:
      return 'Verify that it is beautiful and start exploring!'
    default:
      return 'Unknown stepIndex'
  }
}

const CreateEditPassportPage = props => {
  const { handleSubmit, initialState } = props
  const classes = useStyles()
  const [activeStep, setActiveStep] = useState(0)
  const [completed, setCompleted] = useState({})
  const [showAddDestination, setShowAddDestination] = useState(false)
  const steps = getSteps()
  const [state, dispatch] = useReducer(passportReducer, initialState, init)

  const { listData, fetched, setListData } = useFetchList('destination')
  const destinations = listData


  const disableButton = () => {
    switch (activeStep) {
      case 0:
        return state.name.length === 0 || state.description.length === 0
      case 1:
        return state.destinations.length === 0
      case 2:
        return state.name.length === 0 || state.description.length === 0 || state.destinations.length === 0
      default:
        return true
    }
  }

  const handleAddNewDestination = (data, name) => {
    setListData(data)
    const index = data.map(e => e.name).indexOf(name)
    dispatch({ type: 'addDest', payload: data[index].id})
  }

  const isCompleted = () => {
    const newCompletedSteps = {}
    newCompletedSteps[0] = state.name.length > 0 && state.description.length > 0
    newCompletedSteps[1] = state.destinations.length > 0
    newCompletedSteps[2] = state.name.length > 0 && state.description.length > 0 && state.destinations.length > 0
    setCompleted(newCompletedSteps)
  }

  const handleStep = (step) => () => {
    setActiveStep(step)
    isCompleted()
  }

  const handleNext = (activeStep) => {
    setActiveStep(prevActiveStep => prevActiveStep + 1)
    isCompleted()
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const handleCheckBoxChange = (id, e) => {
    const checked = e.target.checked
    const type = checked ? 'addDest' : 'removeDest'
    dispatch({ type: type, payload: id })
  }

  const isChecked = id => {
    const index = state.destinations.indexOf(id)
    return index > -1
  }

  const destinationItems = destinations.map(dest => (
    <CrudListItem
      key={dest.id}
      {...dest}
      avatarVariant="circle"
      itemType="destination"
      imgDirectory={`${imgUrl}destinations`}
      isEditList={false}
      handleChange={handleCheckBoxChange}
      isChecked={isChecked}
      viewLink={true}
      link={`/destination/${dest.id}`}
    />
  ))

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} alternativeLabel nonLinear>
        {steps.map((label, stepCount) => (
          <Step key={label}>
            <StepButton onClick={handleStep(stepCount)} style={{ outline: 'none' }} completed={completed[stepCount]}>
              {label}
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <div>
        <div>
          <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
          <Container maxWidth={'sm'}>
            <div style={{ display: activeStep === 0 ? 'block' : 'none' }}>
              <PassportSettingsFormContainer
                {...state}
                dispatch={dispatch}
                showFilePreview={state.showFilePreview}
              />
            </div>
            {activeStep === 1 && fetched && (
              <>
                <List>{destinationItems}</List>
                {!showAddDestination ? (
                    <Button variant="contained" color="primary" onClick={() => setShowAddDestination(true)}>
                      Add New Destination
                    </Button>) :
                  (
                    <>
                      <Button variant="contained" color="primary" onClick={() => setShowAddDestination(false)}>
                        Hide Add Destination
                      </Button>
                      <ManageDestinationPage
                        refresh={true}
                        setData={handleAddNewDestination}
                        toggleDest={setShowAddDestination}
                        ignoreId
                      />
                    </>
                  )
                }
              </>
            )}
            {activeStep === 2 && fetched && (
              <>
                <VerifyPassportContainer alldestinations={destinations} {...state} />
              </>
            )}

            <Box style={{ marginTop: 20, marginBottom: 20 }}>
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                className={classes.backButton}
              >
                Back
              </Button>
              {activeStep !== steps.length - 1 ? (<Button
                variant="contained"
                color="primary"
                disabled={disableButton()}
                onClick={() => {
                  handleNext(activeStep)
                }}
              >
                Next
              </Button>) : (
                <Button
                  variant="contained"
                  color="primary"
                  disabled={disableButton()}
                  onClick={() => handleSubmit(state)}
                >
                  Save
                </Button>)}
            </Box>
          </Container>
        </div>
      </div>
    </div>
  )
}

export default CreateEditPassportPage
