import { useHistory, useParams } from 'react-router-dom'
import { useFetchItem, useFetchList } from '../helpers/apiFetchHooks'
import axios from 'axios'
import React from 'react'
import CreateEditPassportPage from './CreateEditPassportPage'

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'

const initialState = {
  name: '',
  description: '',
  featured: false,
  disable_undo_stamp: false,
  imageFile: {},
  destinations: [],
  fileUrl: '',
  image: '',
}

const ManagePassportPage = () => {
  let { id } = useParams()
  let history = useHistory()
  const { itemData, fetched } = useFetchItem('passport', id)

  const getDestIdsArray = destinationObjs => {
    return destinationObjs.map(destObj => destObj.id)
  }

  const submitData = (state) => {
    let form_data = new FormData()
    if (state.imageFile && state.imageFile.name) {
      form_data.append('image_file', state.imageFile, state.imageFile.name)
    }
    if (id) {
      form_data.append('id', id)
    }
    form_data.append('image', state.image)
    form_data.append('name', state.name)
    form_data.append('description', state.description)
    form_data.append('featured', state.featured)
    form_data.append('disable_undo_stamp', state.disable_undo_stamp)
    form_data.append('destinations', state.destinations)

    axios
      .post('/api/passport/', form_data, {
        headers: {
          'content-type': 'multipart/form-data',
        },
      })
      .then(res => {
        console.log(res.data)
        history.push('/admin-home')
      })
      .catch(err => {
        console.log(err)
      })
  }

  if (!fetched) {
    return (
      <>
        <h2>Loading</h2>
      </>
    )
  } else {
    const updatedData = { ...itemData, destinations: id ? getDestIdsArray(itemData.destinations) : [], }
    return (
      <CreateEditPassportPage
        id={id}
        initialState={id ? updatedData : initialState}
        handleSubmit={submitData}
      />
    )
  }
}

export default ManagePassportPage
