import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Link from '@material-ui/core/Link'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import List from '@material-ui/core/List'
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone'
import SmsIcon from '@material-ui/icons/Sms'
import MailOutlineIcon from '@material-ui/icons/MailOutline'

const useStyles = makeStyles((theme) => ({
  containerFluid: {
    backgroundColor: theme.palette.primary.main,
    minHeight: '100vh',
    height: '100%',
    width: '100%',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      minHeight: '90vh',
    },
    [theme.breakpoints.down('xs')]: {
      minHeight: '115vh',
    },
  },
  gridHeader: {
    height: '90vh',
    backgroundColor: theme.palette.primary.main,
  },
  gridItem: {
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.down('xs')]: {
      order: -1,
      marginTop: '5vh',
      alignItems: 'center',
    },
  },
  paper: {
    padding: theme.spacing(1),
    minWidth: 268,
    textAlign: 'center',
    color: theme.palette.text.secondary,
    whiteSpace: 'nowrap',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      margin: '5vh',
    },
  },
}))

const ContactUs = () => {
  const classes = useStyles()

  return (
    <div id="contact" className={classes.containerFluid}>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        className={classes.gridHeader}
      >
        <Grid item xs={12} md={5} sm={6}>
          <Paper elevation={6} className={classes.paper} >
            <Grid container direction="row" justify="center">
              <List>

                <Link
                  href="sms:+12162604805"
                  underline="none"
                >

                  <ListItem>
                    <ListItemIcon>
                      <SmsIcon fontSize="large" color="primary" />
                    </ListItemIcon>
                    <ListItemText
                      primary="Text Us"
                      secondary="216.260.4805"
                      primaryTypographyProps={{
                        variant: 'h5',
                        color: 'textPrimary',
                      }}
                      secondaryTypographyProps={{ variant: 'h6' }}
                    />
                  </ListItem>
                </Link>

                <Link href="tel:+12162604805" underline="none">
                  <ListItem>
                    <ListItemIcon>
                      <PhoneIphoneIcon fontSize="large" color="primary" />
                    </ListItemIcon>
                    <ListItemText
                      primary="Call us"
                      secondary="216.260.4805"
                      primaryTypographyProps={{
                        variant: 'h5',
                        color: 'textPrimary',
                      }}
                      secondaryTypographyProps={{ variant: 'h6' }}
                    />
                  </ListItem>
                </Link>
                <Link href="mailto:info@vagabongo.com" underline="none">
                  <ListItem>
                    <ListItemIcon>
                      <MailOutlineIcon fontSize="large" color="primary" />
                    </ListItemIcon>
                    <ListItemText
                      primary="Email us"
                      secondary="info@vagabongo.com"
                      primaryTypographyProps={{
                        variant: 'h5',
                        color: 'textPrimary',
                      }}
                      secondaryTypographyProps={{ variant: 'h6' }}
                    />
                  </ListItem>
                </Link>
              </List>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} md={5} sm={6} className={classes.gridItem} >
          <Typography variant="h3" style={{ color: 'white', paddingLeft: 10, marginLeft: 10, marginRight: 10 }}>
            Ready to get started?
          </Typography>
          <Typography variant="h4" style={{ color: 'white', marginLeft: 10, marginRight: 10, marginTop: 25 }}>
            We'd love to hear from you!
          </Typography>
        </Grid>
      </Grid>
    </div>
  )
}

export default ContactUs
