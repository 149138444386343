import React from 'react'
import Grid from '@material-ui/core/Grid'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import { formatDate } from '../helpers/utils'
import IconTextButton from '../components/IconTextButton'
import { PassportStampIcon } from '../components/CustomIcons'
import StarBorderIcon from '@material-ui/icons/StarBorder'
import RatingOverview from '../components/RatingOverview'
import PaperContainer from './PaperContainer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearchLocation } from '@fortawesome/free-solid-svg-icons'


const DestinationExploreContainer = props => {
  const {
    children,
    imgSrc,
    history,
    name,
    rating,
    id,
    stamped,
    stampDate,
    passportId,
    hide,
  } = props

  if (hide) {
    return null
  } else {
    return (
      <PaperContainer
        imgSrc={imgSrc}
        history={history}
        name={name}
        subHeader={<RatingOverview rating={rating} destinationId={id} />}
      >
        <Grid container justify="space-around" direction="row" alignItems="center" style={{ marginTop: 5 }}>
          <Grid item sm={4} xs={4}>
            <IconTextButton
              onClick={() => history.push(`/destination-explore/${passportId}/${id}`)}
              label="Details"
              lineHeight={1.5}
            >
              <FontAwesomeIcon icon={faSearchLocation} style={{ fontSize: 33 }} />
            </IconTextButton>
          </Grid>
          <Grid item sm={4} xs={4}>
            <IconTextButton
              onClick={() => history.push(`/destination-explore-review/${passportId}/${id}`)}
              label="Review"
              lineHeight={1.5}
            >
              <StarBorderIcon fontSize="large" />
            </IconTextButton>
          </Grid>
          <Grid item sm={4} xs={4}>
            {stamped ? (
              <IconTextButton
                onClick={() => history.push(`/destination-explore-stamp/${passportId}/${id}`)}
                label={formatDate(stampDate)}
                lineHeight={1.0}
              >
                <CheckCircleOutlineIcon fontSize="large" />
              </IconTextButton>
            ) : (
              <IconTextButton
                onClick={() => history.push(`/destination-explore-stamp/${passportId}/${id}`)}
                label="Stamp"
                lineHeight={0}
              >
                <PassportStampIcon
                  height="45px"
                  width="50px"
                  style={{ textAlign: 'center', vAlign: 'baseline' }}
                />
              </IconTextButton>
            )}
          </Grid>
        </Grid>
        {children}
      </PaperContainer>
    )
  }
}

export default DestinationExploreContainer
