import { get } from 'lodash'
import { CircularProgress } from '@material-ui/core'
import { Rating } from '@material-ui/lab'
import React from 'react'

const DestinationRating = props => {
  const {
    rating,
    handleRatingChange,
    updatingRating,
    precision,
    readOnly,
  } = props
  const stars = get(rating, 'number_of_stars', 0)
  const starPrecision = precision || 1.0


  if (updatingRating) {
    return (
      <CircularProgress color="inherit" />
    )
  } else {
    return (
      <Rating
        name="simple-controlled"
        value={stars}
        onChange={handleRatingChange}
        size="large"
        readOnly={readOnly}
        precision={starPrecision}
      />
    )
  }
}

export default DestinationRating
