import * as yup from 'yup'
import axios from 'axios'
import { makeStyles } from '@material-ui/core/styles'
import { useCsrfToken } from '../helpers/GetCsrfToken'
import Container from '@material-ui/core/Container'
import CssBaseline from '@material-ui/core/CssBaseline'
import { Formik } from 'formik'
import SnackbarAlert from '../components/SnackbarAlert'
import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useFetchItem } from '../helpers/apiFetchHooks'
import PasswordResetLinkForm from '../forms/PasswordResetLinkForm'
import { Typography } from '@material-ui/core'

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}))

const schema = yup.object({
  password: yup.string().required().min(8),
  passwordConfirmation: yup.string().required()
     .oneOf([yup.ref('password'), null], 'Passwords must match')
})

const PasswordResetLinkPage = () => {
  const { id } = useParams()
  const [successMessage, setSuccessMessage] = useState('')
  const { itemData: resetData, fetched, setItemData } = useFetchItem('password_reset', id)
  const [showSuccess, setShowSuccess] = useState(false)

  const history = useHistory()
  const classes = useStyles()

  const redirectLogin = () => {
    setTimeout(() => {
      history.push('/login-page')
    }, 3000)
    setTimeout(3000)
  }

  const submit = (values, { resetForm }) => {
    const data = {
      password: values.password,
      password_confirmation: values.passwordConfirmation,
    }
    axios.put(`/api/password_reset/${id}/`, data)
      .then(res => {
        if (res.data.success) {
          setSuccessMessage(res.data.success)
          setShowSuccess(true)
          redirectLogin()
        } else {
         setItemData(res.data)
        }
      }).catch(err => {
      console.log(err)
    })
  }

  useCsrfToken()
  if (!fetched) {
    return (
      <>
        <h2>Loading</h2>
      </>
    )
  } else if (fetched && resetData.token === id) {
    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Formik
            validationSchema={schema}
            onSubmit={submit}
            initialValues={{
              email: resetData.email,
              password: '',
              passwordConfirmation: '',
            }}
            component={PasswordResetLinkForm}
          />
        </div>
        <SnackbarAlert
          showSnackbar={showSuccess}
          setShowSnackbar={setShowSuccess}
          severity="success"
          message={successMessage}
        />
      </Container>
    )
  } else {
    redirectLogin()
    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Typography variant="h4">Token is {resetData.token}</Typography>
      </Container>
    )
  }
}

export default PasswordResetLinkPage
