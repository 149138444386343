import Container from '@material-ui/core/Container'
import React, { useContext, useState } from 'react'
import ProfileEditPage from './ProfileEditPage'
import PassportListPage from './PassportListPage'
import DestinationListPage from './DestinationListPage'
import axios from 'axios'
import Button from '@material-ui/core/Button'
import { store } from '../store/Store'
import useLogin from '../store/useLogin'
import { useFetchItem, useFetchList } from '../helpers/apiFetchHooks'
import { CircularProgress } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { formatPhoneNumber } from '../helpers/utils'
import ProfileAvatar from '../components/ProfileAvatar'
import SnackbarAlert from '../components/SnackbarAlert'
import PrizeListPage from './PrizeListPage'
import { imgUrl } from '../helpers/constants'


const ProfileInfoContainer = props => {
  const {
    profile: {
      first_name,
      last_name,
      email,
      phone,
      image,
      address,
      city,
      state,
      zip,
    },
  } = props
  const imgSrc = image ? `${imgUrl}profiles/${image}` : null
  const comma = (city && state) ? ',' : ''
  return (
    <Grid container justify="center" direction="column" alignItems="center">
      <Typography variant="h4" style={{ marginBottom: 20, marginTop: 40 }}>
        Profile
      </Typography>
      <ProfileAvatar
        firstName={first_name}
        src={imgSrc}
        fontSize='3.0rem'
        height={75}
        width={75}
      />
      {!image && <Typography variant="body1">Add Photo</Typography>}
      <Typography variant="h6">{first_name} {last_name}</Typography>
      <Typography variant="h6">{email}</Typography>
      <Typography variant="h6">{formatPhoneNumber(phone)}</Typography>
      {(address || city || state || zip) && (
        <div style={{ marginTop: 5 }}>
          {address && <Typography variant="h6">{address}</Typography>}
          {(city || state || zip) && <Typography variant="h6">{city}{comma} {state} {zip}</Typography>}
        </div>)}
    </Grid>
  )

}

const ManageProfileHomePage = () => {
  const { dispatch } = useContext(store)
  const { profileId } = useLogin()
  const [showEdit, setShowEdit] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const { itemData: profile, fetched: fetchedProfile, setItemData: setProfile } = useFetchItem('profile', profileId)



  const logoutApi = () => {
    axios.get('/api/logout/').then(() => {
      dispatch({ type: 'logout' })
    })
  }

  return (
    <Container component="main" maxWidth="sm">
      {!fetchedProfile && (<CircularProgress />)}
      {fetchedProfile && !showEdit && (
        <ProfileInfoContainer
          profile={profile}
          setShowEdit={setShowEdit}
        />)
      }
      {fetchedProfile && showEdit && (
        <ProfileEditPage
          profile={profile}
          setShowEdit={setShowEdit}
          setProfile={setProfile}
          setShowSuccess={setShowSuccess}
        />
      )}
      <Button
        variant="outlined"
        color="primary"
        fullWidth
        style={{ marginTop: 30 }}
        onClick={() => setShowEdit(!showEdit)}
      >
        {showEdit ? 'Exit Edit Profile' : 'Edit Profile'}
      </Button>
      <Button
        variant="outlined"
        color="secondary"
        fullWidth
        style={{ marginTop: 30 }}
        onClick={logoutApi}
      >
        Logout
      </Button>
      <SnackbarAlert
        showSnackbar={showSuccess}
        setShowSnackbar={setShowSuccess}
        severity="success"
        message="Profile changes saved!"
      />
    </Container>
  )

}

export default ManageProfileHomePage
