import React, { useState } from 'react'
import axios from 'axios'
import OneActionListItem from '../components/OneActionListItem'
import { formatPhoneNumber } from '../helpers/utils'
import Container from '@material-ui/core/Container'
import { CircularProgress, Typography } from '@material-ui/core'
import List from '@material-ui/core/List'
import { useFetchList } from '../helpers/apiFetchHooks'

const SentInvitationsContainer = props => {
  const { sentInvitations, setSentInvitations } = props
  const [openModal, setOpenModal] = useState(false)

  const deleteInvite = id => {
    return axios.delete(`/api/friend_invite/${id}/`)
  }

  const handleDelete = idx => {
    const inviteId = sentInvitations[idx].id

    deleteInvite(inviteId)
      .then(() => {
        const newInvites = [...sentInvitations]
        newInvites.splice(idx, 1)
        setSentInvitations([...newInvites])
      }).catch(err => {
      console.log(err)
    })
  }

  const getInviteItems = invites => {
    return invites
      .filter(invite => invite.response !== 'Accepted')
      .map((invite, idx) => (
        <OneActionListItem
          key={idx}
          id={idx}
          primaryText={invite.name}
          secondaryText={invite.email || formatPhoneNumber(invite.phone)}
          handleAction={() => handleDelete(idx)}
          action="delete"
          enableMobileShare
          mobileShareTitle="Join me on Vagabongo!"
          mobileShareText={`Come explore with me!`}
          mobileShareUrl="https://vagabongo.com/profile"
          mobileSharePersonName={invite.name}
          openModal={openModal}
          setOpenModal={setOpenModal}
          handleDelete={() => handleDelete(idx)}
          mobileModalHeader={`Share Vagabongo with ${invite.name}`}
        />
      ))
  }


  return (
    <Container component="main" maxWidth="sm">
      {sentInvitations.length > 0 ? (
        <>
          <Typography variant="h5" style={{ marginTop: 20 }}>Sent Invitations</Typography>
          <List>{getInviteItems(sentInvitations)}</List>
        </>
      ) : (
        <Typography variant="h5" style={{ marginTop: 20 }}>No Pending Invitations</Typography>)}
    </Container>
  )
}

const ManageSentInvitationPage = () => {
  const {
    listData: sentInvitations,
    fetched: fetchedSentInvitations,
    setListData: setSentInvitations,
  } = useFetchList('friend_invite')

  return (
    <>
      {!fetchedSentInvitations ? (
        <CircularProgress />
      ) : (
        <SentInvitationsContainer sentInvitations={sentInvitations} setSentInvitations={setSentInvitations} />
      )}
    </>
  )
}

export default ManageSentInvitationPage
