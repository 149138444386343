import { Link, useHistory, useParams } from 'react-router-dom'
import React, { useContext } from 'react'
import { useFetchItem } from '../helpers/apiFetchHooks'
import List from '@material-ui/core/List'
import ProgressBar from '../components/ProgressBar'
import Grid from '@material-ui/core/Grid'
import ListItemText from '@material-ui/core/ListItemText'
import { store } from '../store/Store'
import PassportExploreContainer from '../containters/PassportExploreContainer'
import { CircularProgress } from '@material-ui/core'

const leaderItem = (leaderStamps, destinationCount, idx, id) => {
  const firstStamp = leaderStamps[0]
  const leaderName = `${firstStamp.first_name} ${firstStamp.last_name}`
  const rank = idx + 1
  const stampCount = leaderStamps.length
  const LeaderProgressBar = ProgressBar(stampCount, destinationCount, 0)
  const stampSummary = `${stampCount} of ${destinationCount}`
  const linkPath = `/passport-explore-leaderboard-detail/${id}/${idx}`

  return (
    <Grid container justify="flex-start" direction="row" alignItems="flex-start" key={idx}>
      <Grid item xs={6} sm={4} >
        <Link to={linkPath}>
          <ListItemText style={{ textAlign: 'left' }} primary={`${rank}). ${leaderName}`} secondary={stampSummary} />
        </Link>
      </Grid>
      <Grid item xs={6} sm={8} style={{ width: '80%' }}>
        {LeaderProgressBar}
      </Grid>
    </Grid>
  )
}

const PassportExploreLeaderboardPage = () => {
  const { id } = useParams()
  const history = useHistory()
  const { dispatch } = useContext(store)
  const { itemData: passport, fetched } = useFetchItem('passport_wallet', id)
  const { itemData: leaders, fetched: leadersFetched } = useFetchItem('leaderboard', id, dispatch, 'leaderboard')

  if (!fetched || !leadersFetched) {
    return (
      <CircularProgress />
    )
  } else {
    const destinationCount = passport.destinations.length
    const leaderItems = leaders.map((leader, idx) => leaderItem(leader, destinationCount, idx, id))
    const imgSrc = passport.image ? `passports/${passport.image}` : null

    return (
      <PassportExploreContainer history={history} imgSrc={imgSrc} name={passport.name} id={id}>
        <List>
          {leaderItems}
        </List>
      </PassportExploreContainer>
    )
  }
}

export default PassportExploreLeaderboardPage
