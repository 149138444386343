import Avatar from '@material-ui/core/Avatar'
import { green } from '@material-ui/core/colors'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import React from 'react'

export const CheckmarkAvatar = () => {
  return (
    <Avatar style={{ color: '#fff', backgroundColor: green[500], width: 22, height: 22, border: 0 }}>
      <CheckCircleOutlineIcon />
    </Avatar>
  )
}