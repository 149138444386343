import PaperContainer from '../containters/PaperContainer'
import MapContainer from '../containters/MapContainer'
import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useFetchItem, useFetchList } from '../helpers/apiFetchHooks'
import RatingOverview from '../components/RatingOverview'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { formatDate } from '../helpers/utils'
import ImageGridList from '../components/ImageGridList'


const ViewReviewItem = props => {
  const {
    review,
    idx,
  } = props

  return (
    <>
      <Grid item xs={12} sm={12} style={{ marginTop: 30 }}>
        {idx !== 0 && (<hr />)}
        <Grid container justify="flex-start" alignItems="baseline" direction="row">
          <Grid item xs={8} sm={8}>
            <Typography variant="h6" style={{ textAlign: 'left' }}>{formatDate(review.updated)}</Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography style={{ textAlign: 'left' }} variant="subtitle1">{review.body}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <ImageGridList photos={review.review_photos} imgDirectory={'reviews'} />
    </>
  )
}

const ViewReviewItems = props => {
  const { reviews } = props

  return reviews.map((review, idx) => (
    <ViewReviewItem idx={idx} key={idx} review={review} />
  ))
}

const ViewDestinationReviewContainer = props => {
  const { history, destination, rating, reviews } = props


  return (
    <PaperContainer
      history={history}
      name={destination.name}
      subHeader={<RatingOverview rating={rating} destinationId={destination.id} />}
    >
      <ViewReviewItems reviews={reviews} />
    </PaperContainer>
  )
}

const ViewDestinationReviewPage = () => {
  const { id } = useParams()
  const history = useHistory()
  const { listData: reviews, fetched: reviewsFetched } = useFetchList('review_view', { id: id })
  const { itemData: rating, fetched: ratingFetched } = useFetchItem('rating_review', id)
  const { itemData: destination, fetched: destinationFetched } = useFetchItem('destination_view', id)

  if (!reviewsFetched || !ratingFetched || !destinationFetched) {
    return (
      <>
        <h2>Loading</h2>
      </>
    )
  } else {
    return (
      <ViewDestinationReviewContainer
        history={history}
        rating={rating}
        reviews={reviews}
        destination={destination}
      />
    )
  }
}

export default ViewDestinationReviewPage
