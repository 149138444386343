import { Link, useHistory, useParams } from 'react-router-dom'
import { useFetchItem, useFetchList } from '../helpers/apiFetchHooks'
import React, { useContext } from 'react'
import Grid from '@material-ui/core/Grid'
import { Typography } from '@material-ui/core'
import List from '@material-ui/core/List'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import ListItemText from '@material-ui/core/ListItemText'
import ListItem from '@material-ui/core/ListItem'
import Badge from '@material-ui/core/Badge'
import ProgressBar from '../components/ProgressBar'

import { CheckmarkAvatar } from '../components/CheckmarkAvatar'
import { store } from '../store/Store'
import PassportExploreContainer from '../containters/PassportExploreContainer'
import IconTextButton from '../components/IconTextButton'
import RedeemIcon from '@material-ui/icons/Redeem'
import { imgUrl } from '../helpers/constants'


const DestinationStampItem = props => {
  const {
    passportId,
    id,
    image,
    name,
    description,
    isStamped,
  } = props

  const truncate = longDesc => (longDesc.length > 75 ? `${longDesc.substring(0, 75)}...` : longDesc)
  const shortDesc = description ? truncate(description) : ''
  const shortName = name ? truncate(name) : ''

  const imgSrc = image ? `${imgUrl}destinations/${image}` : null
  return (
    <ListItem key={id}>
      <ListItemAvatar>
        {isStamped(id) ? (
          <Badge
            overlap="circle"
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            badgeContent={<CheckmarkAvatar alt="stamped" />}
          >
            <Avatar src={imgSrc} alt={name} />
          </Badge>
        ) : (<Avatar src={imgSrc} alt={name} />)}

      </ListItemAvatar>
      <Link to={`/destination-explore/${passportId}/${id}`}>
        <ListItemText primary={shortName} secondary={shortDesc} />
      </Link>
    </ListItem>
  )
}

const PassportProgressContainer = props => {
  const { progressValue, destinationsCount } = props

  const progressBar = ProgressBar(progressValue, destinationsCount, 0)

  return (
    <>
      <Grid container justify="space-around" direction="row" alignItems="center" style={{ marginTop: 5 }}>
        <Grid item sm={6} xs={6}>
          <Typography variant="h5">{progressValue}</Typography>
          <Typography variant="subtitle1">{progressValue === 1 ? `Stamp` : `Stamps`} Earned</Typography>
        </Grid>
        <Grid item sm={6} xs={6}>
          <Typography variant="h5">{destinationsCount}</Typography>
          <Typography variant="subtitle1">Total Stamps</Typography>
        </Grid>
      </Grid>
      <Grid item sm={12}>
        {progressBar}
      </Grid>
    </>
  )
}

const PrizeProgressContainer = props => {
  const {
    progressValue,
    destinationsCount,
    prizeLevel,
    id,
    history,
  } = props

  const allPrizesReached = prizeLevel === 'complete'
  const progressBarTotal = allPrizesReached ? destinationsCount : prizeLevel.stamps

  const prizeProgressBar = ProgressBar(progressValue, progressBarTotal, 0)

  return (
    <>
      {allPrizesReached && (
        <Grid item sm={12} xs={12}>
          <Typography variant="h5">All Prize Levels Achieved!</Typography>
        </Grid>)}
      <Grid container justify="space-around" direction="row" alignItems="center" style={{ marginTop: 5 }}>
        <Grid item sm={4} xs={4}>
          <Typography variant="h5">{progressValue}</Typography>
          <Typography variant="subtitle1">{progressValue === 1 ? `Stamp` : `Stamps`} Earned</Typography>
        </Grid>
        {!allPrizesReached && (
          <Grid item sm={4} xs={4}>
            <Typography variant="h5">{prizeLevel.stamps}</Typography>
            <Typography variant="subtitle1">Next Prize</Typography>
          </Grid>)}
        {allPrizesReached && (
          <Grid item sm={4} xs={4}>
            <Typography variant="h5">{destinationsCount}</Typography>
            <Typography variant="subtitle1">Total Stamps</Typography>
          </Grid>)}
        <Grid item sm={4} xs={4}>
          <IconTextButton
            onClick={() => history.push(`/passport-explore-prize/${id}`)}
            label='Prize Info'
            lineHeight={2}
          >
            <RedeemIcon
              fontSize="large"
              style={{ textAlign: 'center', vAlign: 'text' }}
            />
          </IconTextButton>
        </Grid>
      </Grid>
      <Grid item sm={12}>
        {prizeProgressBar}
      </Grid>
    </>
  )
}

const PassportExplorePage = props => {
  const {
    history,
    passport: {
      id,
      name,
      image,
      description,
      destinations,
      prize_levels,
    },
    stamps,
  } = props
  const imgSrc = image ? `${imgUrl}passports/${image}` : false
  const hasPrizeLevels = prize_levels.length > 0
  const progressValue = stamps.filter(stamp => stamp.status === 'stamped').length
  const stampDestinationIds = stamps.map(stamp => stamp.destination)
  const activePrizeLevel = getActivePrizeLevel()

  function getActivePrizeLevel() {
    if (!prize_levels.length) return null
    for (let i = 0; i < prize_levels.length; i++)
      if (prize_levels[i].stamps > progressValue) {
        return prize_levels[i]
      }
    return 'complete'
  }

  const isStamped = (id) => stampDestinationIds.indexOf(id) > -1

  const destStampItems = destinations.map(dest => (
    <DestinationStampItem
      key={dest.id}
      passportId={id}
      id={dest.id}
      image={dest.image}
      name={dest.name}
      description={dest.description}
      isStamped={isStamped}
    />
  ))

  return (
    <PassportExploreContainer imgSrc={imgSrc} history={history} name={name} id={id}>
      <Grid item sm={12}>
        <Typography variant="subtitle1" style={{ marginBottom: 10, marginTop: 10 }}>{description}</Typography>
      </Grid>
      {hasPrizeLevels && (
        <PrizeProgressContainer
          progressValue={progressValue}
          prizeLevel={activePrizeLevel}
          destinationsCount={destinations.length}
          id={id}
          history={history}
        />
      )}
      {!hasPrizeLevels && (
        <PassportProgressContainer
          progressValue={progressValue}
          destinationsCount={destinations.length}
        />)
      }
      <List>
        {destStampItems}
      </List>
    </PassportExploreContainer>
  )

}

const ManagePassportExplore = () => {
  const { id } = useParams()
  const history = useHistory()
  const { dispatch } = useContext(store)
  const { itemData: passport, fetched } = useFetchItem('passport_wallet', id, dispatch, 'activePassport')
  const { listData: stamps, fetched: stampsFetched } = useFetchList('stamp', { id: id })

  if (!fetched || !stampsFetched) {
    return (
      <>
        <h2>Loading</h2>
      </>
    )
  } else if (fetched) {
    return (
      <PassportExplorePage passport={passport} history={history} stamps={stamps} />
    )
  } else {
    return null
  }
}

export default ManagePassportExplore
