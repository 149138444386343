import Box from '@material-ui/core/Box'
import LinearProgress from '@material-ui/core/LinearProgress'
import { Typography } from '@material-ui/core'
import React from 'react'

const ProgressBar = (value, max, min) => {
  const normalize = value => (value - min) * 100 / (max - min)

  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" value={normalize(value)} style={{ borderRadius: 10, height: 10 }} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="h5" color="textSecondary">{`${Math.round(normalize(value))}%`}</Typography>
      </Box>
    </Box>
  )
}

export default ProgressBar
