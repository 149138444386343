import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import { Link, useHistory } from 'react-router-dom'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import RedeemIcon from '@material-ui/icons/Redeem';
import React, { useState } from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import axios from 'axios'
import MotherMayIModal from './MotherMayIModal'
import Typography from '@material-ui/core/Typography'

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'


const CrudListItem = props => {
  const {
    avatarVariant,
    itemType,
    imgDirectory,
    id,
    name,
    description,
    creator,
    image,
    handleChange,
    isEditList,
    readOnly,
    isChecked,
    viewLink,
    isPrize,
    link,
  } = props
  const [openModal, setOpenModal] = useState(false)
  const truncate = longDesc => (longDesc.length > 25 ? `${longDesc.substring(0, 25)}...` : longDesc)
  const shortDesc = description ? truncate(description) : ''
  const shortName = name ? truncate(name) : ''
  const imgSrc = image ? `${imgDirectory}/${image}` : null
  const history = useHistory()
  const linkAddress = link ? link : `/${itemType}-view/${id}`

  const change = (id, e) => {
    handleChange(id, e)
  }

  const handleClick = (id) => {
    history.push(`/${itemType}/${id}`)
  }

  const handlePrize = (id) => {
    history.push(`/${itemType}-prize/${id}`)
  }

  const handleDelete = (id) => {
    axios.delete(`/api/${itemType}/${id}/`)
      .then(res => {
        history.go(0)
      })
  }

  const handleOpenModal = () => setOpenModal(true)

  const handleCloseModal = () => setOpenModal(false)

  if (readOnly) {
    return (
      <ListItem key={id}>
        <ListItemAvatar>
          <Avatar src={imgSrc} alt={name}/>
        </ListItemAvatar>
        {viewLink ? (<Link to={linkAddress}>
        <ListItemText primary={shortName} secondary={shortDesc}/>
        </Link>) : (
          <ListItemText primary={shortName} secondary={shortDesc}/>
          )}
      </ListItem>
    )
  } else {
    return (
      <ListItem key={id}>
        <ListItemAvatar>
          <Avatar variant={avatarVariant} src={imgSrc} alt={name}/>
        </ListItemAvatar>
        {viewLink ? (<Link to={linkAddress}>
          <ListItemText
            primary={shortName}
            secondary={
              <Typography component={'span'}>
                <div>{shortDesc}</div>
                {creator && <div>invited by: {creator}</div>}
              </Typography>
            }
          />
        </Link>) : (
          <ListItemText
            primary={shortName}
            secondary={
              <Typography component={'span'}>
                <div>{shortDesc}</div>
                {creator && <div>invited by: {creator}</div>}
              </Typography>
            }
          />
        )}
        {isEditList ? (
          <>
          {isPrize && (<ListItemSecondaryAction style={{ marginRight: 70 }}>
              <IconButton aria-label="edit" onClick={() => handlePrize(id)}>
                <RedeemIcon />
              </IconButton>
            </ListItemSecondaryAction>)}
            <ListItemSecondaryAction style={{ marginRight: 30 }}>
              <IconButton aria-label="edit" onClick={() => handleClick(id)}>
                <EditIcon />
              </IconButton>
            </ListItemSecondaryAction>
            <ListItemSecondaryAction>
              <IconButton edge="end" aria-label="delete" onClick={handleOpenModal}>
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
            <MotherMayIModal
              id={id}
              title="Really Delete?"
              description={`This will permanently delete ${name} ${itemType}.`}
              confirmButtonText="Delete"
              handleConfirmClick={handleDelete}
              cancelButtonText="Nope"
              openModal={openModal}
              handleCancelClick={handleCloseModal}
            />
          </>
        ) : (
          <ListItemSecondaryAction>
            <Checkbox
              edge="end"
              onChange={change.bind(null, id)}
              color="primary"
              inputProps={{ 'aria-label': name }}
              checked={isChecked(id)}
            />
          </ListItemSecondaryAction>
        )}
      </ListItem>
    )
  }
}

export default CrudListItem
