import { useContext } from 'react'
import { store } from './Store'

const useLeaderboard = (id, leaderId) => {
  const { state: { leaderboard } } = useContext(store)
  if (id === leaderboard[0][0].passport_id) {
    return leaderboard[leaderId]
  } else {
    return null
  }
}

export default useLeaderboard
