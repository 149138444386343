import React, { createContext, useEffect, useReducer } from 'react'

const initialState = {
  csrf: false,
}

const localSessionState = JSON.parse(sessionStorage.getItem('myState'))

const sessionStore = createContext(localSessionState || initialState)
const { Provider } = sessionStore


const SessionStateProvider = ({ children }) => {
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case 'csrf':
        return { ...state, csrf: true }
      default:
        throw new Error()
    }
  }, localSessionState || initialState)

  useEffect(() => {
    sessionStorage.setItem('myState', JSON.stringify(state))
  }, [state])

  return <Provider value={{ state, dispatch }}>{children}</Provider>
}

export { sessionStore, SessionStateProvider }
